import React from "react";
import { ContentLoader } from "../../boxes/ContentLoader/ContentLoader";
import { useSendOtp } from "./hooks/useSendOtp";
import { Form } from "./shared/Form/Form";
import { authService } from "../../services/AuthService";

export function ChangePassword({
  wpuId,
  header,
  className,
  onSuccess,
  checkAuthBeforeSubmit,
}) {
  var wpuId_ = wpuId || authService.getLoginData().wpuId;
  var sendOtp = useSendOtp(wpuId_);

  return (
    <ContentLoader isLoading={sendOtp.isLoading} className={className}>
      {header}
      <Form
        wpuId={wpuId_}
        resendOtp={sendOtp.refetch}
        checkAuthBeforeSubmit={checkAuthBeforeSubmit}
        onSubmitSuccess={onSuccess}
      />
    </ContentLoader>
  );
}
