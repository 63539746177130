import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useChargeForm } from "../../hooks/useChargeForm";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ViewChargeArWallet = ({ isTipsApplying }) => {
  const { invoice, noTipAmount } = useInvoice();
  const { chargeWallet } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();

  const { data: maxWalletAmount } = useAvailablePaymentMethods(invoice.id, {
    select: (data) => data.wallet.maxAmount,
  });

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: Math.min(maxWalletAmount, noTipAmount),
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeWallet.initiate({ amount });
      },
      initialValues: {
        amount: Math.min(maxWalletAmount, noTipAmount) || "",
      },
    });

  const maxAmountToPay = Math.min(maxWalletAmount, parseFloat(form?.amount));

  return !showTipsModal.value ? (
    <PaymentFormModal
      isLoading={chargeWallet.isLoading}
      isValid={isValid}
      isTipsApplying={isTipsApplying}
      submit={submit}
      form={form}
      errors={errors}
      hasError={hasError}
      changeFormValue={changeFormValue}
      showTipsModal={showTipsModal}
    />
  ) : (
    <PaymentTipsModal
      maxAmountToPay={maxAmountToPay}
      showTipsModal={showTipsModal}
      isTipsApplying={isTipsApplying}
    />
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: state.checkoutInvoice.isTipsApplying,
});

export default connect(mapStateToProps)(ViewChargeArWallet);
