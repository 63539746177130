import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getIsPosEnabled, toggleBodyScroll } from '../../../Utils/services.js';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePosStatus, fetchRolePermissions } from "../../../Actions/Settings/settingsActions.js";
import RestrictionModal from "../ManageClinics/Clinics/RestrictionModal.js";
import { SETTINGS_ROUTES } from "../../../consts/routes.js";

class PosTitle extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem('userData'));

    this.state = {
      stripeAccount : (userData && userData.account && userData.account.pos_gateway === 'stripe' && userData.account.account_type === 'paid' && userData.user_type === 'superadmin') ? true : false,
      clearentAccount : (userData && userData.account && userData.account.pos_gateway === 'clearent' && userData.account.account_type === 'paid' && userData.user_type === 'superadmin') ? true : false,
      pos_enabled : getIsPosEnabled(),
      alertModalContent : '',
      isShowAlertModal : false,
      userData:userData,
      isTouchMD : userData.account.account_preference.is_touch_md,
      plan_selected : userData.account.account_subscription.plan_code, 
      showErrorDisablePosModal: false,
      posSettingsPermission: false
    }
  }

  componentDidMount() {
    const currentUserRole = JSON.parse(localStorage.getItem('currentUserRole'));
    const userPermissions = this.state.userData?.permissions;

    this.props.fetchRolePermissions(currentUserRole)
      .then(result => {
        const posPermission = result.data.data.permissions.settings.find(permission => permission.sysname === "access-pos-settings");
        this.setState({ posSettingsPermission: Boolean(posPermission?.value) || userPermissions.includes("access-pos-settings") });
     })  
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {}
    const userData = JSON.parse(localStorage.getItem('userData'));
    if(nextProps.pos_enabled !== undefined){
      returnState.pos_enabled = (nextProps.pos_enabled) ? true : false;
    } else if(nextProps.pos_enabled == undefined && prevState.pos_enabled !== getIsPosEnabled()){
      returnState.pos_enabled = getIsPosEnabled();
    }
    returnState.stripeAccount = (userData && userData.account && userData.account.pos_gateway === 'stripe' && userData.account.account_type === 'paid' && (userData.user_type === 'superadmin' || userData.user.user_role_id === 1)) ? true : false
    return returnState;
  }


  redirectToPos = () => {
    return (
      <div>
        //{this.props.history.push(`/settings/pos`)}
      </div>
    )
  }

  handleInputChange = (event) => {
    let returnState = {}
    let value = event.target.value;
    let name = event.target.name;
    switch (event.target.type) {
      case 'checkbox':
        value = event.target.checked;
        break;
    }
    if(!value){
      returnState.alertModalType = 'pos_enabled';
      returnState.isShowAlertModal = true;
      returnState.alertModalContent = this.props.settingsLang.pos_enable_alert_msg;
      toggleBodyScroll(false)
      this.setState(returnState);
    } else {
      this.props.changePosStatus({status:1})
    }
  }

  handlePosDisable = () => {
    toggleBodyScroll(false)
    this.setState({isShowAlertModal: false})
    this.props.changePosStatus({status:0}).then(res => {
      toast.success(this.state.globalLang[res.message] || "Status Changed");
    }).catch(error => {
      if(error.message === "disable_pos_active_membership_error") {
        this.setState({ showErrorDisablePosModal: true })
      } else {
        toast.error(this.state.globalLang[res.message] || "Unable to Change Status");
      }
    })
  }

  handleAlertModal = () => {
    toggleBodyScroll(!this.state.isShowAlertModal)
    this.setState({isShowAlertModal: !this.state.isShowAlertModal})
  }

  get isCardReadersTab () {
    return this.props.match.params.activeTab === "card-readers"
  }

  get isGeneralSettingsTab () {
    return this.props.location.pathname === "/settings/pos"
  }

  get havePosSettingsPermission () { 
    return this.state.posSettingsPermission || this.state.userData?.user_type === "superadmin"
  }

  get haveStripeDocumentsPermission () {
    return this.state.userData?.permissions.includes("view-delete-stripe-documents") || this.state.userData?.user_type === "superadmin"
  }

  render() {
    return (
      <div className="title-sub-navigation">
        {this.state.userData && this.state.userData.user_type === 'superadmin' && <div className="setting-setion m-b-10">
          <div className="membership-title">{this.props.title ? this.props.title : this.props.settingsLang.sidebar_POS_settings_menu}
            <span className="setting-custom-switch pull-right no-margin">
              <span id="enabled-text">
                {this.state.pos_enabled ? this.props.settingsLang.pos_enabled_label : this.props.settingsLang.pos_disabled_label }
                </span>
              <label className="setting-switch pull-right">
                <input type="checkbox" id="pos_enabled_title" name="pos_enabled" className=" setting-custom-switch-input" checked={this.state.pos_enabled ? "checked" : false} value={this.state.pos_enabled} onChange={this.handleInputChange} />
                <span className="setting-slider" />
              </label>
            </span>
          </div>
        </div>}
        {((this.state.stripeAccount === true || this.state.clearentAccount === true) && this.state.pos_enabled === true) && this.props.openFrom !== 'onboarding' &&
          <div className="setting-setion m-b-10">
              <div className="membership-title">
              {this.state.userData && this.state.userData.user_type === "superadmin" && 
              <>
              <Link to="/settings/pos" className={this.isGeneralSettingsTab ? "newTabs" : "newTabs disabled-tab"}>General Settings</Link>
              {this.state.userData.account && this.state.userData.account.pos_gateway == 'clearent' && 
                  <Link to="/settings/pos/card-readers" className={this.isCardReadersTab ? "newTabs" : "newTabs disabled-tab"}>Card Readers</Link> }
              </>}
                {(getIsPosEnabled() === true) &&
                  <>
                    {this.state.userData?.account?.pos_gateway == 'stripe' && this.havePosSettingsPermission && <Link to="/settings/pos-payouts" className={(this.props.activeMenu === 'pos-payouts') ? "newTabs" : "newTabs disabled-tab"}>{this.props.settingsLang.sidebar_POS_payouts_menu}</Link>}
                    {this.state.userData?.user_type === "superadmin" && this.state.userData?.account?.pos_gateway == 'stripe' && this.state.userData?.account?.stripe_mode === "custom" &&  <Link to="/settings/pos-payment-settings" className={(this.props.activeMenu === 'pos-payment-settings') ? "newTabs" : "newTabs disabled-tab"}>{'Payout Settings'}</Link>}
                    {this.state.userData?.account?.pos_gateway == 'stripe' && this.havePosSettingsPermission &&  <Link to="/settings/pos-payments" className={(this.props.activeMenu === 'pos-payments') ? "newTabs" : "newTabs disabled-tab"}>{this.props.settingsLang.sidebar_POS_payments_menu}</Link>}
                    {this.state.userData?.account?.pos_gateway == 'stripe' && <Link to="/settings/pos-dashboard" className={(this.props.activeMenu === 'pos-dashboard') ? "newTabs" : "newTabs disabled-tab"}>{this.props.settingsLang.sidebar_POS_dashboard_menu}</Link>}
                    {this.state.userData?.account?.pos_gateway == 'stripe' && this.havePosSettingsPermission &&  <Link to="/settings/pos-reconciliation" className={(this.props.activeMenu === 'pos-reconciliation') ? "newTabs" : "newTabs disabled-tab"}>Reconciliation</Link>}
                    {this.state.userData?.user_type === "superadmin" && this.state.userData?.account?.pos_gateway == 'stripe' && this.state.userData?.account?.stripe_mode === "custom" && <Link to="/settings/pos-klarna" className={(this.props.activeMenu === 'pos-klarna') ? "newTabs" : "newTabs disabled-tab"}>Klarna</Link>}
                  </>
              }
              </div>
            </div>
          }
          {/* Alert Modal - START */}
          <div className={this.state.isShowAlertModal ? "modalOverlay" : 'no-display' }>
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">{this.props.globalLang.label_alert}</div>
                <a className="small-cross" onClick={this.handleAlertModal} >×</a>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <div className="setting-field-outer">
                        {(this.state.alertModalType != 'warning') &&
                          <div className="new-field-label font-size-12 alert-modal-title">{this.props.globalLang.are_you_sure}</div>
                        }
                        <div className="new-field-label font-size-12 alert-modal-content">{this.state.alertModalContent}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a className="new-white-btn pull-right" onClick={this.handleAlertModal}>{this.props.globalLang.label_cancel}</a>
                <a className="new-red-btn pull-left" onClick={this.handlePosDisable} >{this.props.settingsLang.yes_disable_it}</a>
              </div>
            </div>
          </div>
          {/* Alert Modal - END */}

          {this.state.showErrorDisablePosModal && 
            <RestrictionModal  closeModal={() => this.setState({ showErrorDisablePosModal: false })}>
              <p className='text-center'> 
                You cannot disable the POS for an account that has active memberships. Please cancel the patient memberships that are associated with this clinic and then you can disable it.
              </p>
            </RestrictionModal>}

        </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changePosStatus, fetchRolePermissions }, dispatch)
}

export default connect(null, mapDispatchToProps) (withRouter(PosTitle))