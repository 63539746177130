import { useDeletePatientMutation } from "../../../../../../api/patient/useDeletePatientMutation";
import { history } from "../../../../../../history";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useDelete(clientId, onClose) {
  const onDelete = useDeletePatientMutation({
    onSuccess: () => {
      onClose();
      let recentlyViewedSaved = JSON.parse(
        localStorage.getItem("recentlyViewedData"),
      );

      if (recentlyViewedSaved) {
        if (recentlyViewedSaved.length > 0) {
          recentlyViewedSaved.map((obj, idx) => {
            if (obj.profile_id === parseInt(clientId)) {
              recentlyViewedSaved.splice(idx, 1);
            }
          });

          localStorage.removeItem("recentlyViewedData");
          localStorage.setItem(
            "recentlyViewedData",
            JSON.stringify(recentlyViewedSaved),
          );
        }
      }
      history.push(`/clients`);
      uiNotification.success("Patient has been successfully deleted");
    },

    onError: () => {
      uiNotification.error("Unable to delete client");
    },
  });

  return {onDelete}
}