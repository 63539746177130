import React from "react";
import cx from "clsx";
import styles from "./ClietLoyaltyProgram.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { getPathParams } from "../../utils/utils";

export function ClientLoyaltyProgram() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });

  return (
    <div>
      <div className={cx(styles.clientWrapper, "col-xs-12")}>
        <div className={styles.title}>Loyalty Programs Enrollment</div>
        <div className={styles.programBlock}>
          <div className={styles.item}>
            <div className={styles.itemText}>Alle:</div>
            <input type="checkbox" checked={Boolean(clientData?.brilliantAccNumber)} readOnly />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>Aspire:</div>
            <input type="checkbox" checked={Boolean(clientData?.aspireAccEmail)} readOnly />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>Evolus:</div>
            <input type="checkbox" checked={Boolean(clientData?.evolus)} readOnly />
          </div>
          <div className={styles.item}>
            <div className={styles.itemText}>
              Xperience:
            </div>
            <input type="checkbox" checked={Boolean(clientData?.xperienceUsername)} readOnly />
          </div>
        </div>
      </div>
    </div>
  );
}
