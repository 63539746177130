import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import classes from "./Notes.module.scss";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { PatternAutocomplete } from "../../../../../widgets/PatternAutocomplete/PatternAutocomplete";
import { useDotPhrasesPatterns } from "./hooks/useDotPhrasesPatterns";
import { PATTERNS } from "./Notes.consts";
import { useMedicalHistoryPatterns } from "./hooks/useMedicalHistoryPatterns";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Dictation } from "../../../../../widgets/Dictation/Dictation";

export function Notes({ value, onChange, isLoading, clientId }) {
  const { tClients } = useAppTranslation.Clients();

  const dotPhrasesPatterns = useDotPhrasesPatterns();
  const medicalHistoryPatterns = useMedicalHistoryPatterns({ clientId });

  return isLoading ? (
    <div className={classes.root}>
      <Skeleton count={1} height={80} />
    </div>
  ) : (
    <div className={classes.root}>
      <Dictation value={value} onChange={onChange} />
      <AlertBox fitContent size="small">
        <Trans
          i18nKey={tClients("createEditHealthProcedure.notes.note")}
          components={{
            b: <b />,
          }}
        />
      </AlertBox>
      <div className={classes.notes}>
        <PatternAutocomplete
          value={value || ""}
          onChange={onChange}
          placeholder={tClients("createEditHealthProcedure.notes.placeholder")}
          patterns={[
            {
              match: PATTERNS.dot,
              substitutes: dotPhrasesPatterns.data,
            },
            {
              match: PATTERNS.medicalHistory,
              substitutes: medicalHistoryPatterns.data,
            },
          ]}
        />
        <div className={classes.note}>
          {tClients("createEditHealthProcedure.notes.dotPhrasesDesc")}
        </div>
        <div className={classes.note}>
          {tClients("createEditHealthProcedure.notes.callFuncDesc")}
        </div>
      </div>
    </div>
  );
}

Notes.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};
