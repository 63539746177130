import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import classes from "../sass/Table.module.scss";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../consts/general";
import { QUALIPHY_REPORT_ORDER_BY } from "../../../../../../api/qualiphy/useQualiphyReportQuery";
import { capitalize } from "../../../../../../utilities/general";
import { useClientGlobalName } from "../../../../../../hooks/useClientGlobalName";

export function Table({ reports, order }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();
  const clientGlobalName = useClientGlobalName();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const data = reports.value.map((r) => ({
    id: r.id,

    patientName: r.patientName || tCommon("symbol.longDash"),

    clinicName: r.clinicName || tCommon("symbol.longDash"),

    providerName: r.providerName || tCommon("symbol.longDash"),

    examName: r.examName || tCommon("symbol.longDash"),

    status: r.status ? capitalize(r.status) : tCommon("symbol.longDash"),

    completedAt: r.completedAt
      ? moment(r.completedAt).format(
          user?.previewDateFormat || PREVIEW_DATE_FORMAT,
        )
      : tCommon("symbol.longDash"),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={39.06} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            keyExtractor={(row) => row.id}
            data={data}
            cols={[
              {
                data: renderSortWrap(
                  clientGlobalName.t({ path: "label.patientName" }),
                  QUALIPHY_REPORT_ORDER_BY.patient,
                ),
                accessor: "patientName",
                headColClassName: classes.defaultCol,
              },
              {
                data: renderSortWrap(
                  tCommon("label.clinic"),
                  QUALIPHY_REPORT_ORDER_BY.clinic,
                ),
                accessor: "clinicName",
                headColClassName: classes.defaultCol,
              },
              {
                data: renderSortWrap(
                  tCommon("label.provider"),
                  QUALIPHY_REPORT_ORDER_BY.provider,
                ),
                accessor: "providerName",
                headColClassName: classes.defaultCol,
              },
              {
                data: renderSortWrap(
                  tCommon("label.exam"),
                  QUALIPHY_REPORT_ORDER_BY.exam,
                ),
                accessor: "examName",
                headColClassName: classes.defaultCol,
              },
              {
                data: renderSortWrap(
                  tCommon("label.status"),
                  QUALIPHY_REPORT_ORDER_BY.status,
                ),
                accessor: "status",
              },
              {
                data: renderSortWrap(
                  tCommon("label.completedAt"),
                  QUALIPHY_REPORT_ORDER_BY.completedAt,
                ),
                accessor: "completedAt",
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};
