import React from "react";
import PropTypes from "prop-types";

export const FileMedicalIcon = ({
  width = "1em",
  height = "1em",
  title = "File Medical Icon",
  color = "#3182ce",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 384 512"
    fill={color}
    aria-hidden="true"
    aria-labelledby={`file-medical-title-${title
      .replace(/\s+/g, "-")
      .toLowerCase()}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title
      id={`file-medical-title-${title.replace(/\s+/g, "-").toLowerCase()}`}
    >
      {title}
    </title>
    <path
      d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H48C21.5 0 0 21.5 0 48v416c0
      26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V122c0-6.4-2.5-12.5-7-17zM256
      80l80 80h-80V80zm64 280c0 8.8-7.2
      16-16 16h-64v64c0 8.8-7.2 16-16 16h-32c-8.8
      0-16-7.2-16-16v-64H112c-8.8 0-16-7.2-16-16v-32c0-8.8
      7.2-16 16-16h64v-64c0-8.8 7.2-16
      16-16h32c8.8 0 16 7.2 16
      16v64h64c8.8 0 16 7.2 16
      16v32z"
    />
  </svg>
);

FileMedicalIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  fill: PropTypes.string,
  color: PropTypes.string,
};
