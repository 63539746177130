import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  wpuId: yup.number().required(),
});

const responseSchema = yup.object({
  last4: yup.string().required(),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createSendChangePasswordOtpQueryKey = (...args) => [
  QUERY_KEYS.sendChangePasswordOtp,
  ...args,
];

export function useSendChangePasswordOtpQuery({ payload, options = {} } = {}) {
  return useQuery(
    createSendChangePasswordOtpQueryKey(payload?.wpuId),
    async () => {
      const req = requestSchema.validateSync(payload, { strict: true });
      const res = await http.get(
        HTTP_ENDPOINTS.sendChangePasswordOtp(req.wpuId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
