import React from "react";
import PropTypes from "prop-types";

export const ProceduresIcon = ({
  width = "1em",
  height = "1em",
  title = "Procedures Icon",
  color = "#3182ce",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 640 512"
    fill={color}
    aria-hidden="true"
    aria-labelledby={`procedures-title-${title
      .replace(/\s+/g, "-")
      .toLowerCase()}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title id={`procedures-title-${title.replace(/\s+/g, "-").toLowerCase()}`}>
      {title}
    </title>
    <path d="M528 224c61.9 0 112-50.1 112-112S589.9 0 528 0 416 50.1 416 112s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80 0 4.4-.3 8.8-1 13.1-7.1-4.9-15.6-7.1-24-7.1h-26v-16c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v16h-26c-8.4 0-16.9 2.2-24 7.1-.7-4.3-1-8.7-1-13.1 0-44.1 35.9-80 80-80zM624 368h-16V256c0-17.7-14.3-32-32-32h-64c-17.7 0-32 14.3-32 32v112H352V96c0-17.7-14.3-32-32-32H208c-17.7 0-32 14.3-32 32v272H48c-26.5 0-48 21.5-48 48v48c0 8.8 7.2 16 16 16h544c8.8 0 16-7.2 16-16v-48c0-26.5-21.5-48-48-48zM192 112h128v112H192V112z" />
  </svg>
);

ProceduresIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  fill: PropTypes.string,
  color: PropTypes.string,
};

export default ProceduresIcon;
