import React, { useMemo } from "react";
import classes from "../../Clients.module.scss";
import { Table } from "../../../../shared/Table/Table";
import { useRepeatMdWallet } from "../../hooks/useRepeatMdWallet";
import { withRouter } from "react-router";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import moment from "moment/moment";
import {PREVIEW_DATE_FORMAT} from "../../../../consts/general";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";

const RepeatMdWallet = ({ match }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();
  const { data: user } = useCurrentUserQuery();

  const patientId = match.params.clientID;
  const { data: mdWallet } = useRepeatMdWallet(patientId);

  const tableData = useMemo(
    () =>
      mdWallet?.reduce((acc, curr) => {
        const { items } = curr;
        const result = items.map((item) => {
          return {
            item: (function (i) {
                return <p>{i.name} <i className="m-0">(acquired on {moment(i.dateAcquired).format(user?.dateFormat || PREVIEW_DATE_FORMAT)})</i></p>
            })(item),
            clinic: curr.clinicName,
            balanceUnits: item.available,
          };
        });
        return [...acc, ...result];
      }, []),
    [mdWallet],
  );

  console.log(classes.clientWalletTableRow, classes.clientWalletTableHead);

  return (
    <>
      <div className="juvly-container">
        <h1 className={classes.repeatMdTitle}>{tClients("repeatMd.title")}</h1>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          bgColor="white"
          keyExtractor={(row) => row?.id}
          colClassName={classes.clientWalletTableRow}
          headColClassName={classes.clientWalletTableHead}
          bodyClassName={classes.clientWalletTableBody}
          data={tableData}
          cols={[
            {
              data: tClients("repeatMd.table.item"),
              accessor: "item",
            },
            {
              data: tClients("repeatMd.table.clinic"),
              accessor: "clinic",
            },
            {
              data: tClients("repeatMd.table.balanceUnits"),
              accessor: "balanceUnits",
            }
          ]}
        />
        {!tableData?.length && (
          <p className={classes.noRecord}>{tCommon("label.noRecordFound")}</p>
        )}
      </div>
    </>
  );
};

export default withRouter(RepeatMdWallet);
