import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { img } from "../../../../../../assets/img";
import classes from "../ViewChargeKlarna.module.scss";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { createKlarnaPayment } from "../../../../../../Actions/Sales/klarnaActions";
import { dispatch } from "../../../../../../store/store";

const PaymentTipsModal = ({
  isTipsApplying,
  isCharging,
  form,
  setIsCharging,
  showTipsModal,
  createKlarnaPayment,
}) => {
  const { invoice } = useInvoice();
  const totalAmountToPay =
    parseFloat(form.amount) + parseFloat(invoice.tipAmount);

  const handleSubmit = async () => {
    setIsCharging(true);

    await createKlarnaPayment({
      requestedAmount: totalAmountToPay,
      invoiceId: invoice.id,
      patientPhone: form.phoneNumber,
      patientEmail: form.email,
    });

    setIsCharging(false);
  };

  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.klarna")}
      imageSrc={img.klarna}
      imageClassName={classes.titleKlarna}
      onCharge={handleSubmit}
      isCharging={isCharging}
      chargeLabel={tCommon("label.continue")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

const mapDispatchToProps = () => {
  return bindActionCreators(
    {
      createKlarnaPayment,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(PaymentTipsModal);
