import { useState } from "react";
import { useLoginMutation } from "../../../api/mutations/useLoginMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError, extractApiErrorData } from "../../../utilities/api";
import { ROUTES } from "../../../consts/routes";
import { authService } from "../../../services/AuthService";
import { history } from "../../../history";
import { useSwitchWorkspace } from "../../../hooks/useSwitchWorkspace";
import { LOGIN_ERROR_KEYS } from "../Login.consts";
import { useSessionExpireMessage } from "./useSessionExpireMessage";

const STEPS = {
  changePassword: 0,
  confirmLogin: 1,
  finishLogin: 2,
};

const ABRUPT_STEP = "abrupt";

export function useLogin() {
  useSessionExpireMessage();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const [cachedResponse, setCachedResponse] = useState(null);

  const { tAuth } = useAppTranslation.Auth();
  const { mutateAsync, isLoading } = useLoginMutation();
  const { switchWorkspace, isWorkspaceSwitching } = useSwitchWorkspace();

  const handleError = (e) => {
    const fallbackError = "error.fallbackLogin";
    if (Object.values(LOGIN_ERROR_KEYS).includes(e.message)) {
      uiNotification.error(tAuth([`error.${e.message}`, fallbackError]));
    } else {
      const key = extractApiError(e);

      if (key === "must_change_password") {
        const data = extractApiErrorData(e);
        history.push(ROUTES.changePassword(data?.wpuId || 0) + "?message=true");
      } else {
        uiNotification.error(tAuth([`apiError.${key}`, fallbackError]));
      }
    }
  };

  const finishLogin = ({ res, standalone = false }) => {
    try {
      if (!res) throw Error();

      if (res.need2Fa) {
        authService.setPreLoginData({
          wpuId: res.wpuId,
          twoFactorType: res.twoFactorType,
          googleAuthCode: res.googleAuthCode,
        });
        history.push(ROUTES.twoFactorLogin());
      } else if (res.accessToken && res.wpuId) {
        if (res.workspaces.length === 0) {
          throw new Error(LOGIN_ERROR_KEYS.emptyWorkspaces);
        }

        authService.setLoginData({
          accessToken: res.accessToken,
          wpuId: res.wpuId,
        });

        if (res.workspaces.length === 1) {
          switchWorkspace({ workspaceId: res.workspaces[0].id }).then(
            authService.afterLogin,
          );
        } else {
          window.location.href = ROUTES.workspaces();
        }
      } else {
        throw Error();
      }
    } catch (e) {
      if (standalone) {
        handleError(e);
      } else {
        throw e;
      }
    }
  };

  const login_ = (res, steps_ = Object.values(STEPS), standalone = false) => {
    const steps = {
      [STEPS.changePassword]: () => {
        if (res.passwordExpireInDays > 0) {
          setCachedResponse(res);
          setIsChangePasswordModalOpen(true);
          setIsConfirmModalOpen(false);
          return ABRUPT_STEP;
        }
      },
      [STEPS.confirmLogin]: () => {
        if (res.alreadyLoggedIn) {
          setCachedResponse(res);
          setIsConfirmModalOpen(true);
          setIsChangePasswordModalOpen(false);
          return ABRUPT_STEP;
        }
      },
      [STEPS.finishLogin]: () => {
        setIsChangePasswordModalOpen(false);
        setIsConfirmModalOpen(false);
        finishLogin({ res, standalone });
      },
    };

    for (let i = 0; i < steps_.length; i++) {
      const step = steps[steps_[i]]();
      if (step === ABRUPT_STEP) {
        break;
      }
    }
  };

  const login = async (formValues) => {
    try {
      const res = await mutateAsync({
        email: formValues.email,
        password: formValues.password,
      });

      login_(res);
    } catch (e) {
      handleError(e);
    }
  };

  return {
    login,
    isLoggingIn: isLoading || isWorkspaceSwitching,
    confirmChangePassword: {
      isOpen: isChangePasswordModalOpen,
      close: () =>
        login_(cachedResponse, [STEPS.confirmLogin, STEPS.finishLogin], true),
      confirm: () => history.push(ROUTES.changePassword(cachedResponse.wpuId)),
      expireInDays: cachedResponse?.passwordExpireInDays,
    },
    confirmLogin: {
      isOpen: isConfirmModalOpen,
      close: () => setIsConfirmModalOpen(false),
      finishLogin: () => login_(cachedResponse, [STEPS.finishLogin], true),
    },
  };
}
