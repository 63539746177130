import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PLAN_CODES } from "../../../../../../consts/api";
import { checkIfPermissionAllowed } from "../../../../../../Utils/services";
import { ModalCreateQualiphyProcedure } from "../../../../../../widgets/ModalCreateQualiphyProcedure/ModalCreateQualiphyProcedure";
import { useCurrentAccountQuery } from "../../../../../../api/queries/useAccountQuery";

export function Header({
  onChangeType,
  type,
  profile,
  onCreateAppointment,
  onCreateProcedure,
  patientId,
  isQualiphyEnabled,
  refetchProcedures,
}) {
  const { data: account } = useCurrentAccountQuery();

  const isTouchMd = account?.preferences?.isTouchMd;
  const planCode = account?.subscription?.planCode;

  const ePrescriptionSetup = localStorage.getItem("ePrescriptionSetup")
    ? JSON.parse(localStorage.getItem("ePrescriptionSetup"))
    : null;

  const [
    isCreateQualiphyProcedureModalOpen,
    setIsCreateQualiphyProcedureModalOpen,
  ] = useState(false);

  return (
    <>
      <ul className="section-title section-tab-outer setting-tabs setting-sub-tabs">
        <li>
          <a
            data-title="Single"
            onClick={() => onChangeType("procedure")}
            id="client_procedure_tab"
            className={
              type === "procedure" || type === "cosmetic" || type === "health"
                ? "section-title-name r section-tab sel-merge-tab"
                : "section-title-name r section-tab"
            }
          >
            {isTouchMd && planCode === PLAN_CODES.booking
              ? "Appointments"
              : "Procedures"}
          </a>
        </li>
        {isTouchMd && planCode === PLAN_CODES.booking ? (
          ""
        ) : profile?.active_treamtment_plan === 1 ||
          profile?.priscribed_treatment_plans?.length > 0 ? (
          <li>
            <a
              data-title="Treatment Plan"
              onClick={() => onChangeType("treatmentPlan")}
              id="plan_tab"
              className={
                type !== "" && type === "treatmentPlan"
                  ? "section-title-name r section-tab sel-merge-tab"
                  : "section-title-name r section-tab"
              }
            >
              Treatment Plan
            </a>
          </li>
        ) : (
          ""
        )}
        {checkIfPermissionAllowed("manage-procedures") &&
          type !== "" &&
          (type === "procedure" ||
            type === "cosmetic" ||
            type === "health") && (
            <div className="dropdown pull-right create-procedure-dropdown">
              {isTouchMd && planCode === PLAN_CODES.booking ? (
                <a
                  onClick={onCreateAppointment}
                  className="blue-btn pull-right"
                >
                  Create Appointment
                </a>
              ) : (
                <button
                  className="line-btn pull-right"
                  type="button"
                  id="dropdownMenuCreateProcedure"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Create Procedure <i className="fas fa-angle-down"></i>
                </button>
              )}
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuCreateProcedure"
              >
                <li>
                  <a
                    className="header-unselect-btn modal-link"
                    onClick={() => onCreateProcedure("cosmetic")}
                  >
                    Cosmetic
                  </a>
                </li>
                <li>
                  <a
                    className="header-unselect-btn modal-link"
                    onClick={() => onCreateProcedure("health")}
                  >
                    Health
                  </a>
                </li>
                {isQualiphyEnabled && (
                  <li>
                    <a
                      className="header-unselect-btn modal-link"
                      onClick={() =>
                        setIsCreateQualiphyProcedureModalOpen(true)
                      }
                    >
                      Qualiphy
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        {ePrescriptionSetup != null &&
          ePrescriptionSetup.e_prescription_new && (
            <Link
              className="new-blue-btn ePrescribeBtn"
              to={`/clients/procedure-health/add/${patientId}/profile/true`}
            >
              ePrescribe
            </Link>
          )}
      </ul>
      {isCreateQualiphyProcedureModalOpen && (
        <ModalCreateQualiphyProcedure
          isOpen
          patientId={patientId}
          onSuccess={() => {
            refetchProcedures();
            setIsCreateQualiphyProcedureModalOpen(false);
          }}
          onClose={() => setIsCreateQualiphyProcedureModalOpen(false)}
        />
      )}
    </>
  );
}
