import React from "react";
import cx from "clsx";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { showFormattedDate } from "../../../../../../Utils/services";
import styles from "../scss/stripeDocuments.module.scss";
import VIEW_ICON from "../../../../../../Components/Clients/Documents/icons/view.svg";
import { useDownloadStripeDocument } from "../hooks/useDownloadStripeDocument";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { useDeleteStripeDocument } from "../hooks/useDeleteStripeDocument";
import { DeleteIcon } from "../../../../../../assets/Icons/DeleteIcon";

const Table = ({ report, selectedDocuments }) => {
  const { mutation: download } = useDownloadStripeDocument();
  const {
    mutate: deleteDocument,
    isLoading: isDeleting,
    variables: deleteVariables,
  } = useDeleteStripeDocument();

  const handleView = (item) => {
    download.mutate({ document_ids: [item.id] });
  };

  const handleDelete = (item) => {
    deleteDocument(
      { document_id: item.id },
      { onSuccess: () => report.refetch() },
    );
  };

  const isEmpty = report.list.length === 0;
  const tableData = report.list.map((item) => ({
    select_action: (
      <input
        type="checkbox"
        onChange={() => selectedDocuments.handleSelectDocument(item.id)}
        checked={selectedDocuments.value.includes(item.id)}
      />
    ),
    file_name: item.file_name,
    file_type: item.file_type,
    editor: item.editor,
    clinic: item.clinic,
    upload_date: showFormattedDate(item.upload_date),
    actions: (
      <div className={styles.actionData}>
        <button
          className={cx(styles.actionButtons, styles.viewIcon)}
          onClick={() => handleView(item)}
        >
          {download.isLoading &&
          download.variables?.document_ids[0] === item.id ? (
            <CircularProgress size="tiny" />
          ) : (
            <img
              src={VIEW_ICON}
              className="cursor-pointer min-w-16"
              title="View"
              width={16}
              height={16}
              alt=""
            />
          )}
        </button>
        <button
          className={cx(styles.actionButtons, styles.deleteIcon)}
          onClick={() => handleDelete(item)}
          title="Delete"
        >
          {isDeleting && deleteVariables?.document_id === item.id ? (
            <CircularProgress size="tiny" />
          ) : (
            <DeleteIcon width={16} />
          )}
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <SharedTable
        bgColor="white"
        keyExtractor={(row) => row.id}
        data={tableData}
        cols={[
          {
            data: (
              <input
                type="checkbox"
                onChange={() =>
                  selectedDocuments.handleSelectAllDocuments(report.list)
                }
                checked={
                  selectedDocuments.value.length === report.list.length &&
                  report.list.length > 0
                }
              />
            ),
            accessor: "select_action",
          },
          {
            data: "File Name",
            accessor: "file_name",
          },
          {
            data: "File Type",
            accessor: "file_type",
          },
          {
            data: "Clinic",
            accessor: "clinic",
          },
          {
            data: "Upload Date",
            accessor: "upload_date",
          },
          {
            data: "Editor",
            accessor: "editor",
          },
          {
            data: "Actions",
            accessor: "actions",
          },
        ]}
      />
      {report.isLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && !report.isLoading && <Empty position="center" />}
    </div>
  );
};

export default Table;
