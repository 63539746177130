import { useEffect, useRef, useState, useCallback } from "react";
import _ from "lodash";

// eslint-disable-next-line import/no-restricted-paths
import { getMicAudioStream } from "../../../_legacy/Transcribe/transcribe/audio";

import {
  closeSocket,
  streamAudioToWebSocket,
  // eslint-disable-next-line import/no-restricted-paths
} from "../../../_legacy/Transcribe/transcribe/websocketUtils";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";

import {
  formatSOAPNote,
  formatProcedureNote,
  formatSurgicalNote,
  formatFollowUpVisit,
} from "../utils/NoteGeneration";

export function useDictation({ text, onReadText }) {
  const { tCommon } = useAppTranslation.Common();
  const [isRecording, setIsRecording] = useState(false);
  const [partialText, setPartialText] = useState("");
  const [isGeneratingNote, setIsGeneratingNote] = useState(false);
  const textRef = useRef(text);
  const streamRef = useRef(null);

  const prepareText = useCallback((t = "") => {
    return t.replace("you was admitted", "he was admitted");
  }, []);

  const updateTranscript = useCallback(
    async (data) => {
      const preparedText = prepareText(data?.text || "");

      if (data?.isPartial) {
        setPartialText(preparedText);
      } else {
        let finalDiction = textRef.current || "";
        finalDiction += preparedText + " ";

        setPartialText("");
        onReadText(finalDiction.trim());
      }
    },
    [prepareText, onReadText],
  );

  const generateNote = async (noteType) => {
    if (!textRef.current?.trim()) {
      uiNotification.warning(tCommon("error.noTextToFormat"));
      return;
    }

    setIsGeneratingNote(true);
    try {
      let formattedNote;
      switch (noteType) {
        case "SOAP":
          formattedNote = await formatSOAPNote(textRef.current);
          break;
        case "PROCEDURE":
          formattedNote = await formatProcedureNote(textRef.current);
          break;
        case "SURGICAL":
          formattedNote = await formatSurgicalNote(textRef.current);
          break;
        case "FOLLOW_UP":
          formattedNote = await formatFollowUpVisit(textRef.current);
          break;
        default:
          throw new Error("Invalid note type");
      }
      onReadText(formattedNote);
    } catch (error) {
      uiNotification.error(tCommon("error.noteGenerationFailed"));
    } finally {
      setIsGeneratingNote(false);
    }
  };

  const onStartRecording = async () => {
    try {
      setIsRecording(true);
      const micAudioStream = await getMicAudioStream();
      streamRef.current = micAudioStream;
      await streamAudioToWebSocket(micAudioStream, updateTranscript);
    } catch (error) {
      uiNotification.error(tCommon("error.audioNotSupported"));
      setIsRecording(false);
    }
  };

  const onStopRecording = useCallback(async () => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
      closeSocket();
      setIsRecording(false);
      setPartialText("");
    } catch (error) {
      uiNotification.error(tCommon("error.audioNotSupported"));
    }
  }, []);

  const onToggle = useCallback(() => {
    if (isRecording) {
      onStopRecording();
    } else {
      onStartRecording();
    }
  }, [isRecording, onStopRecording]);

  useEffect(() => {
    textRef.current = text;
  }, [text]);

  useEffect(() => {
    return () => {
      if (isRecording) {
        onStopRecording();
      }
    };
  }, [isRecording, onStopRecording]);

  return {
    isRecording,
    isGeneratingNote,
    onToggle,
    partialText,
    generateNote,
  };
}
