import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../../assets/svg";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";

const PaymentTipsModal = ({
  isTipsApplying,
  totalAmountToPay,
  showTipsModal,
  submit,
  isLoading,
}) => {
  const onClose = () => {
    showTipsModal.update(false);
  };
  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.text2pay")}
      imageSrc={svg.text2Pay}
      onCharge={submit}
      isCharging={isLoading}
      isChargeDisabled={isTipsApplying}
      chargeLabel={tCommon("label.apply")}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
