import React, { useState } from "react";
import cx from "clsx";
import { Modal } from "../../../../../../shared/Modal/Modal";
import classes from "../../../../../../boxes/ConfirmModal/ConfirmModal.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { Input } from "../../../../../../shared/Input/Input";
import { InputError } from "../../../../../../shared/InputError/InputError";
import {
  useResetPatientPortalPasswordMutation,
} from "../../../../../../api/patient/useResetPatientPortalPasswordMutation";
import styles from "./ResetPasswordModal.module.scss";
import { uiNotification } from "../../../../../../services/UINotificationService";

import { PASSWORD_PATTERN_REGEX } from "../../../../../../consts/regexps";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";

export function ResetPasswordModal({ isOpen, onClose, email, clientId }) {
  const [passwordType, setPasswordType] = useState({
    type: "password",
    eyeShow: false,
  });
  const [password, setPassword] = useState(null);
  const [showError, setShowError] = useState(false);

  const onCloseModal = () => {
    setPassword(null);
    onClose();
  };

  const onResetPassword = useResetPatientPortalPasswordMutation({
    onSuccess: () => {
      setPasswordType({ type: "password", eyeShow: false });
      onCloseModal();
    },
    onError: () => {
      uiNotification.error("Unable to reset patient password. Try again later");
    },
  });

  const isValidPass = () => {
    if (password) {
      return PASSWORD_PATTERN_REGEX.test(
        password,
      );
    }

    return false;
  };

  const changePasswordType = () => {
    if (passwordType.type === "password") {
      return setPasswordType({ type: "text", eyeShow: true });
    }

    return setPasswordType({ type: "password", eyeShow: false });
  };

  const confirmResetPassword = () => {
    if (!isValidPass()) {
      return setShowError(true);
    }
    setShowError(false);
    const formData = {
      portalEmail: email,
      patientUserId: +clientId,
      portalPassword: password,
    };
    onResetPassword.mutate(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      className={styles.resetModalWraooer}
      onClose={onCloseModal}
      header={<Modal.Title>Reset password</Modal.Title>}
      footer={
        <div className={classes.buttons}>
          <Button onClick={confirmResetPassword} leftAdornment={
            onResetPassword.isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          } isDisabled={onResetPassword.isLoading}>Confirm</Button>
          <Button variant="outlined" onClick={onCloseModal}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className={styles.form}>
        <div>
          <Input
            placeholder="User Email Id"
            name="email"
            value={email}
            id="validEmail"
            type="text"
            isDisabled
            readOnly
          />
        </div>
        <div className={styles.passwordInput}>
          <Input
            placeholder="New password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="newPassword"
            type={passwordType.type}
          />
          <button
            onClick={changePasswordType}
            className={cx(styles.clientTreatCal, {
              [styles.passHidden]: !passwordType.eyeShow,
              [styles.passShow]: passwordType.eyeShow,
            })}
          ></button>
          {showError && <InputError>Password do not match</InputError>}
        </div>
      </div>
      <div className={styles.passwordNote}>
        Password should be minimum 8 characters. At least one capital letter. At least one Special character.
      </div>
    </Modal>
  );
}
