import React from "react";
import PropTypes from "prop-types";

export function NoteDisplay({ note }) {
  return (
    <div className="bg-gray-100 p-4 rounded-md">
      <pre className="whitespace-pre-wrap font-mono text-sm">{note}</pre>
    </div>
  );
}

NoteDisplay.propTypes = {
  note: PropTypes.string.isRequired,
};
