import { matchPath } from "react-router";
import queryString from "query-string";
import { ROUTES } from "../../consts/routes";
import { Maybe, cond, identity, notNaN, undef } from "../../utilities/fp";

export const getPathParams = () => {
  var searchParams = queryString.parse(window.location.search);

  var leadMatch = matchPath(window.location.pathname, {
    path: ROUTES.changePassword(":wpuId"),
    exact: true,
    strict: false,
  });

  var withMessage = searchParams?.message;
  var wpuId = leadMatch?.params?.wpuId;

  return {
    withMessage: withMessage === "true",
    wpuId: cond(undef, [notNaN, identity])(
      Maybe.of(wpuId).map(Number).orElse(undefined).value(),
    ),
  };
};
