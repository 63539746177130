import * as yup from "yup";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { createPasswordSchema } from "../../../schemas/auth";
import { useExtendedFormik } from "../../../hooks/useExtendedFormik";

export function useForm(submitter) {
  var { tCommon: t } = useAppTranslation.Common();
  var { tAuth } = useAppTranslation.Auth();

  var schema = yup.object({
    oldPassword: yup
      .string()
      .required(t("changePassword.error.oldPasswordRequired")),

    newPassword: createPasswordSchema({
      minError: tAuth("password.error.min"),
      noSpacesError: tAuth("password.error.noSpaces"),
      specialCharError: tAuth("password.error.specialChar"),
      lowerLetterError: tAuth("password.error.lowerLetter"),
      capitalLetterError: tAuth("password.error.capitalLetter"),
    })
      .notOneOf(
        [yup.ref("oldPassword")],
        t("changePassword.error.passwordMatchOld"),
      )
      .required(t("changePassword.error.newPasswordRequired")),

    newPasswordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("newPassword")],
        t("changePassword.error.passwordsNotMatch"),
      )
      .required(t("changePassword.error.confirmPasswordRequired")),

    otp: yup.number().required(t("changePassword.error.otpRequired")),
  });

  var { values, handleChange, handleBlur, handleSubmit, getError } =
    useExtendedFormik({
      onSubmit: submitter,
      enableReinitialize: true,
      validationSchema: schema,
      initialValues: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        otp: "",
      },
    });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    getError,
  };
}
