import React, { useState } from "react";
import { Modal } from "../../../../../../shared/Modal/Modal";
import styles from "./ReassignModal.module.scss";
import classes from "../../../../../../boxes/ConfirmModal/ConfirmModal.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { useDeleteInviteMutation } from "../../../../../../api/mutations/useDeleteInviteMutation";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { Select } from "../../../../../../shared/Select/Select";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function ReassignModal({
  isOpen,
  onClose,
  providers,
  appointmentsCount,
  wpuId,
  refetchUsersPendingInvites,
}) {
  const [providerOption, setProviderOption] = useState(null);

  const deleteInvite = useDeleteInviteMutation({
    onSuccess: () => {
      uiNotification.success("Invite deleted");
      refetchUsersPendingInvites();
      onClose();
    },
    onError: () => {
      uiNotification.error("Unable to delete invite. Try again later");
    },
  });

  const onConfirm = () => {
    deleteInvite.mutate({
      wpuId: wpuId,
      assignToProviderId: providerOption.value,
    });
  };

  const handleSelectChange = (provider) => {
    setProviderOption(provider);
  };

  const providersList = providers.map((provider) => ({
    value: provider.id,
    label: provider.full_name,
  }));

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      header={<Modal.Title>Assign Appointments To Provider</Modal.Title>}
      footer={
        <div className={classes.buttons}>
          {onConfirm && (
            <Button
              onClick={onConfirm}
              isDisabled={deleteInvite.isLoading}
              leftAdornment={
                deleteInvite.isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              Assign & Delete
            </Button>
          )}
        </div>
      }
    >
      <div className={styles.wrapper}>
        <div
          className={styles.modalText}
        >{`Provider has ${appointmentsCount} appointment in future. You can delete provider only if he does not have any appointment or his appointments are reassigned to other provider.`}</div>
        <Select
          name="providers"
          id="provider"
          value={providerOption}
          autoComplete="off"
          placeholder="Provider"
          options={providersList}
          onChange={handleSelectChange}
        />
      </div>
    </Modal>
  );
}
