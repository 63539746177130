import React, { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useConfirmPayment } from "../../hooks/useConfirmPayment";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { Footer } from "../../../../../shared/ModalCharge/Footer";
import { usePaymentFlow } from "../../../../../hooks/usePaymentFlow";
import PaymentTipsModal from "./PaymentTipsModal";

const StripeCheckoutPaymentForm = ({
  saveCard,
  paymentAmount,
  setShowPaymentForm,
  stripeData,
}) => {
  const { isTipsOnlyFlow } = useInvoice();
  const { showTipsModal, isTipsAvailable } = usePaymentFlow();
  const [formCompleted, setFormCompleted] = useState(false);

  const { confirmPayment, isLoading } = useConfirmPayment();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isTipsAvailable && !isTipsOnlyFlow) {
      showTipsModal.update(true);
      return;
    }

    confirmPayment({
      paymentIntentId: stripeData.paymentIntentId,
      saveCard: saveCard,
    });
  };

  return (
    <form>
      <PaymentElement onChange={(event) => setFormCompleted(event.complete)} />
      {showTipsModal.value && (
        <PaymentTipsModal
          saveCard={saveCard}
          formPaymentAmount={paymentAmount}
          setShowPaymentForm={setShowPaymentForm}
        />
      )}

      <Footer
        className={"m-t-0"}
        onClose={(e) => {
          e.preventDefault();
          setShowPaymentForm(false);
        }}
        isCharging={isLoading}
        onCharge={handleSubmit}
        isChargeDisabled={!formCompleted}
        isCancelDisabled={isLoading}
        chargeLabel={"Charge"}
      />
    </form>
  );
};

export default StripeCheckoutPaymentForm;
