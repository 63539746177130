import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import { toast } from "react-toastify";
import {
  getSubscriptionDetails,
  subscriptionAddOn,
  subscriptionCancelReactivate,
  subscriptionUpgradeAccount,
  subscriptionUpdateCard,
  subscriptionAutoRefill,
  getSubscriptionMonthlyEstimate,
  subscriptionUpgradeToYearly,
  exportEmptyData
} from "../../../../Actions/Settings/settingsActions.js";
import { getSetupIntentForAccount } from '../../../../Actions/Stripe/StripeActions'
import SubscriptionMonthlyEstimate from './SubscriptionMonthlyEstimate.js';
import { numberFormat, isFormSubmit } from "../../../../Utils/services.js";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import Loader from '../../../Common/Loader.js'
import { formatBillingCurrency } from "../YourBilling.utils.js";
import StripePaymentForm from "../../../Sales/FrontdeskCheckout/StripePaymentForm";
import { SmsUsage } from "./widgets/SmsUsage/SmsUsage.js";
import { EfaxUsage } from "./widgets/EfaxUsage/EfaxUsage.js";
import { withSmsUsageLogReport } from "./widgets/SmsUsage/hooks/useReport.js";
import { withEfaxUsageLogReport } from "./widgets/EfaxUsage/hooks/useReport.js";

const getPercentage = (used, total) => {
  return numberFormat((used * 100 / total), 'decimal', 2);
}

const options = (_, reportData) => {
  return {
    chart: {
      type: "pie",
      margin: [-10, 310, 50, 15],
      height: 400,
      width: 450,
      backgroundColor: 'rgba(255, 255, 255, 0.0)'
    },
    title: {
      text: ''
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.z}</b><br/>'
    }, plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      maxHeight: 160,
      symbolHeight: 14,
      symbolWidth: 14,
      symbolRadius: 3,
      width: 290,
      itemWidth: 290,
      itemMarginBottom: 5,
      x: 0,
      y: 70,
      useHTML: true,
      labelFormatter: function() {
        return '<div className="setting-data-usage">' + this.z + '</div>';
      }
    },
    series: [
      {
        minPointSize: 10,
        innerSize: '50%',
        zMin: 0,
        data: reportData.graph
      }
    ]
  }
};

class ResourceUsage extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      showLoader: true,
      isShowConfirmationModal: false,
      confirmationModalMsg: '',
      confirmationModalHandler: '',
      refill_sms_status: false,
      refill_sms_limit: '250',
      refill_sms_value: '50',
      refill_email_status: false,
      refill_email_limit: '500',
      refill_email_value: '50',
      refill_data_status: false,
      refill_data_limit: '1',
      refill_data_value: '1',
      is_edit_card: false,
      card_number: '',
      card_cvv: '',
      card_month: '',
      card_year: '',
      add_on_sms: '250',
      add_on_email: '500',
      add_on_data: '1',
      isAddMoreSms: false,
      isAddMoreEmail: false,
      isAddMoreData: false,
      subscriptionData: {},
      storageData: {
        limit: 0,
        used: 0,
        used_percentage: 0,
        free: 0,
        free_percentage: 0
      },
      userGraphData: {
        limit: 0,
        used: 0,
        used_percentage: 0,
        free: 0,
        free_percentage: 0
      },
      smsGraphData: {
        limit: 0,
        used: 0,
        used_percentage: 0,
        free: 0,
        free_percentage: 0
      },
      emailGraphData: {
        limit: 0,
        used: 0,
        used_percentage: 0,
        free: 0,
        free_percentage: 0
      },
      cardNumberClass: 'newInputField',
      cardCvvClass: 'newInputField',
      cardMonthClass: 'newSelectField',
      cardYearClass: 'newSelectField',
      userChanged: false,
      message: '',
      isShowUpgradeModal: false,
      subscription_type: 'monthly',
      accountType: 'trial',
      accountStatus: 'cancelled',
      isShowMonthlyEstimateModal: false,
      monthlyToYearlyData: {},
      isAccountInStripe: false,
    };
    window.onscroll = () => {
      return false
    }
  }

  componentDidMount() {
    this.state['showLoader'] = true;
    this.props.exportEmptyData()
    this.props.getSubscriptionDetails();
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) });
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.subscriptionData != undefined && nextProps.subscriptionData !== prevState.subscriptionData) {
      returnState.subscriptionData = nextProps.subscriptionData;
      returnState.isAccountInStripe = (nextProps.subscriptionData.account_code.indexOf('cus_') > -1) ? true : false
      const storageLimit = numberFormat((returnState.subscriptionData.storage_limit / 1000), 'decimal', 2);
      const storageUsed = numberFormat(returnState.subscriptionData.storage_used, 'decimal', 2);
      const storageUsedPercentage = numberFormat(getPercentage(storageUsed, storageLimit), 'decimal', 2);
      const videoStorageUsed = numberFormat(returnState.subscriptionData.video_storage_used, 'decimal', 2);
      const documentStorageUsed = numberFormat(returnState.subscriptionData.document_storage_used, 'decimal', 2);
      const videoStorageUsedPercentage = numberFormat(getPercentage(videoStorageUsed, storageLimit), 'decimal', 2);
      const documentStorageUsedPercentage = numberFormat(getPercentage(documentStorageUsed, storageLimit), 'decimal', 2);
      const storageFree = numberFormat((storageLimit - storageUsed), 'decimal', 2);
      const storageFreePercentage = numberFormat(getPercentage(storageFree, storageLimit), 'decimal', 2);


      const userLimit = parseInt(returnState.subscriptionData.users_limit)
      const userUsed = parseInt(returnState.subscriptionData.users_used);
      const userFree = userLimit - userUsed;


      const smsLimit = parseInt(returnState.subscriptionData.sms_left)
      const smsUsed = parseInt(returnState.subscriptionData.sms_used);
      const smsFree = smsLimit;


      const emailLimit = parseInt(returnState.subscriptionData.email_left)
      const emailUsed = parseInt(returnState.subscriptionData.email_used);
      const emailFree = emailLimit;




      returnState.storageData = {
        limit: storageLimit,
        used: storageUsed,
        used_percentage: storageUsedPercentage,
        free: storageFree,
        free_percentage: storageFreePercentage,
        graph: [

          {
            name: 'Video Storage Used',
            y: parseFloat(videoStorageUsedPercentage),
            color: '#21618C',
            z: 'Video Storage - ' + videoStorageUsed + ' GB / ' + videoStorageUsedPercentage + "%"
          },
          {
            name: 'Document Storage Used',
            y: parseFloat(documentStorageUsedPercentage),
            color: '#3498DB',
            z: 'Document Storage - ' + documentStorageUsed + ' GB / ' + documentStorageUsedPercentage + "%"
          },
          {
            name: 'Free',
            y: parseFloat(storageFreePercentage),
            color: '#C7E3F5',
            z: 'Available Free Storage - ' + storageFree + ' GB / ' + storageFreePercentage + "%"
          }]
      }

      returnState.userGraphData = {
        limit: userLimit,
        used: userUsed,
        free: storageFree,
        graph: [{
          name: 'Total Users',
          y: parseInt(userLimit),
          color: '#0066B4',
          z: userLimit + ' Users in system'
        }, {
          name: 'Free',
          y: userFree,
          color: '#C7E3F5',
          z: userFree + ' unassigned user'
        }]
      }
      returnState.smsGraphData = {
        limit: smsLimit,
        used: smsUsed,
        free: smsFree,
        graph: [{
          name: 'SMS sent',
          y: parseInt(smsUsed),
          color: '#0066B4',
          z: smsUsed + ' SMS sent'
        }, {
          name: 'SMS left',
          y: smsFree,
          color: '#C7E3F5',
          z: smsFree + ' SMS left'
        }]
      }

      returnState.emailGraphData = {
        limit: emailLimit,
        used: emailUsed,
        free: emailFree,
        graph: [{
          name: 'Emails sent',
          y: parseInt(emailUsed),
          color: '#0066B4',
          z: emailUsed + ' Emails sent'
        }, {
          name: 'Emails left',
          y: emailFree,
          color: '#C7E3F5',
          z: emailFree + ' Emails left'
        }]
      }

      returnState.isAddMoreSms = false;
      returnState.isAddMoreEmail = false;
      returnState.isAddMoreData = false;
      returnState.is_edit_card = false;

      returnState.accountType = (returnState.subscriptionData.account_type != undefined && returnState.subscriptionData.account_type != '') ? returnState.subscriptionData.account_type.toLowerCase() : 'trial'
      returnState.accountStatus = (returnState.subscriptionData.account_status != undefined && returnState.subscriptionData.account_status != '') ? returnState.subscriptionData.account_status.toLowerCase() : 'cancelled'

      returnState.refill_sms_status = (returnState.subscriptionData.refill_sms_status) ? true : false;
      returnState.refill_sms_limit = returnState.subscriptionData.refill_sms_limit;
      returnState.refill_sms_value = returnState.subscriptionData.refill_sms_value;

      returnState.refill_email_status = (returnState.subscriptionData.refill_email_status) ? true : false;
      returnState.refill_email_limit = returnState.subscriptionData.refill_email_limit;
      returnState.refill_email_value = returnState.subscriptionData.refill_email_value;

      returnState.refill_data_status = (returnState.subscriptionData.refill_data_status) ? true : false;
      returnState.refill_data_limit = returnState.subscriptionData.refill_data_limit;
      returnState.refill_data_value = returnState.subscriptionData.refill_data_value;
      if (nextProps.isEditCard !== undefined && nextProps.isEditCard === false) {
        returnState.is_edit_card = false;
      }

      if (nextProps.message !== undefined && nextProps.message != null && nextProps.message != '') {
        returnState.message = nextProps.message;
        toast.dismiss();
        toast.success(nextProps.message)
        //return;
      }
      returnState.showLoader = false;
    } if (nextProps.monthlyToYearlyData != undefined && nextProps.monthlyToYearlyData !== prevState.monthlyToYearlyData) {
      returnState.showLoader = false;
      returnState.monthlyToYearlyData = nextProps.monthlyToYearlyData;
    } else if (nextProps.reload !== undefined && nextProps.reload === true) {
      toast.dismiss();
      toast.success(nextProps.message)
      nextProps.getSubscriptionDetails();
    } else if (nextProps.showLoader !== undefined && nextProps.showLoader === false) {
      if (nextProps.message !== undefined && nextProps.message != null && nextProps.message != '' && nextProps.message != prevState.message) {
        returnState.message = nextProps.message;
        toast.dismiss();
        toast.success(nextProps.message, { onClose: () => { return } });
      }
      returnState.showLoader = false;
      if (nextProps.isEditCard !== undefined && nextProps.isEditCard === false) {
        returnState.is_edit_card = false;
      }
      nextProps.exportEmptyData()
    }
    return returnState;
  }


  handleInputChange = event => {
    this.setState({ userChanged: true });
    const target = event.target;
    const name = event.target.name;
    let value = target.value;
    if (target.type === "checkbox") {
      value = target.checked;
      if (!value) {
        if (name == 'refill_sms_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_sms_package_warning,
            confirmationModalHandler: "handleRefillSmsStatus"
          })
          this.toggleConfirmationModal();
          return;
        } else if (name == 'refill_email_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_emails_package_warning,
            confirmationModalHandler: "handleRefillEmailStatus"
          })
          this.toggleConfirmationModal();
          return;
        } else if (name == 'refill_data_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_data_package_warning,
            confirmationModalHandler: "handleRefillDataStatus"
          })
          this.toggleConfirmationModal();
          return;
        }
      } else {
        if (name == 'is_edit_card') {
          this.setState({ is_edit_card: true });
          return;
        }
      }
    }

    this.setState({
      [event.target.name]: value
    });
  };

  handleSubmit = event => {
    if (isFormSubmit()) {
      let formData = {
        "refill_sms_status": (this.state.refill_sms_status) ? 1 : 0,
        "refill_sms_value": this.state.refill_sms_value,
        "refill_sms_limit": this.state.refill_sms_limit,
        "refill_email_status": (this.state.refill_email_status) ? 1 : 0,
        "refill_email_value": this.state.refill_email_value,
        "refill_email_limit": this.state.refill_email_limit,
        "refill_data_status": (this.state.refill_data_status) ? 1 : 0,
        "refill_data_value": this.state.refill_data_value,
        "refill_data_limit": this.state.refill_data_limit
      }
      this.setState({ showLoader: true, message: '' });
      this.props.subscriptionAutoRefill(formData)
    }
  };

  handleSubmitAddOn = (type) => {
    let formData = {
      addon_type: (type == 'add_on_sms') ? 'sms' : (type == 'add_on_email') ? 'email' : 'storage',
      addon_value: this.state[type]
    };
    this.setState({ showLoader: true, message: '' });
    this.props.subscriptionAddOn(formData)
  };


  handleStripeSubmit = (type) => {
    if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
      let formData = {
        setup_intent_id: this.props.stripeIntent.setupIntentId
      }
      if (type == 'is_edit_card') {
        this.setState({ showLoader: true })
        this.props.subscriptionUpdateCard(formData) //TO DO STRIPE SETUP INTENT
      } else if (type == 'isShowUpgradeModal') {
        this.setState({ showLoader: true })
        formData.subscription_type = this.state.subscription_type;
        this.setState({ isShowUpgradeModal: false });
        this.props.subscriptionUpgradeAccount(formData) //TO DO STRIPE SETUP INTENT
      }
    }
  }

  toggleConfirmationModal = (type) => {
    if (type == 'cancel_plan') {
      this.setState({
        confirmationModalMsg: this.state.settingsLang.subscription_cancel_plan_msg,
        confirmationModalHandler: 'subscriptionCancelReactivate',
      })
    } else if (type == 'reactivate_plan') {
      this.setState({
        confirmationModalMsg: this.state.settingsLang.subscription_resume_plan_msg,
        confirmationModalHandler: 'subscriptionCancelReactivate',
      })
    }
    this.setState({ isShowConfirmationModal: !this.state.isShowConfirmationModal })
  }

  toggleUpgradeModal = () => {
    this.props.getSetupIntentForAccount();
    this.setState({ isShowUpgradeModal: !this.state.isShowUpgradeModal });
  }

  toggleMonthlyEstimateModal = () => {
    if (!this.state.isShowMonthlyEstimateModal) {
      this.setState({ showLoader: true })
      this.props.getSubscriptionMonthlyEstimate();
    }
    this.setState({ isShowMonthlyEstimateModal: !this.state.isShowMonthlyEstimateModal });
  }

  handleRefillSmsStatus = () => {
    this.setState({ refill_sms_status: !this.state.refill_sms_status });
    this.toggleConfirmationModal();
  }
  handleRefillEmailStatus = () => {
    this.setState({ refill_email_status: !this.state.refill_email_status });
    this.toggleConfirmationModal();
  }
  handleRefillDataStatus = () => {
    this.setState({ refill_data_status: !this.state.refill_data_status });
    this.toggleConfirmationModal();
  }

  subscriptionCancelReactivate = () => {
    if (this.state.subscriptionData.account_status != undefined)
      this.setState({ showLoader: true, message: '' })
    this.toggleConfirmationModal();
    this.props.subscriptionCancelReactivate({ action_type: (this.state.subscriptionData.account_status == 'Cancelled') ? 'reactivate' : 'cancelled' })
  }

  subscriptionUpgradeToYearly = () => {
    this.setState({ showLoader: true })
    this.props.subscriptionUpgradeToYearly();
    this.toggleMonthlyEstimateModal();
  }

  toggleState = (type) => {
    this.setState({ [type]: !this.state[type], userChanged: true });
  }



  renderObjectOption = (list, key, labelTag, insideBraces) => {
    let htmlList = []
    Object.keys(list).forEach((idx) => {
      htmlList.push(<option key={key + '-' + idx} value={idx} >{idx} {(insideBraces == true) ? labelTag + ' @ $' + numberFormat((list[idx].price), 'decimal', 2) : `(${labelTag})`}</option>);
    })
    return htmlList;
  }

  renderArrayOption = (list, key, labelTag, insideBraces) => {
    let htmlList = []
    list.map((obj, idx) => {
      htmlList.push(<option key={key + '-' + idx} value={obj} >{obj} {(insideBraces == true) ? labelTag : `(${labelTag})`}</option>);
    })
    return htmlList;
  }

  render() {
    const smsMonthlyUsed = this.props.smsUsageLogReport?.stats?.[0]?.data || 0;
    const smsMonthlyFree = this.props.smsUsageLogReport?.stats?.[1]?.data || 0;
    
    const efaxPagesUsed = this.props.efaxUsageLogReport?.stats?.[0]?.data || 0;
    const efaxPagesFree = this.props.efaxUsageLogReport?.stats?.[1]?.data || 0;

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  Resource Usage
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 new-sale-col">
                  <div className="setting-setion m-b-15">
                    <div className="mainSectionTitle resourceGraphTitle">Data Usage
                      {/* <a  className={((this.state.accountType == 'paid')) ? "easy-link pull-right no-padding" : "no-display"} onClick={this.toggleState.bind(this, 'isAddMoreData')}><i  className="fa fa-plus"></i> &nbsp;Add</a> */}
                    </div>
                    <div className="dash-box-content egiftCardGraph businessGraphOuter" data-highcharts-chart="0">
                      <div className="highcharts-container " data-highcharts-chart="0"
                        style={{
                          position: 'relative', overflow: 'hidden', width: '400px',
                          height: '200px', textAlign: 'left', lineHeight: 'normal', zIndex: '0',
                          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
                        }}>
                        <PieChart highcharts={Highcharts} options={options('data', this.state.storageData)} />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 new-sale-col">
                  <div className="setting-setion m-b-15">
                    <div className="mainSectionTitle">Users Limit</div>
                    <div className="dash-box-content egiftCardGraph businessGraphOuter" data-highcharts-chart="0">
                      <div className="highcharts-container " data-highcharts-chart="0"
                        style={{
                          position: 'relative', overflow: 'hidden', width: '400px',
                          height: '200px', textAlign: 'left', lineHeight: 'normal', zIndex: '0',
                          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
                        }}>
                        <PieChart highcharts={Highcharts} options={options('data', this.state.userGraphData)} />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 new-sale-col">
                  <div className="setting-setion m-b-15">
                    <div className="mainSectionTitle resourceGraphTitle">SMS Segments monthly limit</div>
                    <div className="dash-box-content egiftCardGraph businessGraphOuter" data-highcharts-chart="0">
                      <div className="highcharts-container " data-highcharts-chart="0"
                        style={{
                          position: 'relative', overflow: 'hidden', width: '400px',
                          height: '200px', textAlign: 'left', lineHeight: 'normal', zIndex: '0',
                          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
                        }}>
                        <PieChart highcharts={Highcharts} options={options('data', {
                          limit: smsMonthlyFree,
                          used: smsMonthlyUsed,
                          free: smsMonthlyFree,
                          graph: [{
                            name: 'SMS Segments sent',
                            y: smsMonthlyUsed,
                            color: '#0066B4',
                            z: smsMonthlyUsed + ' SMS Segments sent'
                          }, {
                            name: 'SMS Segments left',
                            y: smsMonthlyFree,
                            color: '#C7E3F5',
                            z: smsMonthlyFree + ' SMS Segments left'
                          }]
                        })} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 new-sale-col">
                  <div className="setting-setion m-b-15">
                    <div className="mainSectionTitle resourceGraphTitle">Emails monthly limit {/*<a  className={((this.state.accountType == 'paid')) ? "easy-link pull-right no-padding" : "no-display"} onClick={this.toggleState.bind(this, 'isAddMoreEmail')}><i className="fa fa-plus"></i> &nbsp;Add</a>*/}</div>
                    <div className="dash-box-content egiftCardGraph businessGraphOuter" data-highcharts-chart="0">
                      <div className="highcharts-container " data-highcharts-chart="0"
                        style={{
                          position: 'relative', overflow: 'hidden', width: '400px',
                          height: '200px', textAlign: 'left', lineHeight: 'normal', zIndex: '0',
                          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
                        }}>
                        <PieChart highcharts={Highcharts} options={options('data', this.state.emailGraphData)} />
                      </div>
                    </div>

                  </div>
                  <div className="setting-setion m-b-15">
                    <div className="mainSectionTitle resourceGraphTitle">eFax Page Usage</div>
                    <div className="dash-box-content egiftCardGraph businessGraphOuter" data-highcharts-chart="0">
                      <div className="highcharts-container " data-highcharts-chart="0"
                        style={{
                          position: 'relative', overflow: 'hidden', width: '400px',
                          height: '200px', textAlign: 'left', lineHeight: 'normal', zIndex: '0',
                          WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
                        }}>
                        <PieChart highcharts={Highcharts} options={options('data', {
                          limit: efaxPagesFree,
                          used: efaxPagesUsed,
                          free: efaxPagesFree,
                          graph: [{
                            name: 'Pages used',
                            y: efaxPagesUsed,
                            color: '#0066B4',
                            z: efaxPagesUsed + ' Pages used'
                          }, {
                            name: 'Pages left',
                            y: efaxPagesFree,
                            color: '#C7E3F5',
                            z: efaxPagesFree + ' Pages left'
                          }]
                        })} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SmsUsage />
              <EfaxUsage />
            </div>
          </div>
          {/* Confirmation Modal - START */}
          <div className={(this.state.isShowConfirmationModal) ? 'overlay' : ''} ></div>
          <div id="filterModal" role="dialog" className={(this.state.isShowConfirmationModal) ? 'modal fade in displayBlock' : 'modal fade no-display'}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" onClick={this.toggleConfirmationModal}>×</button>
                  <h4 className="modal-title" id="model_title">{this.state.globalLang.delete_confirmation}</h4>
                </div>
                <div id="errorwindow" className="modal-body add-patient-form filter-patient">{this.state.confirmationModalMsg}</div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.toggleConfirmationModal}>{this.state.globalLang.label_no}</button>
                    <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this[this.state.confirmationModalHandler]}>{this.state.globalLang.label_yes}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Confirmation Modal - END */}
          {/* Upgrade Account Modal - START */}
          <div className={(this.state.isShowUpgradeModal) ? 'modalOverlay' : 'no-display'}>
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.settingsLang.subscription_upgrade_account}</div>
                <button className="small-cross" onClick={this.toggleUpgradeModal}>×</button>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">
                  <div className="row">
                    {(this.state.subscriptionData.subscription_plans != undefined) &&
                      <div className="col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.subscription_type}<span className="setting-require">*</span></div>
                          <select className="newSelectField" name="subscription_type" value={this.state.subscription_type} onChange={this.handleInputChange} >
                            <option value='monthly'>{this.state.subscriptionData.subscription_plans.monthly}</option>
                            <option value="yearly">{this.state.subscriptionData.subscription_plans.yearly}</option>
                          </select>
                        </div>
                      </div>
                    }
                    <StripePaymentForm type={'setupIntent'} onSubmit={this.handleStripeSubmit.bind(this, 'isShowUpgradeModal')} onCancel={this.toggleUpgradeModal} />
                  </div>
                </div>
              </div>
              <div className="footer-static"></div>
            </div>
          </div>
          {/* Upgrade Account Modal - END */}
          {/* Monthly Estimate Modal - START */}
          <div className={(this.state.isShowMonthlyEstimateModal) ? 'modalOverlay' : 'no-display'}>
            <div className="small-popup-outer subscription-popup">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.settingsLang.subscription_switch_to_yearly_subscription}</div>
                <button className="small-cross" onClick={this.toggleMonthlyEstimateModal}>×</button>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">

                  {(this.state.monthlyToYearlyData.monthly_plan_estimated != undefined && this.state.monthlyToYearlyData.yearly_plan_estimated != undefined) &&
                    <div className="monthly-estimate-block">
                      <div className="popup-accordian-section-head">
                        <div className="accordian-section text-left">{this.state.settingsLang.subscription_we_will_charge_your_card_with} {formatBillingCurrency(this.state.monthlyToYearlyData.monthly_plan_estimated.total_amount)} {this.state.settingsLang.subscription_we_will_charge_your_card_with}<br /> From {this.state.monthlyToYearlyData.monthly_plan_estimated.from_date} To {this.state.monthlyToYearlyData.monthly_plan_estimated.to_date}</div>
                      </div>
                      <div className="accordion-table your-billing m-b-20">
                        <SubscriptionMonthlyEstimate estimatedData={this.state.monthlyToYearlyData.monthly_plan_estimated} />
                      </div>

                      <div className="popup-accordian-section-head">
                        <div className="accordian-section text-left">{this.state.settingsLang.subscription_we_will_charge_your_card_with} {formatBillingCurrency(this.state.monthlyToYearlyData.yearly_plan_estimated.total_amount)} {this.state.settingsLang.subscription_we_will_charge_your_card_with}<br /> From {this.state.monthlyToYearlyData.yearly_plan_estimated.from_date} To {this.state.monthlyToYearlyData.yearly_plan_estimated.to_date}</div>
                      </div>
                      <div className="accordion-table your-billing m-b-20">
                        <div className="profile accordion-row">
                          <div className="accordian-section col-xs-6 text-left inner-box">
                            {this.state.settingsLang.subscription_your_yearly_subscription_for} {this.state.monthlyToYearlyData.yearly_plan_estimated.total_users} {this.state.settingsLang.subscription_users}
                          </div>
                          <div className="accordian-section col-xs-6 text-right inner-box">{formatBillingCurrency(this.state.monthlyToYearlyData.yearly_plan_estimated.total_amount)}</div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="footer-static">
                <button className="new-blue-btn pull-right" name='btn_create_schedule' onClick={this.subscriptionUpgradeToYearly}>{this.state.globalLang.label_continue}</button>
                <button className="new-white-btn pull-right" onClick={this.toggleMonthlyEstimateModal}>{this.state.globalLang.label_cancel}</button>
              </div>
            </div>
          </div>
          {/* Monthly Estimate Modal - END */}

          {/* ADD MORE SMS MODAL BEGIN */}

          <div className={(this.state.isAddMoreSms) ? "blackOverlay" : "blackOverlay no-display"}>
            <div className="vert-middle">
              <div className="white-popup large-popup">
                <div className="white-popup-wrapper">
                  <div className="membershipTypeTitle">Buy More SMS<a onClick={this.toggleState.bind(this, 'isAddMoreSms')} className="popupClose"><img src="/images/popupClose.png" /></a>
                  </div>
                  <div className="">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">{this.state.settingsLang.subscription_buy_more_sms}</div>
                        <div className="setting-input-outer">
                          <select className="newSelectField" name="add_on_sms" value={this.state.add_on_sms} onChange={this.handleInputChange} id="addon_sms">
                            {this.state.subscriptionData.sms_packages &&
                              this.renderObjectOption(this.state.subscriptionData.sms_packages, 'add_on_sms', this.state.settingsLang.subscription_sms, true)}
                          </select>
                        </div>
                        <div className="important-instruction">{this.state.settingsLang.subscription_current_sms_limit}: {this.state.settingsLang.subscription_up_to} {this.state.subscriptionData.sms_limit + this.state.subscriptionData.add_on_sms} {this.state.settingsLang.subscription_sms}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-4 no-padding-right">
                      <button type="button" id="addon-sms-btn" className="new-blue-btn sms-btn m-l-0 m-t-15 no-width" onClick={this.handleSubmitAddOn.bind(this, 'add_on_sms')}>{this.state.settingsLang.subscription_buy_sms}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ADD MORE SMS MODAL ENDS */}


          {/* ADD MORE DATA MODAL BEGIN */}

          <div className={(this.state.isAddMoreData) ? "blackOverlay" : "blackOverlay no-display"}>
            <div className="vert-middle">
              <div className="white-popup large-popup">
                <div className="white-popup-wrapper">
                  <div className="membershipTypeTitle">Buy More Data<a onClick={this.toggleState.bind(this, 'isAddMoreData')} className="popupClose"><img src="/images/popupClose.png" /></a>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">{this.state.settingsLang.subscription_buy_more_data}</div>
                      <div className="setting-input-outer">
                        <select className="newSelectField" name="add_on_data" value={this.state.add_on_data} onChange={this.handleInputChange} id="addon_data">
                          {this.state.subscriptionData.storage_data_packages &&
                            this.renderObjectOption(this.state.subscriptionData.storage_data_packages, 'add_on_data', this.state.settingsLang.subscription_gb, true)}
                        </select>
                      </div>
                      <div className="important-instruction" id="add-data-sub">{this.state.settingsLang.subscription_you_will_be_charged}
                        <span className="new_amount"><b> {formatBillingCurrency(this.state.add_on_data)} </b></span>{this.state.settingsLang.subscription_one_time_from_credit_card_you_have_in_file}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 no-padding-right">
                    <button type="button" id="addon-data-btn" className="new-blue-btn sms-btn m-l-0 m-t-15 no-width" onClick={this.handleSubmitAddOn.bind(this, 'add_on_data')}>{this.state.settingsLang.subscription_buy_data}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ADD MORE DATA MODAL ENDS */}

          {/* ADD MORE EMAILS MODAL BEGIN */}

          <div className={(this.state.isAddMoreEmail) ? "blackOverlay" : "blackOverlay no-display"}>
            <div className="vert-middle">
              <div className="white-popup large-popup">
                <div className="white-popup-wrapper">
                  <div className="membershipTypeTitle">Buy More Email<a onClick={this.toggleState.bind(this, 'isAddMoreEmail')} className="popupClose"><img src="/images/popupClose.png" /></a>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">{this.state.settingsLang.subscription_buy_more_emails}</div>
                      <div className="setting-input-outer">
                        <select className="newSelectField" name="add_on_email" value={this.state.add_on_email} onChange={this.handleInputChange} id="addon_email">
                          {this.state.subscriptionData.email_packages &&
                            this.renderObjectOption(this.state.subscriptionData.email_packages, 'add_on_email', this.state.settingsLang.subscription_email, true)}
                        </select>
                      </div>
                      <div className="important-instruction m-t-10">{this.state.settingsLang.subscription_current_email_limit}: {this.state.settingsLang.subscription_up_to} {this.state.subscriptionData.email_limit + this.state.subscriptionData.add_on_email}
                        {this.state.settingsLang.subscription_emails}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 no-padding-right">
                    <button type="button" id="addon-email-btn" className="new-blue-btn sms-btn m-l-0 m-t-10 no-width" onClick={this.handleSubmitAddOn.bind(this, 'add_on_email')}>{this.state.settingsLang.subscription_buy_email}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ADD MORE EMAILS MODAL ENDS */}
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.SettingReducer.action === "SUBSCRIPTION_DETAILS") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.subscriptionData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_ADD_ON") {
    if (state.SettingReducer.data.status != 200) {
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.subscriptionData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_CANCEL_REACTIVATE") {
    if (state.SettingReducer.data.status != 200 && state.SettingReducer.data.status != 201) {
      toast.dismiss();
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      if (state.SettingReducer.data.status == 201) {
        returnState.message = state.SettingReducer.data.message;
      } else {
        returnState.message = languageData.global[state.SettingReducer.data.message];
      }
      returnState.subscriptionData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_UPGRADE_ACCOUNT") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.subscriptionData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_UPDATE_CARD") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      //returnState.showLoader = false;
      returnState.isEditCard = false;
      returnState.subscriptionData = state.SettingReducer.data.data
      returnState.message = languageData.global[state.SettingReducer.data.message];
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_AUTO_REFILL") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      returnState.message = languageData.global[state.SettingReducer.data.message];
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_MONTHLY_TO_YEARLY_ESTIMATE") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.monthlyToYearlyData = state.SettingReducer.data.data;
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_MONTHLY_TO_YEARLY_UPGRADE") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.subscriptionData = state.SettingReducer.data.data;
    }
  }
  returnState.stripeIntent = state.StripeReducer.stripeIntent;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSubscriptionDetails: getSubscriptionDetails,
    subscriptionAddOn: subscriptionAddOn,
    subscriptionCancelReactivate: subscriptionCancelReactivate,
    subscriptionUpgradeAccount: subscriptionUpgradeAccount,
    subscriptionUpdateCard: subscriptionUpdateCard,
    subscriptionAutoRefill: subscriptionAutoRefill,
    getSubscriptionMonthlyEstimate: getSubscriptionMonthlyEstimate,
    subscriptionUpgradeToYearly: subscriptionUpgradeToYearly,
    exportEmptyData: exportEmptyData,
    getSetupIntentForAccount

  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSmsUsageLogReport(
  withEfaxUsageLogReport(ResourceUsage))
);
