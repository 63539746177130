import React, { useState } from "react";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { Box } from "../../../shared/Box/Box";
import { PageHeader } from "../../../boxes/PageHeader/PageHeader";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import classes from "./PatientQuestionnaire.module.scss";
import { usePatientQuestionnaireQuery } from "../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireQuery";
import { getPathParams } from "./PatientQuestionnaire.utils";
import { uiNotification } from "../../../services/UINotificationService";
import { extractQuestionnaireStatus } from "../../../utilities/api";
import { Button } from "../../../shared/Button/Button";
import { Loading } from "../../../boxes/Loading/Loading";
import { PatientQuestionnairePreview } from "../../../widgets/PatientQuestionnairePreview/PatientQuestionnairePreview";
import { ModalPatientQuestionnaireFill } from "../../../widgets/ModalPatientQuestionnaireFill/ModalPatientQuestionnaireFill";
import { useClientQuery } from "../../../api/queries/useClientQuery";
import { history } from "../../../history";
import { CrossIcon } from "../../../assets/Icons/CrossIcon";
import { useClientGlobalName } from "../../../hooks/useClientGlobalName";
import { FillButton } from "../shared/FillButton/FillButton";

export function PatientQuestionnaire() {
  const { tCommon } = useAppTranslation.Common();
  const { patientQuestionnaireId } = getPathParams();
  const [isFillModalOpen, setIsFillModalOpen] = useState(false);
  const clientGlobalName = useClientGlobalName();

  const {
    data: patientQuestionnaire,
    isLoading,
    refetch,
  } = usePatientQuestionnaireQuery({
    payload: {
      patientQuestionnaireId: patientQuestionnaireId,
    },
    options: {
      enabled: Boolean(patientQuestionnaireId),
      onError: () => {
        uiNotification.error(tCommon("error.fetchQuestionnaire"));
      },
    },
  });

  const { data: patient, isLoading: isPatientLoading } = useClientQuery(
    {
      clientId: patientQuestionnaire?.patient_id,
    },
    {
      enabled: Boolean(patientQuestionnaire),
    },
  );

  const status = extractQuestionnaireStatus(patientQuestionnaire);

  const isLoaderVisible = isLoading || isPatientLoading;
  const isContentVisible = !isLoaderVisible;

  const title = (() => {
    if (isLoaderVisible) {
      return `${tCommon("label.loading")}...`;
    }
    return `${clientGlobalName.t({ path: "label.patientQuestionnaire" })} - ${
      patient.fullName
    } - ${patientQuestionnaire?.questionnaire?.title}`;
  })();

  return (
    <LayoutMain>
      <Box className={classes.root}>
        <PageHeader
          title={title}
          rightAdornment={
            status === "notFilled" ? (
              <FillButton
                onClick={() => setIsFillModalOpen(true)}
                status={status}
                isPartiallyFilled={
                  patientQuestionnaire.step_history?.length > 0
                }
              />
            ) : (
              <Button
                variant="text"
                size="tiny"
                onClick={() => history.goBack()}
              >
                <CrossIcon width="17px" />
              </Button>
            )
          }
        />
        {isLoaderVisible && <Loading center />}
        {isContentVisible && (
          <PatientQuestionnairePreview
            noHeader
            preloadedPatientQuestionnaire={patientQuestionnaire}
          />
        )}
      </Box>
      {isFillModalOpen && (
        <ModalPatientQuestionnaireFill
          isOpen
          onClose={() => setIsFillModalOpen(false)}
          preloadedPatientQuestionnaire={patientQuestionnaire}
          onSuccess={() => {
            refetch();
            setIsFillModalOpen(false);
          }}
        />
      )}
    </LayoutMain>
  );
}
