import React, { Component } from "react";
import * as yup from "yup";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import {
  fetchSelectedPosttreatmentInstructions,
  createPosttreatmentInstructions,
  deleteSelectedPosttreatmentInstructions,
  updateSelectedPosttreatmentInstructions,
  exportEmptyData,
} from "../../../../Actions/Settings/settingsActions.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isNumber, isInt } from "../../../../Utils/services.js";
import Loader from "../../../Common/Loader.js";
import { FileParser } from "../../../../widgets/FileParser/FileParser.js";
import { uiNotification } from "../../../../services/UINotificationService.js";
import { FORM_TYPE } from "../consts.js";
import cx from "clsx";
import classes from "../ManageClinics.module.scss";
import { Tabs } from "../../../../shared/Tabs/Tabs.js";
import { MultiPostFromFiles } from "../MultiPostFromFiles/MultiPostFromFiles.js";
import { Button } from "../../../../shared/Button/Button.js";
import { MultiPostFromFilesContent } from "../PreTreatmentInstructions/components/MultiPostFromFilesContent.js";
import { withMultiPostFromFiles } from "../MultiPostFromFiles/withMultiPostFromFiles.js";
import { http } from "../../../../services/HttpService.js";
import { HTTP_ENDPOINTS } from "../../../../consts/api.js";
import { TextEditor } from "../../../../shared/TextEditor/TextEditor.js";
import { ASSIGN_TO_SERVICES_MODEL_TYPE } from "../../../../api/mutations/useAssignServiceToModelMutation.js";
import { SERVICES_BY_CATEGORIES_MODEL_TYPE } from "../../../../api/queries/useServicesGroupedByCategoriesQuery.js";
import { ModalAssignServices } from "../../../../widgets/ModalAssignServices/ModalAssignServices.js";

class CreateEditPostInstructions extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      postData: {},
      userId: userData.user.id,
      id: this.props.match.params.id,
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      scopes: "business_hours",
      user_changed: false,
      hideBtns: false,
      showModal: false,
      settingsLang: languageData.settings,
      title: "",
      description: "",
      scheduled_after: "",
      days_after: "PleaseSelect",
      days_after_enable: "col-lg-3 col-sm-6 col-xs-12 daysValue",
      days_after_disable: "col-lg-3 col-sm-6 col-xs-12 daysValue no-display",
      defaultDays: [1, 2, 3, 4, 5, 6, 7, 14, 21, 30, 60],
      scheduleAfterClass: "newInputField daysValue",
      formType: FORM_TYPE.single,
      editorFiles: [],
      isAssignToServicesModalOpen: false,
      assignedServiceIds: [],
    };
    window.onscroll = () => {
      return false;
    };
  }
  componentDidMount() {
    window.onscroll = () => {
      return false;
    };
    const postId = this.props.match.params.id;
    if (postId) {
      this.setState({ postId: postId });
    }
    if (!localStorage.getItem("languageData")) {
      axios
        .get(process.env.REACT_APP_API_URL + `getLanguageText/1/settings`)
        .then((res) => {
          const languageData = res.data.data;
          localStorage.setItem("languageData", JSON.stringify(languageData));
          this.setState({
            edit_post_instructions:
              languageData.settings["edit_post_instructions"],
            pre_instructions_title:
              languageData.settings["pre_instructions_title"],
            post_instructions_sendAfter:
              languageData.settings["post_instructions_sendAfter"],
            post_create_instructions:
              languageData.settings["post_create_instructions"],
          });
        })
        .catch(function(error) { });
    } else {
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      this.setState({
        edit_post_instructions: languageData.settings["edit_post_instructions"],
        pre_instructions_title: languageData.settings["pre_instructions_title"],
        post_instructions_sendAfter:
          languageData.settings["post_instructions_sendAfter"],
        post_create_instructions:
          languageData.settings["post_create_instructions"],
      });
    }
    let formData = { params: {} };

    if (postId) {
      this.setState({ showLoader: true });
      this.props.fetchSelectedPosttreatmentInstructions(formData, postId);
    } else {
      this.props.exportEmptyData({});
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      return { showLoader: false };
    }
    if (
      props.postData !== undefined &&
      props.postData.status === 200 &&
      props.postData.data.description !== state.description
    ) {
      let returnState = {};
      if (
        !state.userChanged &&
        state.defaultDays.indexOf(parseInt(props.postData.data.days_after)) ==
        -1
      ) {
        returnState.days_after = "custom";
        returnState.scheduled_after = props.postData.data.days_after;
      } else {
        returnState.days_after = state.userChanged
          ? state.days_after
          : props.postData.data.days_after;
      }

      returnState.title = state.userChanged
        ? state.title
        : props.postData.data.title;
      returnState.description = state.userChanged
        ? state.description
        : props.postData.data.description;
      returnState.showLoader = false;
      returnState.assignedServiceIds = props.postData.data.services_ids;
      return returnState;
    }
    if (props.redirect != undefined && props.redirect == true) {
      toast.success(props.message);
      props.history.push("/settings/post-treatment-instructions");
    }
    if (props.showLoader !== undefined && props.showLoader === false) {
      return { showLoader: false };
    }
    return null;
  }

  handleInputChange = (event) => {
    const target = event.target;

    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
      userChanged: true,
    });
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deleteSelectedPosttreatmentInstructions = () => {
    this.setState({ showLoader: true, hideBtns: true });
    this.dismissModal();
    let postId = this.props.match.params.id;
    this.props.deleteSelectedPosttreatmentInstructions(postId);
  };

  handleSubmit = (event) => {
    let error = false;
    let scheduleVal = this.state.days_after;

    this.setState({
      title_Error: false,
      description_Error: false,
      scheduleAfterClass: "newInputField daysValue",
    });

    if (
      typeof this.state.title === "undefined" ||
      this.state.title === null ||
      this.state.title === "" ||
      this.state.title.trim() == ""
    ) {
      this.setState({
        title_Error: true,
      });
      error = true;
    }
    if (
      typeof this.state.description === "undefined" ||
      this.state.description === null ||
      this.state.description === "" ||
      this.state.description.trim() == ""
    ) {
      this.setState({
        description_Error: true,
      });
      error = true;
    }

    if (scheduleVal === "custom") {
      if (
        !isNumber(this.state.scheduled_after) ||
        !isInt(this.state.scheduled_after)
      ) {
        this.setState({
          scheduleAfterClass: "newInputField daysValue field_error",
        });
        error = true;
      }
    }

    if (error === true) {
      return;
    }

    const formData = {
      title: this.state.title,
      description: this.state.description,
      days_after: 1,
    };
    const postId = this.props.match.params.id;

    this.setState({
      title: this.state.title,
      description: this.state.description,
      days_after: 1,
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      postData: [],
      showLoader: true,
    });

    if (postId) {
      this.props.updateSelectedPosttreatmentInstructions(formData, postId);
    } else {
      this.props.createPosttreatmentInstructions(formData);
    }
  };
  handleEditorChange = (e) => {
    this.setState({ description: e, userChanged: true });
  };

  handlePrintSelected = ({ title, body }) => {
    var mywindow = window.open("", title);
    mywindow.document.write("<html><head><title>" + title + "</title>");
    mywindow.document.write(
      "<style>.title {width: 100%; float:left; text-align: center; font-size:25px;margin-bottom:20px!important; } .printClass{margin-top: 50px!important;clear:both;}</style>"
    );
    mywindow.document.write("</head><body>");
    mywindow.document.write('<p><b class="title">' + title + "</b></p>");
    mywindow.document.write('<div class="printClass">' + body + "</div>");
    mywindow.document.write("</body></html>");
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
  };

  handleChangeTab = (formType) => {
    const msg = "Your changes will be lost. Confirm?";
    const confirmMap = {
      [FORM_TYPE.single]: this.state.editorFiles.length > 0,
    };
    if ((confirmMap[formType] && confirm(msg)) || !confirmMap[formType]) {
      this.setState({ formType });
    }
  };

  handleSaveSingleFile = (file, { onRemoveFile }) => {
    this.props.multiPostFromFiles
      .post([file])
      .then(() => {
        uiNotification.success.bind(uiNotification)(
          `Successfully Created '${file.title}' Post Instruction`
        );
        onRemoveFile(file.id);
      })
      .catch((e) => {
        uiNotification.error.bind(uiNotification)(e.message);
      });
  };

  handleSaveMultipleFiles = (files, { onRemoveAll }) => {
    if (
      confirm(
        "Are you sure you want to save all files? Some of them may not parsed correctly or include some mistakes that you don't want to submit without review. Continue anyway?"
      )
    ) {
      this.props.multiPostFromFiles
        .post(files)
        .then(() => {
          uiNotification.success.bind(uiNotification)(
            "Successfully Created Post Instructions From Multiple Files"
          );
          onRemoveAll();
          this.props.history.push("/settings/post-treatment-instructions");
        })
        .catch((e) => {
          uiNotification.error.bind(uiNotification)(e.message);
        });
    }
  };

  render() {
    const isEditMode = Boolean(this.props.match.params.id);
    const { multiPostFromFiles } = this.props;

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className={cx("setting-setion", classes.multiPostFromFilesRoot)}>
            <div className="setting-container">
              <div className="items-center m-b-40 justify-between">
                <div className="items-center">
                  <div className="setting-title-default">
                    {this.state.postId
                      ? this.state.edit_post_instructions
                      : this.state.post_create_instructions}
                  </div>
                  {isEditMode && (
                    <Button
                      size="small"
                      className="m-l-20"
                      variant="outlined"
                      onClick={() => this.setState({ isAssignToServicesModalOpen: true })}
                    >
                      Assign to Services
                    </Button>
                  )}
                </div>
                <Link
                  to="/settings/post-treatment-instructions"
                  className="pull-right cancelAction"
                >
                  <img src="/images/close.png" alt="x" />
                </Link>
              </div>
              {!isEditMode && (
                <Tabs
                  className="f-left m-b-20"
                  active={this.state.formType}
                  options={[
                    {
                      label: "Create",
                      value: FORM_TYPE.single,
                      onClick: () => this.handleChangeTab(FORM_TYPE.single),
                    },
                    {
                      label: "Upload Files",
                      value: FORM_TYPE.multipleFromFiles,
                      onClick: () =>
                        this.handleChangeTab(FORM_TYPE.multipleFromFiles),
                    },
                  ]}
                />
              )}
              {this.state.formType === FORM_TYPE.single ? (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                      <div className="newInputFileldOuter no-ques-margin">
                        <input
                          type="hidden"
                          name="data[PreTreatmentInstruction][id]"
                          defaultValue={2}
                          id="PreTreatmentInstructionId"
                        />{" "}
                        <div className="newInputLabel">
                          {this.state.pre_instructions_title}
                          <span className="setting-require">*</span>
                        </div>
                        <div className="setting-input-outer" id="divToPrint">
                          <input
                            name="title"
                            id="title"
                            onChange={this.handleInputChange}
                            className={
                              this.state.title_Error === true
                                ? "newInputField field_error"
                                : "newInputField"
                            }
                            autoComplete="off"
                            placeholder="Title"
                            maxLength={255}
                            type="text"
                            value={this.state.title}
                          />{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xs-12 textarea-img m-t-40"
                      id="divToPrint"
                    >
                      <div
                        className={cx(
                          classes.singlePostRichEditor,
                          "editor_textarea",
                          {
                            field_error: this.state.description_Error,
                          }
                        )}
                        id="divToPrint"
                      >
                        <TextEditor
                          value={this.state.description || ""}
                          onChange={this.handleEditorChange}
                          className="break-all"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={cx("row", classes.multiPostFromFilesRow)}>
                  <FileParser
                    withEditor
                    selectFileAsync
                    output="html"
                    onError={uiNotification.error.bind(uiNotification)}
                    footerClassName={classes.multiPostFromFilesFooter}
                    renderFooter={({ isFilesSelected, onOpenEditor }) => (
                      <MultiPostFromFiles.Footer
                        isFilesSelected={isFilesSelected}
                        onOpenEditor={onOpenEditor}
                      />
                    )}
                    editor={{
                      isRemoveAllDisabled: multiPostFromFiles.isPosting,
                      isSelectFilesDisabled: multiPostFromFiles.isPosting,
                      isFilesDisabled: multiPostFromFiles.isPosting,
                      onRemoveAllCb:
                        multiPostFromFiles.resetValidationErrorLogs,
                      onRemoveFileCb:
                        multiPostFromFiles.resetValidationErrorLogs,
                      renderFooter: ({
                        currentFile,
                        files,
                        isFilePending,
                        isFilesParsing,
                        onRemoveFile,
                        onRemoveAll,
                      }) => (
                        <MultiPostFromFiles.EditorFooter
                          onPostMultiple={() =>
                            this.handleSaveMultipleFiles(files, {
                              onRemoveAll,
                            })
                          }
                          onPostSingle={() =>
                            this.handleSaveSingleFile(currentFile, {
                              onRemoveFile,
                            })
                          }
                          currentFile={currentFile}
                          files={files}
                          isFilePending={isFilePending}
                          isFilesParsing={isFilesParsing}
                          errorLogs={multiPostFromFiles.validationErrorLogs}
                          isPosting={multiPostFromFiles.isPosting}
                          leftAdornment={
                            currentFile ? (
                              <Button
                                variant="text"
                                size="small"
                                isDisabled={
                                  isFilePending ||
                                  isFilesParsing ||
                                  multiPostFromFiles.isPosting
                                }
                                onClick={() =>
                                  this.handlePrintSelected({
                                    title: currentFile.title || "",
                                    body: currentFile.parsedContent || "",
                                  })
                                }
                              >
                                Print
                              </Button>
                            ) : null
                          }
                        />
                      ),
                      renderFilesMobileFooter: ({
                        files,
                        isFilesParsing,
                        isFilePending,
                        onRemoveAll,
                      }) => (
                        <MultiPostFromFiles.EditorFilesMobileFooter
                          isFilePending={isFilePending}
                          isFilesParsing={isFilesParsing}
                          onPostMultiple={() =>
                            this.handleSaveMultipleFiles(files, {
                              onRemoveAll,
                            })
                          }
                          errorLogs={multiPostFromFiles.validationErrorLogs}
                          isPosting={multiPostFromFiles.isPosting}
                        />
                      ),
                      renderContent: ({
                        currentFile,
                        onEditFile,
                        isFilePending,
                      }) => (
                        <MultiPostFromFilesContent
                          currentFile={currentFile}
                          onEditFile={onEditFile}
                          isFilePending={isFilePending}
                          isPosting={multiPostFromFiles.isPosting}
                        />
                      ),
                      onFilesChange: (editorFiles) => {
                        this.setState({ editorFiles });
                      },
                    }}
                  />
                </div>
              )}
            </div>
            {this.state.formType === FORM_TYPE.single && (
              <div className="footer-static">
                {this.state.postId ? (
                  <input
                    className="new-red-btn pull-left confirm-model"
                    onClick={this.showDeleteModal}
                    data-message="Are you sure you want to delete this Pre Treatment Instruction?"
                    data-confirm-url="/pre_treatment_instructions/delete_pre_treatment_instruction/2"
                    type="submit"
                    value="Delete"
                  />
                ) : (
                  ""
                )}
                <div className={this.state.showModal ? "overlay" : ""}></div>
                <div
                  id="filterModal"
                  role="dialog"
                  className={
                    this.state.showModal
                      ? "modal fade in displayBlock"
                      : "modal fade"
                  }
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          onClick={this.dismissModal}
                        >
                          ×
                        </button>
                        <h4 className="modal-title" id="model_title">
                          {
                            this.state.settingsLang
                              .settings_Confirmation_required
                          }
                          {this.state.showModal}
                        </h4>
                      </div>
                      <div
                        id="errorwindow"
                        className="modal-body add-patient-form filter-patient"
                      >
                        {this.state.settingsLang.settings_r_u_want_to_delete}
                      </div>
                      <div className="modal-footer">
                        <div className="col-md-12 text-left" id="footer-btn">
                          <button
                            type="button"
                            className="btn  logout pull-right"
                            data-dismiss="modal"
                            onClick={this.dismissModal}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="btn btn-success pull-right m-r-10"
                            data-dismiss="modal"
                            onClick={
                              this.deleteSelectedPosttreatmentInstructions
                            }
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  className="new-blue-btn pull-right"
                  id="save_pre"
                  onClick={() =>
                    this.handlePrintSelected({
                      title: this.state.title,
                      body: this.state.description,
                    })
                  }
                  type="submit"
                  value="Print"
                />
                <input
                  className="new-blue-btn pull-right"
                  id="save_pre"
                  onClick={this.handleSubmit}
                  type="submit"
                  value="Save"
                />
                <Link
                  to="/settings/post-treatment-instructions"
                  className="new-white-btn pull-right cancelAction"
                >
                  Cancel
                </Link>
              </div>
            )}
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
        {this.state.isAssignToServicesModalOpen && (
          <ModalAssignServices
            id={this.state.id}
            modelType={ASSIGN_TO_SERVICES_MODEL_TYPE.postTreatmentInstruction}
            servicesType={SERVICES_BY_CATEGORIES_MODEL_TYPE.all}
            pickedIds={this.state.assignedServiceIds}
            isOpen={this.state.isAssignToServicesModalOpen}
            onClose={() => this.setState({ isAssignToServicesModalOpen: false })}
            onSuccess={() => this.props.fetchSelectedPosttreatmentInstructions({}, this.state.id)}
            title="Assign Services to Instruction"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (
    state.SettingReducer.action === "SELECTED_POST_TREATMENT_INSTRUCTIONS_LIST"
  ) {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.postData = state.SettingReducer.data;
    }
  } else if (
    state.SettingReducer.action === "CREATE_POST_TREATMENT_INSTRUCTIONS_LIST"
  ) {
    if (state.SettingReducer.data.status != 201) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  } else if (
    state.SettingReducer.action === "UPDATE_POST_TREATMENT_INSTRUCTIONS_LIST"
  ) {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  } else if (
    state.SettingReducer.action === "DELETE_POST_TREATMENT_INSTRUCTIONS_LIST"
  ) {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  }
  if (state.SettingReducer.action === "EMPTY_DATA") {
    return {};
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSelectedPosttreatmentInstructions: fetchSelectedPosttreatmentInstructions,
      createPosttreatmentInstructions: createPosttreatmentInstructions,
      updateSelectedPosttreatmentInstructions: updateSelectedPosttreatmentInstructions,
      deleteSelectedPosttreatmentInstructions: deleteSelectedPosttreatmentInstructions,
      exportEmptyData: exportEmptyData,
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withMultiPostFromFiles(CreateEditPostInstructions, {
      submitFile: (file) =>
        http.post(HTTP_ENDPOINTS.postPostTreatmentInstructions(), {
          title: file.title,
          description: file.text,
          days_after: 1,
        }),
      composeFileForSubmit: (file) => ({
        title: file.title || "",
        text: file.parsedContent || "",
      }),
      submitFileValidationSchema: yup.object({
        title: yup.string().required("Title is required"),
        text: yup.string().required("Body is required"),
      }),
    })
  )
);
