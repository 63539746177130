import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import cx from "clsx";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import ProviderDashboardContainer from "./components/ProviderDashboardContainer";
import { getWidgetCalendarTodayDate } from "../Dashboard.utils";
import ProviderWidgetCard from "./components/ProviderWidgetCard";
import { Box } from "../../../shared/Box/Box";
import styles from "./scss/ProviderDashboard.module.scss";
import { WidgetDateRange } from "../components/WidgetDateRange";
import { useProviderDashboard } from "./hooks/useProviderDashboard";
import GridSkeleton from "./components/GridSkeleton";

const ProviderDashboard = ({ match }) => {
  const [dates, setDates] = useState({
    start: getWidgetCalendarTodayDate(),
    end: getWidgetCalendarTodayDate(),
  });

  const { widgetsList, clinicList, isLoading } = useProviderDashboard();

  return (
    <LayoutMain className={styles.root}>
      <Box className={styles.providerTitleBox}>
        <h3>Dashboard</h3>
        <div className={styles.dashboardTabs}>
          <Link
            to="/dashboard"
            className={cx(!match.path.includes("/provider") && styles.active)}
          >
            Practice
          </Link>
          <Link
            to="/dashboard/provider"
            className={cx(match.path.includes("/provider") && styles.active)}
          >
            Provider
          </Link>
        </div>
        <WidgetDateRange
          className={styles.dateRange}
          ranges={{
            startDate: dates.start,
            endDate: dates.end,
          }}
          onChange={(result) =>
            setDates({ start: result.startDate, end: result.endDate })
          }
          dates={dates}
        />
      </Box>
      <ProviderDashboardContainer>
        {widgetsList?.map((widget, index) => {
          if (widget.active) {
            return (
              <ProviderWidgetCard
                key={index}
                title={widget.label}
                filters={{
                  singleDate: widget.single_date_picker,
                  clinics: widget.clinics,
                }}
                globalDate={dates}
                url={widget.url}
                clinicList={clinicList}
              />
            );
          }
        })}
      </ProviderDashboardContainer>
      {isLoading && <GridSkeleton />}
    </LayoutMain>
  );
};

export default withRouter(ProviderDashboard);
