import React from "react";

export function Checkbox({ checked, onChange, label }) {
  return (
    <div className="repeat-price new-repeat-price">
      <input
        className="pull-left sel-all-visible"
        type="checkbox"
        autoComplete="off"
        id={label}
        onChange={onChange}
        checked={checked ? "checked" : false}
      />
      <label className="search-text" htmlFor={label}>
        {" "}
        {label}
      </label>
    </div>
  );
}
