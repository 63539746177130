import { Maybe, identity } from "../../../utilities/fp";
import { ACTION_TYPES, DEFAULT_MEMBERSHIP } from "./config";
import { getRawsForMembership, preparePrices } from "./utilities";

const initialState = {
  initialized: false,
  forAllClinics: false,
  forAllMemberships: true,
  membership: DEFAULT_MEMBERSHIP,
  memberships: [],
  clinics: [],
  prices: {
    original: {
      perUnit: 0,
      member: 0,
      perClinic: {},
    },
    changed: {
      perUnit: 0,
      member: 0,
      perClinic: {},
    },
  },
};

function setForAllClinics(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    forAllClinics: status || false,
  };
}

function setPrices(state, action) {
  const { prices, raw } = action.payload;

  const isForAllMemberships = Object.values(prices).every(
    (x) => x.membershipId === DEFAULT_MEMBERSHIP.value,
  );

  const isForAllClinics = (() => {
    const perUnit = new Set(Object.values(prices).map((x) => x.perUnit));
    const member = new Set(Object.values(prices).map((x) => x.member));

    return perUnit.size === 1 && member.size === 1;
  })();

  const perUnit = isForAllClinics
    ? Maybe.of(Object.values(prices || {})[0]?.perUnit)
        .map(identity)
        .orElse("")
        .value()
    : "";

  const member = isForAllClinics
    ? Maybe.of(Object.values(prices || {})[0]?.member)
        .map(identity)
        .orElse("")
        .value()
    : "";

  return {
    ...state,

    forAllMemberships: isForAllMemberships,

    forAllClinics: isForAllClinics,

    membership: isForAllMemberships
      ? DEFAULT_MEMBERSHIP
      : state.memberships?.find(
          (x) => x.value === Object.values(prices)?.[0]?.membershipId,
        ) || state.membership,

    prices: {
      raw,
      original: {
        perUnit,
        member,
        perClinic: {
          ...prices,
        },
      },
      changed: {
        perUnit,
        member,
        perClinic: {
          ...prices,
        },
      },
    },
  };
}

function setForAllMemberships(state, action) {
  const { status } = action.payload;

  let nextPrices = {};

  if (status) {
    const rawsWithMembership = getRawsForMembership({
      raws: state.prices.raw,
      membershipId: DEFAULT_MEMBERSHIP.value,
    });

    nextPrices = preparePrices({
      clinics: state.clinics,
      pricePerClinic: rawsWithMembership,
    });
  } else {
    const rawsWithMembership = getRawsForMembership({
      raws: state.prices.raw,
      membershipId: state.memberships?.[1]?.value,
    });

    const rawWithDefaultMembership = getRawsForMembership({
      raws: state.prices.raw,
      membershipId: DEFAULT_MEMBERSHIP.value,
    });

    nextPrices = preparePrices({
      clinics: state.clinics,
      pricePerClinic: rawsWithMembership,
      fallback: {
        membershipId: state.memberships?.[1]?.value,
        pricePerClinic: rawWithDefaultMembership,
      },
    });
  }

  const stateWithPrices = setPrices(state, {
    payload: {
      prices: nextPrices,
      raw: state.prices.raw,
    },
  });

  return {
    ...state,
    ...stateWithPrices,
    forAllMemberships: status || false,
    membership: status
      ? DEFAULT_MEMBERSHIP
      : state.memberships?.[1] || state.membership,
  };
}

function selectMembership(state, action) {
  const { membership } = action.payload;
  const isDefault = membership.value === DEFAULT_MEMBERSHIP.value;

  const rawWithMembership = getRawsForMembership({
    raws: state.prices.raw,
    membershipId: membership.value,
  });

  const fallback = !isDefault
    ? {
        membershipId: membership.value,
        pricePerClinic: getRawsForMembership({
          raws: state.prices.raw,
          membershipId: DEFAULT_MEMBERSHIP.value,
        }),
      }
    : {};

  const nextPrices = preparePrices({
    clinics: state.clinics,
    pricePerClinic: rawWithMembership,
    fallback,
  });

  const stateWithPrices = setPrices(state, {
    payload: {
      prices: nextPrices,
      raw: state.prices.raw,
    },
  });

  return {
    ...state,
    ...stateWithPrices,
    membership,
    forAllMemberships:
      String(membership.value) === String(DEFAULT_MEMBERSHIP.value),
  };
}

function setMemberships(state, action) {
  const { memberships } = action.payload;

  return {
    ...state,
    memberships,
  };
}

function changePrice(state, action) {
  const { type, value, clinicId } = action.payload;
  const nextChanged = state.prices.changed || {};

  if (clinicId) {
    nextChanged.perClinic[clinicId] = {
      ...nextChanged.perClinic[clinicId],
      [type]: value,
    };
  } else {
    nextChanged[type] = value;
  }

  return {
    ...state,
    prices: {
      ...state.prices,
      changed: nextChanged,
    },
  };
}

function setInitialized(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    initialized: status || false,
  };
}

function setClinics(state, action) {
  const { clinics } = action.payload;

  return {
    ...state,
    clinics,
  };
}

function revertChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      changed: {
        ...state.prices.original,
      },
    },
  };
}

function setOriginalToChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      original: {
        ...state.prices.changed,
      },
    },
  };
}

export function reducer(state = initialState, action) {
  const actionByType = {
    [ACTION_TYPES.setForAllClinics]: setForAllClinics,
    [ACTION_TYPES.setForAllMemberships]: setForAllMemberships,
    [ACTION_TYPES.selectMembership]: selectMembership,
    [ACTION_TYPES.setMemberships]: setMemberships,
    [ACTION_TYPES.setPrices]: setPrices,
    [ACTION_TYPES.changePrice]: changePrice,
    [ACTION_TYPES.setInitialized]: setInitialized,
    [ACTION_TYPES.setClinics]: setClinics,
    [ACTION_TYPES.revertChangedPrices]: revertChangedPrices,
    [ACTION_TYPES.setOriginalToChangedPrices]: setOriginalToChangedPrices,
  };

  return actionByType?.[action.type]?.(state, action) || state;
}
