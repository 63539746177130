import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  wpuId: yup.number().required(),
  assignToProviderId: yup.number(),
});

export function useDeleteInviteMutation(options = {}) {
  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, { strict: true });
      return http.post(HTTP_ENDPOINTS.deleteInvite(), {
        wpu_id: +req.wpuId,
        assign_to_provider_id: req.assignToProviderId,
      });
    },
    ...options,
  });
}
