import React from "react";
import PropTypes from "prop-types";

export const ClipboardListIcon = ({
  width = "1em",
  height = "1em",
  title = "Clipboard List Icon",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 384 512"
    aria-hidden="true"
    aria-labelledby={`clipboard-list-title-${title
      .replace(/\s+/g, "-")
      .toLowerCase()}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title
      id={`clipboard-list-title-${title.replace(/\s+/g, "-").toLowerCase()}`}
    >
      {title}
    </title>
    <path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 24c22.1 0 40 17.9 40 40h-80c0-22.1 17.9-40 40-40zm128 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V128h256v296zm-248-88c0-13.3 10.7-24 24-24h152c13.3 0 24 10.7 24 24s-10.7 24-24 24H96c-13.3 0-24-10.7-24-24zm0-96c0-13.3 10.7-24 24-24h152c13.3 0 24 10.7 24 24s-10.7 24-24 24H96c-13.3 0-24-10.7-24-24zm24-72c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c13.3 0 24 10.7 24 24s-10.7 24-24 24h-72z" />
  </svg>
);

ClipboardListIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  fill: PropTypes.string,
};
