import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../hooks/useChargeForm";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ViewChargeCheck = ({ isTipsApplying }) => {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, noTipAmount } = useInvoice();
  const { chargeCheque } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: noTipAmount,
      currency: invoice?.currency,
    }),
    checkNumber: yup
      .string()
      .required(tSales("checkoutInvoice.formError.checkNumberRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount, checkNumber }) => {
        chargeCheque.initiate({ amount, chequeNumber: checkNumber });
      },
      initialValues: {
        amount: noTipAmount || "",
        checkNumber: "",
      },
    });

  return !showTipsModal.value ? (
    <PaymentFormModal
      isLoading={chargeCheque.isLoading}
      isValid={isValid}
      isTipsApplying={isTipsApplying}
      submit={submit}
      form={form}
      errors={errors}
      hasError={hasError}
      changeFormValue={changeFormValue}
      showTipsModal={showTipsModal}
    />
  ) : (
    <PaymentTipsModal
      isTipsApplying={isTipsApplying}
      form={form}
      showTipsModal={showTipsModal}
    />
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCheck);
