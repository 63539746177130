export const REDUCER_NAME = "event";

export const EVENT_TYPES = {
  appointment: "appointment",
  outOfOffice: "outofoffice",
  breakTime: "break_time",
};

export const ACTION_TYPES = {
  eventTypeChange: `${REDUCER_NAME}/eventTypeChange`,
  isSubmitActiveChange: `${REDUCER_NAME}/isSubmitActiveChange`,
};
