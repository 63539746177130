import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  userId: yup.number().required(),
});

const responseSchema = yup.object({
  upcomingAppointmentCount: yup.number().nullable(),
  providers: yup.array().of(
    yup.object({
      id: yup.number().required(),
      firstname: yup.string().nullable(),
      lastname: yup.string().nullable(),
      full_name: yup.string().nullable(),
    }),
  ),
});

const composeResponse = (res) => {
  const data = res.data?.data;

  return {
    upcomingAppointmentCount: data?.upcoming_appointment_count || null,
    providers: data?.providers || [],
  };
};

export function useCheckProviderFutureAppointmentsMutation(options = {}) {
  return useMutation({
    mutationFn: async (dto) => {
      const req = requestSchema.validateSync(dto, { strict: true });
      const res = await http.get(
        HTTP_ENDPOINTS.checkProviderFutureAppointments(req.userId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    ...options,
  });
}
