/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import Select from "react-select";
import { ORDER_TYPES, REPORT_TYPES } from "../../consts/api.js";
import { passOr } from "../../utilities/general.js";
import { MULTISELECT_ALL_OPTION } from "./BusinessInsights.consts.js";
import {
  insightDateArray,
  monthOfArray,
  getCurrencySymbol,
  fromToDateRange,
  revenuePerHourDateArray,
} from "../../Utils/services.js";

const ApiDateFormat = "YYYY-MM-DD";
const CurrentMonth = parseInt(moment().format("M"));

const colourStyles = {
  menu: (styles) => {
    return {
      ...styles,
      top: 30,
      zIndex: 2,
    };
  },
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      border: isFocused
        ? "1px solid #cad2d6 !important"
        : "1px solid #cad2d6 !important",
      borderRadius: "3px",
      boxShadow: "none",
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#047ec3" : isSelected ? "#047ec3" : "#fff",
      color: isFocused ? "#fff" : isSelected ? "#fff" : "#404040",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: isFocused
          ? "#047ec3"
          : isSelected
          ? "#047ec3"
          : "#fff",
        color: isFocused ? "#fff" : isSelected ? "#fff" : "#404040",
      },
      borderBottom: "1px solid #dedede",
    };
  },
};

export default class ReportsFilters extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const dateFormat = localStorage.getItem("dateFormat");

    this.state = {
      globalLang: languageData.global,
      reportName: props.reportName ? props.reportName : "",
      reportVariableData: props.reportVariableData
        ? props.reportVariableData
        : [],
      dateArray: insightDateArray(),
      revenuePerHourDateArray: revenuePerHourDateArray(),
      reportSection: props.reportSection ? props.reportSection : "",
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      },
      startDate: new Date(),
      endDate: new Date(),
      from_date: new Date(),
      to_date: new Date(),
      showCalendar: false,
      dateFormat: dateFormat,
      monthOfArray: monthOfArray(),
    };
  }

  get hasMaxDate() {
    if (
      this.props.reportType !== REPORT_TYPES.bookedServicesReport &&
      this.props.reportVariableData[0]?.sys_name !== "documents-expiration"
    ) {
      return new Date();
    }
    return undefined;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let name = event.target.name;
    let reportFilter = this.props.parentState.reportFilter;
    if (name === "month") {
      if (parseInt(value) > CurrentMonth) {
        return false;
      }
    }
    reportFilter[name] = value;
    if (name === "date") {
      if (value !== "custom") {
        let fromToDate = fromToDateRange(value);
        reportFilter.from_date = fromToDate.from_date;
        reportFilter.to_date = fromToDate.to_date;
      } else {
        reportFilter.from_date = moment().format(ApiDateFormat);
        reportFilter.to_date = moment().format(ApiDateFormat);
        this.setState({ startDate: new Date(), endDate: new Date() });
      }
    }
    this.props.handleChildState(reportFilter);
  };

  handleSelect2Change = (option, mode) => {
    if (mode) {
      if (mode.action == "select-option") {
        let value = option.value;
        let name = mode.name;
        let reportFilter = this.props.parentState.reportFilter;
        if (name === "year") {
          const isSecondOptionIsMonth = Boolean(
            this.props.reportVariableData?.find((o) => o.variable === "month"),
          );
          if (
            isSecondOptionIsMonth &&
            parseInt(this.props?.parentState?.month) > CurrentMonth &&
            parseInt(value) === new Date().getFullYear()
          ) {
            reportFilter.month = String(new Date().getMonth() + 1);
          }
        }
        if (name === "month") {
          const isSecondOptionIsYear = Boolean(
            this.props.reportVariableData?.find((o) => o.variable === "year"),
          );
          const isSecondOptionIsCurrentYear =
            Number(
              this.props?.parentState?.year || new Date().getFullYear(),
            ) === new Date().getFullYear();
          if (
            parseInt(value) > CurrentMonth &&
            isSecondOptionIsYear &&
            isSecondOptionIsCurrentYear
          ) {
            return false;
          }
        }
        reportFilter[name] = value;
        if (name === "date") {
          if (value !== "custom") {
            let fromToDate = fromToDateRange(value);
            reportFilter.from_date = fromToDate.from_date;
            reportFilter.to_date = fromToDate.to_date;
          } else {
            reportFilter.from_date = moment().format(ApiDateFormat);
            reportFilter.to_date = moment().format(ApiDateFormat);
            this.setState({ startDate: new Date(), endDate: new Date() });
          }
        }

        if (name === "revenue_per_hour_interval") {
          if (value !== "custom") {
            let fromToDate = fromToDateRange(value);
            reportFilter.from_date = fromToDate.from_date;
            reportFilter.to_date = fromToDate.to_date;
          } else {
            reportFilter.from_date = moment()
              .startOf("month")
              .format(ApiDateFormat);
            reportFilter.to_date = moment()
              .endOf("month")
              .format(ApiDateFormat);
            this.setState({ startDate: new Date(), endDate: new Date() });
          }
        }

        this.props.handleChildState(reportFilter);
      }
    }
  };

  updateParentFilter = (field, value) => {
    this.props.handleChildState({
      ...this.props.parentState,
      reportFilter: {
        ...this.props.parentState.reportFilter,
        [field]: value,
      },
    });
  };

  handleProvidersChange = (options) => {
    const isSelectAllPredicate = (o) =>
      o.value === MULTISELECT_ALL_OPTION.value;
    this.updateParentFilter(
      "providers",
      passOr(Boolean(options?.find(isSelectAllPredicate)), options, () => [
        MULTISELECT_ALL_OPTION,
      ]),
    );
  };

  select2Options = (list, value, label) => {
    let selectOptions = [];
    if (list && list.length > 0) {
      selectOptions = list.map((obj) => {
        return {
          value: obj[value ? value : "id"],
          label: obj[label ? label : "name"],
        };
      });
    }
    return selectOptions;
  };

  select2Value = (selectOptions, value) => {
    let selectValue = selectOptions.find((x) => x.value == value);
    selectValue = selectValue ? selectValue : {};
    return selectValue;
  };

  getHTMLBySlug = (object, loopCount) => {
    let html = "";

    if (object) {
      let variableType = "";

      if (object.variable.startsWith("service_")) {
        variableType = "service";
      } else if (object.variable.startsWith("product_")) {
        variableType = "product";
      } else {
        variableType = object.variable;
      }

      let selectOptions = [];
      let selectValues = {};

      switch (variableType) {
        case "service":
          selectOptions = this.select2Options(object.variable_data);

          if (object.sys_name === "nps-score-per-service") {
            selectOptions.unshift({ value: "", label: "Choose Service" });
          }

          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <div
              key={`${object.variable}_${loopCount}`}
              className="col-sm-6 col-xs-12"
            >
              <div className="simpleField m-b-15">
                <div className="simpleLabel">{object.name}</div>
                <Select
                  onChange={this.handleSelect2Change}
                  value={selectValues}
                  options={selectOptions}
                  name={object.variable}
                  styles={colourStyles}
                  isSearchable
                  isMulti={false}
                />
              </div>
            </div>
          );
          break;

        case "date":
          if (object.operator === "range") {
            let selectionRange = {
              startDate: this.props.parentState.resetEditMode
                ? this.props.parentState.startDate
                : this.state.startDate,
              endDate: this.props.parentState.resetEditMode
                ? this.props.parentState.endDate
                : this.state.endDate,
              key: "selection",
            };

            selectOptions = this.state.dateArray;

            selectValues = this.select2Value(
              selectOptions,
              this.props.parentState.reportFilter[object.variable],
            );

            html = (
              <React.Fragment key={`date_` + loopCount}>
                <div className="col-sm-6 col-xs-12">
                  <div className="simpleField m-b-15">
                    <div className="simpleLabel">{object.name}</div>
                    <Select
                      onChange={this.handleSelect2Change}
                      value={selectValues}
                      options={selectOptions}
                      name={object.variable}
                      styles={colourStyles}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
                <div
                  className={
                    this.props.parentState.reportFilter.date === "custom"
                      ? "col-sm-6 col-xs-12 calender calender-hide-bottom"
                      : "col-sm-6 col-xs-12 calender  calender-hide-bottom no-display"
                  }
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <div className="simpleField field-icon m-b-15">
                    <div className="simpleLabel">Choose Custom Period</div>
                    {this.state.showCalendar && (
                      <DateRangePicker
                        className={"CalendarPreviewArea"}
                        ranges={[selectionRange]}
                        onChange={this.dateRangeOnChange}
                        maxDate={this.hasMaxDate}
                        dragSelectionEnabled={false}
                        staticRanges={[]}
                      />
                    )}
                    <input
                      onChange={this.handleInputChange}
                      type="text"
                      className="input-cal simpleInput height38px"
                      name="calendar-input"
                      value={
                        moment(selectionRange.startDate).format(
                          this.state.dateFormat,
                        ) +
                        `-` +
                        moment(selectionRange.endDate).format(
                          this.state.dateFormat,
                        )
                      }
                    />
                    <a name="calendar-input" className="simple-cal-icon" />
                  </div>
                </div>
              </React.Fragment>
            );
          }

          break;
        case "revenue_per_hour_interval":
          if (object.operator === "range") {
            let selectionRange = {
              startDate: this.props.parentState.resetEditMode
                ? this.props.parentState.startDate
                : this.state.startDate,
              endDate: this.props.parentState.resetEditMode
                ? this.props.parentState.endDate
                : this.state.endDate,
              key: "selection",
            };

            selectOptions = this.state.revenuePerHourDateArray;

            selectValues = this.select2Value(
              selectOptions,
              this.props.parentState.reportFilter[object.variable],
            );

            html = (
              <React.Fragment key={`date_` + loopCount}>
                <div className="col-sm-6 col-xs-12">
                  <div className="simpleField m-b-15">
                    <div className="simpleLabel">{object.name}</div>
                    <Select
                      onChange={this.handleSelect2Change}
                      value={selectValues}
                      options={selectOptions}
                      name={object.variable}
                      styles={colourStyles}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
                <div
                  className={
                    this.props.parentState.reportFilter
                      .revenue_per_hour_interval === "custom"
                      ? "col-sm-6 col-xs-12 calender calender-hide-bottom"
                      : "col-sm-6 col-xs-12 calender  calender-hide-bottom no-display"
                  }
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <div className="simpleField field-icon m-b-15">
                    <div className="simpleLabel">Choose Custom Period</div>
                    {this.state.showCalendar && (
                      <DateRangePicker
                        className={"CalendarPreviewArea"}
                        ranges={[selectionRange]}
                        onChange={this.dateRangeOnChange}
                        maxDate={this.hasMaxDate}
                        dragSelectionEnabled={false}
                        staticRanges={[]}
                      />
                    )}
                    <input
                      onChange={this.handleInputChange}
                      type="text"
                      className="input-cal simpleInput height38px"
                      name="calendar-input"
                      value={
                        moment(selectionRange.startDate).format(
                          this.state.dateFormat,
                        ) +
                        `-` +
                        moment(selectionRange.endDate).format(
                          this.state.dateFormat,
                        )
                      }
                    />
                    <a name="calendar-input" className="simple-cal-icon" />
                  </div>
                </div>
              </React.Fragment>
            );
          }

          break;
        case "month_of_birth":
          selectOptions = this.select2Options(
            this.state.monthOfArray,
            "value",
            "label",
          );
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );

          html = (
            <React.Fragment key={`mob_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "spent_money":
          html = (
            <React.Fragment key={`sm_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField  m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <div
                    className={
                      this.props.parentState.reportFilterClass.spent_money
                    }
                  >
                    <input
                      className=""
                      name="spent_money"
                      onChange={this.handleInputChange}
                      value={this.props.parentState.reportFilter.spent_money}
                      maxLength={15}
                      placeholder={"Enter value"}
                    />
                    <span className="TP-discount-type pull-right">
                      {getCurrencySymbol()}
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "discount_type":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          selectOptions.unshift({ id: "0", name: "All type" });
          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`dt_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "provider":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          if (
            this.props.type &&
            (this.props.type === "clients" ||
              this.props.type === "appointments")
          ) {
            selectOptions.unshift({ id: "0", name: "All providers" });
          }
          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`pro_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "providers": {
          const value = this.props.parentState.reportFilter.providers;

          const options = [
            MULTISELECT_ALL_OPTION,
            ...(object?.variable_data?.map((provider) => ({
              label: provider?.name || "",
              value: provider?.id,
            })) || []),
          ];

          const preparedOptions = value?.find(
            (i) => i.value === MULTISELECT_ALL_OPTION.value,
          )
            ? []
            : options;

          html = (
            <React.Fragment key={`pro_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">Providers</div>
                  <Select
                    isMulti
                    isSearchable
                    closeMenuOnSelect={false}
                    onChange={this.handleProvidersChange}
                    value={value}
                    options={preparedOptions}
                    name="providers"
                    styles={colourStyles}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        }

        case "patient_activity_columns": {
          const value = this.props.parentState.reportFilter.sortBy;

          const options = object?.variable_data?.map((provider) => ({
            label: provider?.name || "",
            value: provider?.id,
          }));

          html = (
            <React.Fragment key={`pro_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">Sort By</div>
                  <Select
                    onChange={(option) =>
                      this.updateParentFilter("sortBy", option.value)
                    }
                    value={options.find((o) => o.value === value)}
                    options={options}
                    name="patient_activity_columns"
                    styles={colourStyles}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        }

        case "sort_order": {
          const value = this.props.parentState.reportFilter.sortOrder;

          const options = [
            {
              value: ORDER_TYPES.asc,
              label: "ASC",
            },
            {
              value: ORDER_TYPES.desc,
              label: "DESC",
            },
          ];

          html = (
            <React.Fragment key={`pro_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">Sort Order</div>
                  <Select
                    onChange={(option) =>
                      this.updateParentFilter("sortOrder", option.value)
                    }
                    value={options.find((o) => o.value === value)}
                    options={options}
                    name="sort_order"
                    styles={colourStyles}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        }

        case "cashier":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          selectOptions.unshift({ id: "0", name: "All cashier" });
          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`cash_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "product": {
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];

          const sysNames = [
            "x-done-in-x-time",
            "not-visited-in-x-days",
            "not-visited-in-x-days-for-x-product",
            "had-x-and-y",
            "had-x-and-not-y",
            "not-had-x",
          ];

          if (
            this.props.type &&
            this.props.type === "clients" &&
            this.props.parentState &&
            this.props.parentState.report_type_sysname &&
            !sysNames.includes(this.props.parentState.report_type_sysname)
          ) {
            selectOptions.unshift({ id: "0", name: "All products" });
          }

          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`product_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        }

        case "cancellation_fee_charge":
          html = (
            <React.Fragment key={`cfc_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="basic-checkbox-outer m-t-15">
                  <input
                    checked={
                      this.props.parentState.reportFilter
                        .cancellation_fee_charge
                        ? "checked"
                        : false
                    }
                    id={object.variable}
                    className="basic-form-checkbox new-check"
                    name="cancellation_fee_charge"
                    type="checkbox"
                    onChange={this.handleInputChange}
                  />
                  <label className="basic-form-text" htmlFor={object.variable}>
                    {object.name}
                  </label>
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "lot_number":
          html = (
            <React.Fragment key={`lnum_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <input
                    className={
                      this.props.parentState.reportFilterClass.lot_number
                    }
                    name="lot_number"
                    onChange={this.handleInputChange}
                    value={this.props.parentState.reportFilter.lot_number}
                    placeholder={"Enter lot number"}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "month": {
          selectOptions = this.state.monthOfArray;
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );

          const getIsOptionDisabled = (option) => {
            const isSecondOptionIsYear = Boolean(
              this.props.reportVariableData?.find((o) => o.variable === "year"),
            );

            const isSecondOptionIsCurrentYear =
              Number(
                this.props?.parentState?.year || new Date().getFullYear(),
              ) === new Date().getFullYear();

            return (
              parseInt(option.value) > CurrentMonth &&
              isSecondOptionIsYear &&
              isSecondOptionIsCurrentYear
            );
          };

          const options = selectOptions.map((o) => ({
            ...o,
            isDisabled: getIsOptionDisabled(o),
          }));

          html = (
            <React.Fragment key={`date_month_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={options}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                    isOptionDisabled={getIsOptionDisabled}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        }

        case "month_year":
          selectOptions = [];
          Object.keys(object.variable_data).forEach((idx) => {
            selectOptions.push({
              value: idx,
              label: object.variable_data[idx],
            });
          });
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`date_month_year_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "year":
          selectOptions = [];
          for (
            let counter = 2017;
            counter <= moment().format("YYYY");
            counter++
          ) {
            selectOptions.push({ value: counter, label: counter });
          }
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`date_year_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "rate":
          selectOptions = [{ value: 0, label: "Choose Rating" }];

          for (let counter = 1; counter <= 5; counter++) {
            selectOptions.push({ value: counter, label: counter });
          }
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );

          html = (
            <div
              key={`${object.variable}_${loopCount}`}
              className="col-sm-6 col-xs-12"
            >
              <div className="simpleField m-b-15">
                <div className="simpleLabel">{object.name}</div>
                <Select
                  onChange={this.handleSelect2Change}
                  value={selectValues}
                  options={selectOptions}
                  name={object.variable}
                  styles={colourStyles}
                  isSearchable
                  isMulti={false}
                />
              </div>
            </div>
          );
          break;

        case "clinic":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          if (object.sys_name !== "inventory-forecasting-percentage-booked") {
            selectOptions.unshift({ id: "0", name: "All clinics" });
          }
          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`clinic_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "document_type":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          selectOptions.unshift({ id: "0", name: "All types" });
          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`clinic_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "lead_source":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          if (object.sys_name !== "inventory-forecasting-percentage-booked") {
            selectOptions.unshift({ id: "0", name: "All sources" });
          }
          selectOptions = this.select2Options(selectOptions);
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`source_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;

        case "show_report_by":
          selectOptions = JSON.parse(JSON.stringify(object.variable_data));
          selectOptions = selectOptions ? selectOptions : [];
          selectOptions = this.select2Options(selectOptions);

          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );

          html = (
            <div
              key={`${object.variable}_${loopCount}`}
              className="col-sm-6 col-xs-12"
            >
              <div className="simpleField m-b-15">
                <div className="simpleLabel">{object.name}</div>
                <Select
                  onChange={this.handleSelect2Change}
                  value={selectValues}
                  options={selectOptions}
                  name={object.variable}
                  styles={colourStyles}
                  isSearchable
                  isMulti={false}
                />
              </div>
            </div>
          );
          break;
        case "month_of_visit":
          selectOptions = this.select2Options(
            this.state.monthOfArray,
            "value",
            "label",
          );
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );

          html = (
            <React.Fragment key={`mob_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "next_days":
          selectOptions = this.select2Options(
            object.variable_data,
            "value",
            "label",
          );
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`pro_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "past_days":
          selectOptions = this.select2Options(
            object.variable_data,
            "value",
            "label",
          );
          selectValues = this.select2Value(
            selectOptions,
            this.props.parentState.reportFilter[object.variable],
          );
          html = (
            <React.Fragment key={`pro_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValues}
                    options={selectOptions}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        case "current_period": {
          let optionGroups = [
            {
              label: "Month",
              options: [
                { label: "January", value: { type: "month", value: 0 } },
                { label: "February", value: { type: "month", value: 1 } },
                { label: "March", value: { type: "month", value: 2 } },
                { label: "April", value: { type: "month", value: 3 } },
                { label: "May", value: { type: "month", value: 4 } },
                { label: "June", value: { type: "month", value: 5 } },
                { label: "July", value: { type: "month", value: 6 } },
                { label: "August", value: { type: "month", value: 7 } },
                { label: "September", value: { type: "month", value: 8 } },
                { label: "October", value: { type: "month", value: 9 } },
                { label: "November", value: { type: "month", value: 10 } },
                { label: "December", value: { type: "month", value: 11 } },
              ],
            },
            {
              label: "Quarter",
              options: [
                { label: "Q1", value: { type: "quarter", value: 1 } },
                { label: "Q2", value: { type: "quarter", value: 2 } },
                { label: "Q3", value: { type: "quarter", value: 3 } },
                { label: "Q4", value: { type: "quarter", value: 4 } },
              ],
            },
            {
              label: "YoY",
              options: [{ label: "YoY", value: { type: "yoy" } }],
            },
          ];
          let selectValue = optionGroups[0].options[0];
          const currentValue =
            this.props.parentState.reportFilter[object.variable];
          if (currentValue) {
            selectValue = optionGroups
              .map((o) => o.options)
              .find(
                (v) =>
                  v.type === currentValue.type &&
                  v.value === currentValue.value,
              );
          }
          const isOptionDisabled = (option) => {
            if (option.value.type === "month") {
              return moment().month(option.value.value).isAfter();
            }
            if (option.value.type === "quarter") {
              return moment().quarter(option.value.value).isAfter();
            }
            return false;
          };
          html = (
            <React.Fragment key={`date_month_` + loopCount}>
              <div className="col-sm-6 col-xs-12">
                <div className="simpleField m-b-15">
                  <div className="simpleLabel">{object.name}</div>
                  <Select
                    onChange={this.handleSelect2Change}
                    value={selectValue}
                    options={optionGroups}
                    name={object.variable}
                    styles={colourStyles}
                    isSearchable
                    isMulti={false}
                    isOptionDisabled={isOptionDisabled}
                  />
                </div>
              </div>
            </React.Fragment>
          );
          break;
        }
        case "document_expiration_date":
          if (object.operator === "range") {
            let selectionRange = {
              startDate: this.props.parentState.resetEditMode
                ? this.props.parentState.startDate
                : this.state.startDate,
              endDate: this.props.parentState.resetEditMode
                ? this.props.parentState.endDate
                : this.state.endDate,
              key: "selection",
            };

            selectOptions = [
              { label: "30 Days", value: "30_days" },
              { label: "60 Days", value: "60_days" },
              { label: "90 Days", value: "90_days" },
              { label: "Custom Period", value: "custom" },
            ];

            selectValues = this.select2Value(
              selectOptions,
              this.props.parentState.reportFilter["date"],
            );

            html = (
              <React.Fragment key={`date_` + loopCount}>
                <div className="col-sm-6 col-xs-12">
                  <div className="simpleField m-b-15">
                    <div className="simpleLabel">{object.name}</div>
                    <Select
                      onChange={this.handleSelect2Change}
                      value={selectValues}
                      options={selectOptions}
                      name="date"
                      styles={colourStyles}
                      isSearchable
                      isMulti={false}
                    />
                  </div>
                </div>
                <div
                  className={
                    this.props.parentState.reportFilter.date === "custom"
                      ? "col-sm-6 col-xs-12 calender calender-hide-bottom"
                      : "col-sm-6 col-xs-12 calender  calender-hide-bottom no-display"
                  }
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <div className="simpleField field-icon m-b-15">
                    <div className="simpleLabel">Choose Custom Period</div>
                    {this.state.showCalendar && (
                      <DateRangePicker
                        className={"CalendarPreviewArea"}
                        ranges={[selectionRange]}
                        onChange={this.dateRangeOnChange}
                        maxDate={this.hasMaxDate}
                        dragSelectionEnabled={false}
                        staticRanges={[]}
                      />
                    )}
                    <input
                      onChange={this.handleInputChange}
                      type="text"
                      className="input-cal simpleInput height38px"
                      name="calendar-input"
                      value={
                        moment(selectionRange.startDate).format(
                          this.state.dateFormat,
                        ) +
                        `-` +
                        moment(selectionRange.endDate).format(
                          this.state.dateFormat,
                        )
                      }
                    />
                    <a name="calendar-input" className="simple-cal-icon" />
                  </div>
                </div>
              </React.Fragment>
            );
          }

          break;

        default:
          break;
      }
    }
    return html;
  };

  renderMonthYear = (variable_data) => {
    let htmlList = [];
    Object.keys(variable_data).forEach((idx) => {
      htmlList.push(
        <option key={"month_year-" + idx} value={idx}>
          {variable_data[idx]}
        </option>,
      );
    });
    return htmlList;
  };

  renderYear = () => {
    let htmlList = [];
    for (let counter = 2017; counter <= moment().format("YYYY"); counter++) {
      htmlList.push(
        <option key={"year-" + counter} value={counter}>
          {counter}
        </option>,
      );
    }
    return htmlList;
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (event) => {
    if (
      this.node &&
      this.node.contains(event.target) &&
      this.state.showCalendar === true
    ) {
      return;
    }
    this.toggleCalendar(event.target);
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar, clicked: 0 });
  };

  dateRangeOnChange = (payload) => {
    this.props.handleChildState({ resetEditMode: false });

    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    let clicked = this.state.clicked + 1;

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      showCalendar: showCalendar,
      startDate: startDate,
      endDate: endDate,
      clicked: clicked,
    });

    if (startDate && endDate) {
      let reportFilter = this.props.parentState.reportFilter;
      reportFilter.from_date = moment(startDate).format(ApiDateFormat);
      reportFilter.to_date = moment(endDate).format(ApiDateFormat);
      this.props.handleChildState(reportFilter);
    }
  };

  render() {
    let reportVariableHtml = [];
    if (this.props.reportVariableData && this.props.reportVariableData.length) {
      this.props.reportVariableData.forEach((obj, idx) => {
        reportVariableHtml.push(this.getHTMLBySlug(obj, ++idx));
      });
    }

    return <div className="row p-t-20">{reportVariableHtml}</div>;
  }
}
