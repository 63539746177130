import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isValidPhoneNumber } from "libphonenumber-js";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import "react-phone-input-2/lib/style.css";
import { createKlarnaPayment } from "../../../../../Actions/Sales/klarnaActions";
import { uiNotification } from "../../../../../services/UINotificationService";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ChargeFormKlarna = (props) => {
  const { createKlarnaPayment, chargeFormHandle, isTipsApplying } = props;
  const { invoice } = useInvoice();
  const [isCharging, setIsCharging] = useState(false);
  const { isTipsAvailable, showTipsModal } = usePaymentFlow();

  const { form, changeFormValue, hasError, errors } = chargeFormHandle;

  const klarnaPayment = async () => {
    const totalAmount = form.amount;
    if (hasError("amount")) return;

    if (!isValidPhoneNumber(form.phoneNumber)) {
      return uiNotification.error("Please Enter Phone Number!");
    }

    if (isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      await createKlarnaPayment({
        requestedAmount: totalAmount,
        invoiceId: invoice.id,
        patientPhone: form.phoneNumber,
        patientEmail: form.email,
      });
    }

    setIsCharging(false);
  };

  return !showTipsModal.value ? (
    <PaymentFormModal
      isLoading={isCharging}
      isTipsApplying={isTipsApplying}
      form={form}
      changeFormValue={changeFormValue}
      errors={errors}
      hasError={hasError}
      submit={klarnaPayment}
    />
  ) : (
    <PaymentTipsModal
      isCharging={isCharging}
      setIsCharging={setIsCharging}
      form={form}
      showTipsModal={showTipsModal}
      isTipsApplying={isTipsApplying}
    />
  );
};

ChargeFormKlarna.propTypes = {
  createKlarnaPayment: PropTypes.func,
  chargeFormHandle: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  };
};

const mapDispatchToProps = () => {
  return bindActionCreators(
    {
      createKlarnaPayment: createKlarnaPayment,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeFormKlarna);
