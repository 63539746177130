import React, { useState } from "react";
import { Modal } from "../../../../../shared/Modal/Modal";
import { ChangePassword as Widget } from "../../../../../widgets/ChangePassword/ChangePassword";

export function ChangePassword() {
  var [showModal, setShowModal] = useState(false);

  return (
    <>
      <a
        onClick={() => setShowModal(true)}
        className="easy-link pull-right no-padding-right"
      >
        <i className="fa fa-lock"></i>&nbsp; Change password
      </a>
      {showModal && (
        <Modal
          isOpen
          header={<Modal.Title>Change Password</Modal.Title>}
          size="small"
          shouldCloseOnOverlayClick={false}
          onClose={() => setShowModal(false)}
        >
          <Widget
            checkAuthBeforeSubmit
            onSuccess={() => setShowModal(false)} 
          />
        </Modal>
      )}
    </>
  )
}
