import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { AvailableBalance } from "../../../shared/AvailableBalance/AvailableBalance";
import { svg } from "../../../../../../assets/svg";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { usePaymentFlow } from "../../../hooks/usePaymentFlow";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { formatCurrency } from "../../../../../../utilities/general";

const PaymentFormModal = ({
  isLoading,
  isValid,
  isTipsApplying,
  submit,
  form,
  errors,
  hasError,
  changeFormValue,
  showTipsModal,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();
  const { isTipsAvailable } = usePaymentFlow();

  const handleSubmit = () => {
    if (isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.arWallet")}
      imageSrc={svg.arWalletPrimary}
      onCharge={handleSubmit}
      isCharging={isLoading}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
      isChargeDisabled={!isValid || isTipsApplying}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <AvailableBalance
          amount={formatCurrency(
            invoice?.patient?.walletBalance,
            invoice?.currency,
          )}
        />
      </ModalCharge.InputWrap>
    </ModalCharge>
  );
};

export default PaymentFormModal;
