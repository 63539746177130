import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { usePatientQuestionnaire } from "./hooks/usePatientQuestionnaire";
import { Skeleton } from "../../shared/Skeleton/Skeleton";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import classes from "./PatientQuestionnairePreview.module.scss";
import { collectAnsweredQuestions } from "./utilities";
import { useClientGlobalName } from "../../hooks/useClientGlobalName";

export function PatientQuestionnairePreview({
  patientQuestionnaireId,
  preloadedPatientQuestionnaire,
  noHeader,
}) {
  const { tCommon } = useAppTranslation.Common();
  const clientGlobalName = useClientGlobalName();

  const { data, isLoading } = usePatientQuestionnaire({
    patientQuestionnaireId,
    preloadedPatientQuestionnaire,
  });

  const answeredQuestions = useMemo(
    () => collectAnsweredQuestions(data),
    [data],
  );

  const renderAnswer = (answer) => {
    if (answer?.startsWith("http://") || answer?.startsWith("https://")) {
      return (
        <div className={classes.imageAnswer}>
          <img src={answer} alt="" width="200px" />
          <a href={answer} target="_blank" rel="noopener noreferrer">
            {tCommon("label.openInNewTab")}
          </a>
        </div>
      );
    }

    return answer;
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        Array.from(Array(5).keys()).map((k) => (
          <Skeleton key={k} count={1} height={48.56} />
        ))
      ) : (
        <>
          {!noHeader && (
            <Modal.Title className={classes.title}>
              {isLoading
                ? `${tCommon("label.loading")}...`
                : `${clientGlobalName.t({
                    path: "label.patientQuestionnaire",
                  })} - ${data?.questionnaire?.title}`}
            </Modal.Title>
          )}
          {answeredQuestions.map(({ id, question, answers, comment }) => (
            <div key={id} className={classes.questionWrap}>
              <div className={classes.question}>{question}</div>
              {answers?.length ? (
                <div className={classes.answers}>
                  {answers.map((answer, index) => (
                    <div key={index} className={classes.answer}>
                      {renderAnswer(answer)}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={classes.noAnswer}>
                  {tCommon("label.noAnswer")}
                </div>
              )}
              {comment && (
                <div>
                  <b>{tCommon("label.comment")}</b>: {comment}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

PatientQuestionnairePreview.propTypes = {
  patientQuestionnaireId: PropTypes.number,
  preloadedPatientQuestionnaire: PropTypes.object,
  noHeader: PropTypes.bool,
};
