import React from "react";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../../assets/svg";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";
import { InfoIcon } from "../../../../../../assets/Icons/InfoIcon";
import styles from "../../ViewTipsModal/scss/viewTipsModal.module.scss";

const PaymentTipsModal = ({
  isTipsApplying,
  maxAmountToPay,
  showTipsModal,
}) => {
  const { chargeWallet } = useInvoiceActions();

  const handleSubmit = () => {
    chargeWallet.initiate({
      amount: maxAmountToPay,
    });
  };

  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.arWallet")}
      imageSrc={svg.arWalletPrimary}
      onCharge={handleSubmit}
      isCharging={chargeWallet.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={maxAmountToPay} />
      <AlertBox className={styles.infoBox}>
        <InfoIcon width="17px" />
        <span>{tSales("checkoutInvoice.info.walletTipsInfo")}</span>
      </AlertBox>
    </ModalCharge>
  );
};

export default PaymentTipsModal;
