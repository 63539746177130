import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { WidgetNetSalesByClinic } from "./widgets/WidgetNetSalesByClinic.js";
import WidgetProviderSales from "./widgets/WidgetProviderSales.js";
import WidgetProductSales from "./widgets/WidgetProductSales.js";
import WidgetClinicNPSScores from "./widgets/WidgetClinicNPSScores.js";
import WidgetProviderNPSScores from "./widgets/WidgetProviderNPSScores.js";
import WidgetMostUsedDiscounts from "./widgets/WidgetMostUsedDiscounts.js";
import WidgetProviderSalesGoals from "./widgets/WidgetProviderSalesGoals.js";
import WidgetBookingPerProvider from "./widgets/WidgetBookingPerProvider.js";
import WidgetBookingPerClinic from "./widgets/WidgetBookingPerClinic.js";
import {
  fetchwidgetsData,
  submitSelectedIds,
  exportEmptyData,
} from "../../Actions/Dashboard/dashboardActions.js";
import WidgetProviderProcedureGoals from "./widgets/WidgetProviderProcedureGoals.js";
import WidgetOfficeSalesGoals from "./widgets/WidgetOfficeSalesGoals.js";
import { isFormSubmit, toggleBodyScroll } from "../../Utils/services.js";
import Loader from "../Common/Loader.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Header from "./components/Header";
import { Empty } from "./components/Empty";
import { AddWidgetModal } from "./components/AddWidgetModal";
import { WidgetNetSalesByProvider } from "./widgets/WidgetNetSalesByProvider.js";
import { getSelectedWidgetNames } from "./Dashboard.utils.js";
import { WidgetShopify } from "./widgets/WidgetShopify";
import WidgetRevenuePerHour from "./widgets/RevenuePerHour/WidgetRevenuePerHour.js";

const WIDGETS_BY_NAME = {
  sales_by_clinic: <WidgetNetSalesByClinic />,
  sales_by_provider: <WidgetProviderSales />,
  sales_by_product: <WidgetProductSales />,
  nps_scores_by_provider: <WidgetProviderNPSScores />,
  nps_scores_by_clinic: <WidgetClinicNPSScores />,
  most_used_discounts: <WidgetMostUsedDiscounts />,
  provider_sales_goals: <WidgetProviderSalesGoals />,
  provider_procedure_goals: <WidgetProviderProcedureGoals />,
  office_sales_goals: <WidgetOfficeSalesGoals />,
  booking_percentage_per_provider: <WidgetBookingPerProvider />,
  booking_percentage_per_clinics: <WidgetBookingPerClinic />,
  net_sales_by_provider: <WidgetNetSalesByProvider />,
  shopify_orders: <WidgetShopify />,
  revenue_per_hour: <WidgetRevenuePerHour />,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      showCreateDashboard: false,
      dashboardData: [],
      widgetId: [],
      dashboardViewData: [],
      selectedWidget: [],
      notSelectedWidget: [],
      dashWidgetList: [],
      widgetActive: [],
      tempSelected: [],
      dashWidgetActive: [],
      PopActiveWid: [],
      PopInactiveWid: [],
      showLoaderMain: false,
      showWidgets: [],
    };

    window.onscroll = () => {
      return false;
    };
  }

  fetchWidgets = () => {
    this.setState({ showLoaderMain: true });
    this.props
      .fetchwidgetsData()
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable to retrieve widgets",
        );
      })
      .finally(() => {
        this.setState({ showLoaderMain: false });
      });
  };

  componentDidMount() {
    this.fetchWidgets();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    nextProps.exportEmptyData();
    if (
      nextProps.dashWidgetList != undefined &&
      nextProps.dashWidgetList.data != prevState.dashWidgetList &&
      prevState.dashWidgetListTime != nextProps.dashWidgetListTime &&
      nextProps.dashWidgetList.status == 200
    ) {
      returnState.dashWidgetList = nextProps.dashWidgetList.data.all_widgets
        ? nextProps.dashWidgetList.data.all_widgets
        : [];
      returnState.widgetsResponse = nextProps.dashWidgetList.data;
      returnState.dashWidgetActive = nextProps.dashWidgetList.data
        .active_widgets
        ? nextProps.dashWidgetList.data.active_widgets
        : [];
      returnState.showWidgets = nextProps.dashWidgetList.data.user_widgets
        ? nextProps.dashWidgetList.data.user_widgets
        : [];
      returnState.dashWidgetListTime = nextProps.dashWidgetListTime;
      returnState.tempSelected = [];
      returnState.showLoaderMain = false;
      let allId = [];
      let activeWid = [];
      let inactiveWid = [];
      let idss = [];
      let widgets = [];

      if (returnState.dashWidgetList) {
        returnState.dashWidgetList.forEach((obj) => {
          allId.push(obj.id);
          const activeObj = returnState.dashWidgetActive.find(
            (x) => x.id == obj.id,
          );
          if (activeObj) {
            activeWid.push(obj.id);
          } else {
            inactiveWid.push(obj.id);
          }
        });
      } else {
        allId = [];
        activeWid = [];
        inactiveWid = [];
      }
      if (nextProps.dashWidgetList.data.user_widgets.length > 0) {
        widgets = nextProps.dashWidgetList.data.user_widgets;
        widgets.forEach((obj) => {
          idss.push(obj.dashboard_widget_id);
        });
      } else {
        widgets = [];
      }

      returnState.widgetId = idss;
      returnState.dashboardData = allId;
      returnState.PopActiveWid = activeWid;
      returnState.PopInactiveWid = inactiveWid;
      returnState.selectedWidget = idss;
    }
    if (
      nextProps.widgetActive != undefined &&
      nextProps.widgetActive.data != prevState.widgetActive &&
      prevState.widgetListTime != nextProps.widgetListTime &&
      nextProps.widgetActive.status == 200
    ) {
      returnState.dashboardViewData = nextProps.widgetActive.data.user_widgets;
      returnState.showCreateDashboard = false;
      returnState.showLoaderMain = false;
      returnState.widgetListTime = nextProps.widgetListTime;
      let dashboard_widgets = [];
      let idss = [];
      dashboard_widgets = nextProps.widgetActive.data.user_widgets;
      dashboard_widgets.forEach((obj) => {
        idss.push(obj.dashboard_widget_id);
      });

      returnState.widgetId = idss;
      returnState.selectedWidget = idss;
      returnState.widgetActive = JSON.stringify(idss);
      returnState.tempSelected = [];
    }

    if (
      nextProps.refreshDashboardDataTime !=
        prevState.refreshDashboardDataTime &&
      nextProps.refreshDashboard != undefined &&
      nextProps.refreshDashboard == true
    ) {
      returnState.refreshDashboardDataTime = nextProps.refreshDashboardDataTime;
      returnState.showCreateDashboard = false;
      returnState.dashboardData = [];
      returnState.widgetId = [];
      returnState.dashboardViewData = [];
      returnState.selectedWidget = [];
      returnState.notSelectedWidget = [];
      returnState.dashWidgetList = [];
      returnState.widgetActive = [];
      returnState.tempSelected = [];
      returnState.dashWidgetActive = [];
      returnState.PopActiveWid = [];
      returnState.PopInactiveWid = [];
      returnState.showWidgets = [];

      this.fetchWidgets();
    }

    return returnState;
  }

  selectImage = (event) => {
    let temp = [];
    let imageName = event;
    let id = imageName;
    let existOrNot = this.state.tempSelected.indexOf(id);
    temp = this.state.tempSelected;
    if (existOrNot > -1) {
      temp.splice(existOrNot, 1);
    } else {
      temp.push(id);
    }
    this.setState({
      tempSelected: temp,
    });
  };

  openModal = () => {
    let ret = {};
    ret.showCreateDashboard = true;
    ret.tempSelected = JSON.parse(JSON.stringify(this.state.selectedWidget));
    this.setState(ret);
    toggleBodyScroll(true);
  };

  closeModal = () => {
    this.setState({ showCreateDashboard: false, tempSelected: [] });
    toggleBodyScroll(false);
  };

  createDashboard = () => {
    if (isFormSubmit()) {
      let selectedIds = this.state.tempSelected;

      let dashboardWidgets = [];
      dashboardWidgets = selectedIds;
      let formData = {
        dashboard_widgets: dashboardWidgets,
      };

      this.setState({ showLoaderMain: true });
      toggleBodyScroll(false);

      this.props
        .submitSelectedIds(formData)
        .then((res) => {
          toast.success(this.state.globalLang[res.message] || "Saved");
        })
        .catch((res) => {
          toast.error(this.state.globalLang[res.message] || "Unable to save");
        })
        .finally(() => {
          this.setState({ showLoaderMain: false });
        });
    }
  };

  render() {
    const isHeaderVisible = this.state.dashboardData.length > 0;

    const isEmpty =
      this.state.widgetId.length <= 0 && this.state.dashboardData.length > 0;

    const isAddWidgetModalOpen =
      this.state.dashWidgetList && this.state.showCreateDashboard;

    const canAddWidget =
      this.state.widgetId.length > 0 && this.state.dashboardData.length > 0;

    const userWidgetNames = getSelectedWidgetNames(
      this.state.dashWidgetList,
      this.state.widgetId,
    );

    const widgetNamesToShow = userWidgetNames.filter((name) =>
      Object.keys(WIDGETS_BY_NAME).includes(name),
    );

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="memberWalletOuter full-width new-dashboard-container">
            {isHeaderVisible && (
              <Header onAddWidget={canAddWidget ? this.openModal : undefined} />
            )}
            {isEmpty && (
              <Empty
                onCreate={() => this.setState({ showCreateDashboard: true })}
              />
            )}
            {widgetNamesToShow.length > 0 && (
              <div className="row">
                {widgetNamesToShow.map((name, index) => (
                  <div className="col-md-6" key={`${name}-${index}`}>
                    {WIDGETS_BY_NAME[name]}
                  </div>
                ))}
              </div>
            )}
            <Loader showLoader={this.state.showLoaderMain} isFullWidth={true} />
          </div>
        </div>
        {isAddWidgetModalOpen && (
          <AddWidgetModal
            isOpen={isAddWidgetModalOpen}
            onClose={this.closeModal}
            selectedWidget={this.state.tempSelected}
            dashWidgetList={this.state.dashWidgetList}
            PopInactiveWid={this.state.PopInactiveWid}
            PopActiveWid={this.state.PopActiveWid}
            onCreateDashboard={this.createDashboard}
            onSelectImage={this.selectImage}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.DashboardReducer.action === "DASHBOARD_WIDGET_LIST") {
    if (state.DashboardReducer.data.status === 200) {
      returnState.dashWidgetList = state.DashboardReducer.data;
      returnState.dashWidgetListTime = new Date();
    }
  } else if (state.DashboardReducer.action === "SELECTED_WIDGETS_IDS") {
    if (state.DashboardReducer.data.status === 200) {
      returnState.widgetActive = state.DashboardReducer.data;
      returnState.widgetListTime = new Date();
    }
  } else if (state.SettingReducer.action === "REFRESH_DASHBOARD") {
    if (state.SettingReducer.data.status === 200) {
      returnState.refreshDashboard = true;
      returnState.refreshDashboardDataTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchwidgetsData,
      submitSelectedIds,
      exportEmptyData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
