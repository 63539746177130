import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../../consts/api";

export const usePatientWallet = (params, options = {}) => {
  return useQuery(
    ["patientWallet"],
    async () => {
      return await http.get(HTTP_ENDPOINTS.getPatientWallet(), { params });
    },
    { cacheTime: 0, select: (data) => data.data.data, ...options },
  );
};
