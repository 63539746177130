import React from "react";
import PropTypes from "prop-types";

export const UserMDIcon = ({
  width = "1em",
  height = "1em",
  title = "Medical Doctor Icon",
  color = "#3182ce",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 448 512"
    fill={color}
    aria-hidden="true"
    aria-labelledby={`usermd-title-${title.replace(/\s+/g, "-").toLowerCase()}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title id={`usermd-title-${title.replace(/\s+/g, "-").toLowerCase()}`}>
      {title}
    </title>
    <path
      d="M447.9 420.6l-19.2-67.2c-2.9-10.1-8.6-19.2-16.6-26.3-10.5-9.2-23.9-14.2-37.8-14.2-34.8
      0-63 28.2-63 63 0 12 3.3 23.5 9.6
      33.5 1.4 2.2 1.8 4.9 1.2
      7.4l-9.1 36.3H320v-80c0-13.3-10.7-24-24-24h-16V304c-3.4
      0-6.7 .1-10
      .4v-23.2l5.5-5.5c11.7-11.7 18.5-27.5
      18.5-44.2V192c0-51.9-42.1-94-94-94s-94
      42.1-94 94v39.5c0 16.6 6.7 32.4
      18.5 44.2l5.5 5.5V304c-3.3-.2-6.6-.4-10-.4v32h-16c-13.3
      0-24 10.7-24
      24v80H85.9l-9.1-36.3c-.6-2.5-.2-5.3
      1.2-7.5 6.4-9.9 9.7-21.4 9.7-33.4
      0-34.8-28.2-63-63-63-13.9
      0-27.4 5-37.8 14.2-7.9 7-13.6 16.2-16.5
      26.2l-19.2 67.2C.5 427.4
      0 431.3 0 435.3v48.7C0 498.5 13.5 512 30.1
      512h387.7c16.7 0 30.1-13.5
      30.1-30.1v-48.7c0-4.1-.5-8-1.4-11.6zM112
      448v-64h32v64h-32zm249.9
      0H176.1l-.7-48
      .7-32H368v80h-6.1z"
    />
  </svg>
);

UserMDIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  fill: PropTypes.string,
  color: PropTypes.string,
};
