import React from "react";
import PropTypes from "prop-types";

export const DownloadIcon = ({
  width = "1em",
  height = "1em",
  title = "Download Icon",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    aria-hidden="true"
    aria-labelledby={`download-title-${title
      .replace(/\s+/g, "-")
      .toLowerCase()}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title id={`download-title-${title.replace(/\s+/g, "-").toLowerCase()}`}>
      {title}
    </title>
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" x2="12" y1="15" y2="3" />
  </svg>
);

DownloadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
};
