import axios from "axios";

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_BASE_URL = "https://api.openai.com/v1";

const CHAT_COMPLETIONS_URL = `${OPENAI_BASE_URL}/chat/completions`;

async function formatNote(text, systemRole) {
  const response = await axios.post(
    CHAT_COMPLETIONS_URL,
    {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: systemRole,
        },
        {
          role: "user",
          content: `Format the following transcribed text into ${
            systemRole.includes("SOAP")
              ? "a SOAP note structure"
              : systemRole.split("into ")[1]
          }:\n\n${text}`,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
    },
  );

  const resultMessage = response.data?.choices?.[0]?.message?.content;
  if (resultMessage) {
    return resultMessage.trim();
  }
  throw new Error("No content returned from API");
}

export const formatSOAPNote = (text) =>
  formatNote(
    text,
    "You are a medical assistant that formats transcribed text into a SOAP note structure.",
  );

export const formatProcedureNote = (text) =>
  formatNote(
    text,
    "You are an assistant that formats transcribed text into a detailed Procedure Note.",
  );

export const formatSurgicalNote = (text) =>
  formatNote(
    text,
    "You are a medical assistant that formats transcribed text into a detailed Surgical Note.",
  );

export const formatFollowUpVisit = (text) =>
  formatNote(
    text,
    "You are a medical assistant that formats transcribed text into a Follow-Up Visit Note.",
  );
