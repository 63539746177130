import React, { useState } from "react";
import moment from "moment";
import { Header } from "./shared/Header/Header.js";
import { Total } from "./shared/Total/Total.js";
import { StaticReports } from "./StaticReports/StaticReports.js";
import { Box } from "../../../../../shared/Box/Box.js";
import { TableHeading } from "./shared/TableHeading/TableHeading.js";
import { Table } from "./shared/Table/Table.js";
import { useReports } from "./hooks/useReports.js";
import styles from "./styles.module.scss";
import { ReportLayout } from "../../../../BusinessInsights/components/ReportLayout/ReportLayout.js";
import { API_DATE_FORMAT } from "../../../../../consts/api.js";
import PatientWalletReport from "./StaticReports/PatientWallet/PatientWalletReport.js";

const modalInfo = {
  title: "shortTermLiabilityReport.info.title",
  rows: ["shortTermLiabilityReport.info.note"],
};

export const ShortTermLiability = () => {
  const { reports, order, period, search, totalLiability } = useReports();
  const requestParams = {
    fromDate: moment(period.value.from).format(API_DATE_FORMAT),
    toDate: moment(period.value.to).format(API_DATE_FORMAT),
    search_term: search.value,
  };

  const [selectedRowView, setSelectedRowView] = useState(null);

  const handleRowClick = (row) => {
    setSelectedRowView(row);
  };

  return (
    <ReportLayout>
      {!selectedRowView ? (
        <>
          <Header requestParams={requestParams} modalInfo={modalInfo} />
          <Total value={totalLiability} />
          <StaticReports />
          <Box className={styles.tableWrap}>
            <TableHeading
              isReportsLoading={reports.isLoading}
              period={period}
              search={search}
            />
            <Table
              order={order}
              reports={reports}
              period={period}
              handleRowClick={handleRowClick}
            />
          </Box>
        </>
      ) : (
        <PatientWalletReport
          selectedRow={selectedRowView}
          setSelectedRowView={setSelectedRowView}
          fromDate={moment(period.value.from).format(API_DATE_FORMAT)}
          toDate={moment(period.value.to).format(API_DATE_FORMAT)}
        />
      )}
    </ReportLayout>
  );
};
