import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Box } from "../../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { SALES_CHURN_REPORTS_ORDER_BY } from "../../../../../../api/queries/useSalesChurnReportsQuery";
import { LoadMoreObserver } from "../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { SUBSCRIPTION_TYPES } from "../../../../../../consts/api";
import {
  capitalize,
  formatCurrency,
} from "../../../../../../utilities/general";
import classes from "../sass/Table.module.scss";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../consts/general";
import { useClientGlobalName } from "../../../../../../hooks/useClientGlobalName";

export function Table({ reports, order }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();
  const clientGlobalName = useClientGlobalName();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const getFee = (r) => {
    if (r.monthlyFees || r.yearlyFees) {
      return formatCurrency(
        r.paymentFrequency === SUBSCRIPTION_TYPES.monthly.toLowerCase()
          ? r.monthlyFees
          : r.yearlyFees,
        r.currency,
      );
    }
    return tCommon("symbol.longDash");
  };

  const data = reports.value.map((r) => ({
    key: r.id,

    clinicName: r.clinicName || tCommon("symbol.longDash"),

    patientName: r.patientName || tCommon("symbol.longDash"),

    membershipName: r.tier || tCommon("symbol.longDash"),

    churnedOn: moment(r.churnOnDate).format(
      user?.previewDateFormat || PREVIEW_DATE_FORMAT,
    ),

    churnReason: tBi(
      `membershipChurnReport.filter.reason.${r.churnReason}`,
      r.churnReason || tCommon("symbol.longDash"),
    ),

    paymentFrequency:
      capitalize(r.paymentFrequency) || tCommon("symbol.longDash"),

    amount: getFee(r),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={data}
            cols={[
              {
                data: renderSortWrap(
                  tCommon("label.clinic"),
                  SALES_CHURN_REPORTS_ORDER_BY.clinic,
                ),
                accessor: "clinicName",
              },
              {
                data: renderSortWrap(
                  clientGlobalName.t({ path: "label.patientName" }),
                  SALES_CHURN_REPORTS_ORDER_BY.patient,
                ),
                accessor: "patientName",
              },
              {
                data: renderSortWrap(
                  tCommon("label.membershipName"),
                  SALES_CHURN_REPORTS_ORDER_BY.tier,
                ),
                accessor: "membershipName",
                headColClassName: classes.tableColMaxWidth,
              },
              {
                data: renderSortWrap(
                  tCommon("label.membershipType"),
                  SALES_CHURN_REPORTS_ORDER_BY.paymentFrequency,
                ),
                accessor: "paymentFrequency",
              },
              {
                data: tCommon("label.amount"),
                accessor: "amount",
              },
              {
                data: renderSortWrap(
                  tBi("membershipChurnReport.table.churnedOn"),
                  SALES_CHURN_REPORTS_ORDER_BY.churnOnDate,
                ),
                accessor: "churnedOn",
              },
              {
                data: renderSortWrap(
                  tBi("membershipChurnReport.table.churnReason"),
                  SALES_CHURN_REPORTS_ORDER_BY.churnReason,
                ),
                accessor: "churnReason",
                headColClassName: classes.tableColMaxWidth,
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};
