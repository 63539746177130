import { REDUCER_NAME } from "./config";
import { reducer } from "./reducer";
import { selectors } from "./selectors";
import { actions } from "./actions";

export const productPricing = {
  reducerName: REDUCER_NAME,
  selectors,
  actions,
  reducer,
};
