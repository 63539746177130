import { useCurrentAccountQuery } from "../api/queries/useAccountQuery";
import { CLIENT_GLOBAL_NAME } from "../consts/api";
import { useAppTranslation } from "../i18n/useAppTranslation";

export function useClientGlobalName() {
  var { tCommon } = useAppTranslation.Common();
  var accountQuery = useCurrentAccountQuery();
  var clientGlobalName = accountQuery.data?.preferences?.clientGlobalName;

  var t = ({ tFn, path, options } = {}) => {
    const tFn_ = tFn || tCommon;
    const path_ = path || "label";
    const options_ = options || { count: 1 };

    const name =
      clientGlobalName === CLIENT_GLOBAL_NAME.client ? "client" : "patient";

    return tFn_(path_ + "." + name, options_);
  };

  return {
    t,
  };
}
