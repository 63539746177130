import React from "react";
import { Modal } from "../../../../shared/Modal/Modal";
import classes from "./Header.module.scss";
import { useClientGlobalName } from "../../../../hooks/useClientGlobalName";

export function Header() {
  const clientGlobalName = useClientGlobalName();

  return (
    <div className={classes.root}>
      <Modal.Title>
        {clientGlobalName.t({ path: "fillPatientQuestionnaire.title" })}
      </Modal.Title>
    </div>
  );
}
