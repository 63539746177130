export const APP_LANGUAGES = {
  en: "en",
};

export const APP_DEFAULT_LANGUAGE = "en";

export const I18N_NAMESPACES = {
  common: "common",
  auth: "auth",
  settings: "settings",
  businessInsights: "businessInsights",
  sales: "sales",
  inbox: "inbox",
  clients: "clients",
  appointments: "appointments",
  inventory: "inventory",
  dashboard: "dashboard",
};
