import React from "react";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";
import { InfoIcon } from "../../../../../../assets/Icons/InfoIcon";
import styles from "../../ViewTipsModal/scss/viewTipsModal.module.scss";
import { tSales } from "../../../../../../i18n/useAppTranslation";

const PaymentTipsModal = ({ maxAmountToPay }) => {
  return (
    <>
      <ViewTipsModal amountToPay={maxAmountToPay} />
      <AlertBox className={styles.infoBox}>
        <InfoIcon width="17px" />
        <span>{tSales("checkoutInvoice.info.cherryTipsInfo")}</span>
      </AlertBox>
    </>
  );
};

export default PaymentTipsModal;
