import React, { useMemo, useState } from "react";
import { withRouter } from "react-router";
import Header from "../../../../../components/Header";
import { Table } from "../../../../../../../shared/Table/Table";
import { Box } from "../../../../../../../shared/Box/Box";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import styles from "../../../../../newBusinessInsightsContent.module.scss";
import { usePatientWallet } from "./hooks/usePatientWallet";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";

const PatientWalletReport = ({
  selectedRow,
  setSelectedRowView,
  fromDate,
  toDate,
}) => {
  const requestParams = {
    invoice_id: selectedRow.invoiceId,
    patient_id: selectedRow.patientId,
    package_id: selectedRow.packageId,
    product_name: selectedRow.product,
    fromDate,
    toDate,
  };

  const { data, isLoading } = usePatientWallet(requestParams);
  const [expandedRows, setExpandedRows] = useState([]);

  const reportHeader = useMemo(() => {
    if (!data?.report_header) {
      return [
        { data: "Amount", accessor: "amount" },
        { data: "Expiry Date", accessor: "expiry_date" },
        { data: "Product Name", accessor: "product_name" },
        { data: "Units Sold", accessor: "units_sold" },
        { data: "Invoice Date", accessor: "invoice_date" },
        { data: "Invoice Number", accessor: "invoice_number" },
      ];
    }
    return Object.keys(data?.report_header || {}).map((el) => {
      return {
        data: data.report_header[el],
        accessor: el,
      };
    });
  }, [data]);

  const reportData =
    data?.report_data.map((row, index) => ({
      ...row,
      index,
      children: row.redemptions,
    })) || [];

  const tableDataEmpty = !isLoading && !reportData.length;

  const handleExpandRow = (row) => {
    if (!expandedRows.includes(row.index)) {
      setExpandedRows((prev) => [...prev, row.index]);
    } else {
      setExpandedRows((prev) => prev.filter((index) => index !== row.index));
    }
  };

  return (
    <>
      <Header
        title="Short Term Liability"
        withBackBtn
        backFunc={() => setSelectedRowView(null)}
        downloadRoute={HTTP_ENDPOINTS.getPatientWallet()}
        requestParams={requestParams}
      />
      <div className="flex gap-16">
        <div className={styles.tableContainer}>
          <Box>
            <h4 className="m-t-15 m-b-20">{selectedRow.patient}</h4>
            <Table
              bgColor="white"
              cols={reportHeader}
              data={reportData}
              onRowClick={(row) => {
                if (row.children) {
                  handleExpandRow(row);
                }
              }}
              expandedRows={expandedRows}
            />
            {isLoading && (
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            )}
            {tableDataEmpty && <Empty position="center" />}
          </Box>
        </div>
      </div>
    </>
  );
};

export default withRouter(PatientWalletReport);
