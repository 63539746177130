import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  tCommon,
  useAppTranslation,
} from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { useChargeForm } from "../../hooks/useChargeForm";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useCherry } from "../../hooks/useCherry";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { PaymentAwaitLoader } from "./shared/PaymentAwaitLoader/PaymentAwaitLoader";
import { PendingPaymentAlert } from "./shared/PendingPaymentAlert/PendingPaymentAlert";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

function ViewChargeCherry({ isCherryPaymentWatchMode, isTipsApplying }) {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, noTipAmount } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const { showTipsModal, isTipsAvailable } = usePaymentFlow();

  const onClosePaymentView = () => {
    if (showTipsModal.value) {
      showTipsModal.update(false);
    } else {
      paymentViewClose();
    }
  };

  const {
    cherryPatient,
    chargeCherry,
    cancelOngoingCherryPayment,
    cancelPendingCherryPayment,
    ignorePendingCherryPayment,
    pendingCherryPayment,
  } = useCherry({
    onPaymentSuccess: () =>
      dispatch(checkoutInvoice.actions.paymentViewClose()),
  });

  const { data: maxCherryAmount } = useAvailablePaymentMethods(invoice.id, {
    select: (data) => data.cherry.maxAmount,
  });

  const onClose = () => {
    if (!isCherryPaymentWatchMode) {
      onClosePaymentView();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: Math.min(maxCherryAmount, noTipAmount),
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeCherry.initiate({ amount });
      },
      initialValues: {
        amount: Math.min(maxCherryAmount, noTipAmount) || "",
      },
    });

  const isChargeDisabled = Boolean(
    !isValid ||
      chargeCherry.isLoading ||
      isCherryPaymentWatchMode ||
      pendingCherryPayment ||
      isTipsApplying,
  );

  const cancelOnGoingPayment = () => {
    cancelOngoingCherryPayment.initiate();
    dispatch(checkoutInvoice.actions.changeIsCherryPaymentWatchMode(false));
  };

  const handleSubmit = () => {
    if (!showTipsModal.value && isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  const getChargeLabel = () => {
    if (!showTipsModal.value && isTipsAvailable) {
      return tCommon("label.continue");
    }
    if (!isTipsAvailable) {
      return tCommon("label.apply");
    }
  };

  const maxAmountToPay = Math.min(maxCherryAmount, parseFloat(form?.amount));

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cherry")}
      imageSrc={svg.cherryPay}
      onCharge={handleSubmit}
      isCharging={chargeCherry.isLoading}
      isCancelDisabled={isCherryPaymentWatchMode}
      isChargeDisabled={isChargeDisabled}
      chargeLabel={getChargeLabel()}
    >
      {!showTipsModal.value ? (
        <PaymentFormModal
          form={form}
          errors={errors}
          hasError={hasError}
          changeFormValue={changeFormValue}
          cherryPatient={cherryPatient}
        />
      ) : (
        <PaymentTipsModal maxAmountToPay={maxAmountToPay} />
      )}
      {isCherryPaymentWatchMode && (
        <PaymentAwaitLoader
          onCancelPayment={cancelOnGoingPayment}
          isCancelling={cancelOngoingCherryPayment.isLoading}
        />
      )}
      {pendingCherryPayment && (
        <PendingPaymentAlert
          message={pendingCherryPayment.message}
          onCancelPayment={cancelPendingCherryPayment.initiate}
          onIgnore={ignorePendingCherryPayment}
          isCancelling={cancelPendingCherryPayment.isLoading}
        />
      )}
    </ModalCharge>
  );
}

ViewChargeCherry.propTypes = {
  isCherryPaymentWatchMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isCherryPaymentWatchMode:
    checkoutInvoice.selectors.selectIsCherryPaymentWatchMode(state),
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCherry);
