import React from "react";
import { connect } from "react-redux";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";
import { useConfirmPayment } from "../../hooks/useConfirmPayment";
import { useStripePaymentIntent } from "../../hooks/useStripePaymentIntent";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { usePaymentFlow } from "../../../../../hooks/usePaymentFlow";
import { svg } from "../../../../../../../../assets/svg";
import { tSales } from "../../../../../../../../i18n/useAppTranslation";

const PaymentTipsModal = ({
  saveCard,
  formPaymentAmount,
  isTipsApplying,
  setShowPaymentForm,
}) => {
  const { showTipsModal } = usePaymentFlow();
  const { invoice } = useInvoice();

  const totalAmountToPay =
    parseFloat(formPaymentAmount) + parseFloat(invoice.tipAmount);

  const { mutateAsync: generateIntent, isLoading: isGeneratingIntent } =
    useStripePaymentIntent({
      onError: (error) => {
        uiNotification.error(error.message);
      },
    });

  const { confirmPayment, isLoading } = useConfirmPayment();

  const handleSubmit = async () => {
    generateIntent({
      invoice_id: invoice.id,
      amount: totalAmountToPay,
    })
      .then((res) => {
        const paymentIntentId = res.data.data.paymentIntentId;
        confirmPayment({
          paymentIntentId,
          saveCard,
        }).catch(() => {
          showTipsModal.update(false);
          setShowPaymentForm(false);
        });
      })
      .catch((error) => {
        uiNotification.error(error.message);
      });
  };

  return (
    <ModalCharge
      isOpen
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onClose={() => {
        showTipsModal.update(false);
        setShowPaymentForm(false);
      }}
      onCharge={handleSubmit}
      isCharging={isLoading || isGeneratingIntent}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(PaymentTipsModal);
