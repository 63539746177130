import React from "react";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { svg } from "../../../../../../../../assets/svg";

const PaymentTipsModal = ({
  showLoader,
  submit,
  form,
  showTipsModal,
  isTipsApplying,
}) => {
  const { invoice } = useInvoice();

  const totalAmountToPay =
    parseFloat(form.amount) + parseFloat(invoice.tipAmount);

  const handleSubmit = () => {
    submit(totalAmountToPay);
  };

  const onClose = () => {
    showTipsModal.update(false);
  };
  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onCharge={handleSubmit}
      isCharging={showLoader}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
