import React, { useState } from "react";
import { connect } from "react-redux";
import classes from "./styles.module.scss";
import { Select } from "../../../../../shared/Select/Select";
import { useUpdate } from "./hooks/useUpdate";
import { productPricing } from "../../../../../store/inventory/productPricing";
import { Checkbox } from "./shared/Checkbox/Checkbox";
import { dispatch } from "../../../../../store/store";
import { useInit } from "./hooks/useInit";
import { SaveButton } from "./shared/SaveButton/SaveButton";
import {
  isDefaultMembership,
  isPricesChanged,
  validateChangedPricesForRequest,
} from "../../../../../store/inventory/productPricing/utilities";
import { Input } from "./shared/Input/Input";
import { MemberPriceInfoLabel } from "./shared/MemberPriceInfoLabel/MemberPriceInfoLabel";
import { ClinicRow } from "./shared/ClinicRow/ClinicRow";
import { CHANAGE_PRICE_TYPE } from "../../../../../store/inventory/productPricing/config";
import { RouterPrompt } from "../../../../../widgets/RouterPrompt/RouterPrompt";
import { uiNotification } from "../../../../../services/UINotificationService";

const UNSAVED_CHANGES_TEXT =
  "Are you sure you want to change the membership? You have changes that will not be saved";

const { selectors, actions } = productPricing;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  forAllMemberships: selectors.selectForAllMemberships(state),
  membership: selectors.selectMembership(state),
  memberships: selectors.selectMemberships(state),
  clinics: selectors.selectClinics(state),
  getPerUnitPrice: selectors.selectPerUnitPrice(state),
  getMemberPrice: selectors.selectMemberPrice(state),
  getOriginalPrices: selectors.selectOriginalPrices(state),
  getChangedPrices: selectors.selectChangedPrices(state),
  getChangedPricesForRequest: selectors.selectChangedPricesForRequest(state),
  isInitialized: selectors.selectInitialized(state),
});

export const Pricing = connect(mapStateToProps)(
  ({
    productId,
    initData,
    refetch,
    forAllClinics,
    forAllMemberships,
    membership,
    memberships,
    clinics,
    getPerUnitPrice,
    getMemberPrice,
    getOriginalPrices,
    getChangedPrices,
    getChangedPricesForRequest,
    isInitialized,
    errors,
  }) => {
    useInit(initData, isInitialized);

    const updatePriceList = useUpdate(refetch);
    const [errors_, setErrors_] = useState(null);

    const changeMembershipAction = (action) => {
      const isChanged = isPricesChanged({
        prices: getChangedPrices(),
        initialPrices: getOriginalPrices(),
        forAllClinics,
      });

      if (isChanged && window.confirm(UNSAVED_CHANGES_TEXT)) {
        dispatch(actions.revertChangedPrices());
      }

      action();
    };

    const onSave = () => {
      setErrors_(null);
      const requestChangedPrices = getChangedPricesForRequest();

      const errors = validateChangedPricesForRequest({
        changedPrices: getChangedPrices(),
        requestChangedPrices,
        forAllClinics,
      });

      if (!errors.perUnitError && errors.perClinicErrors.length === 0) {
        updatePriceList.initiate({
          productId: Number(productId),
          priceList: requestChangedPrices,
        });
      } else {
        setErrors_(errors);
        uiNotification.error(
          "Please fill all the required fields in Pricing tab",
        );
      }
    };

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={classes.wrap}>
            Product Price
            <div className={classes.right}>
              <div className="flex-col gap-8">
                <Checkbox
                  label="Same for All Clinics"
                  checked={forAllClinics}
                  onChange={() => {
                    dispatch(actions.setForAllClinics(!forAllClinics));
                  }}
                />
                {Boolean(productId) && (
                  <Checkbox
                    label="Same for All Memberships"
                    checked={forAllMemberships}
                    onChange={() => {
                      changeMembershipAction(() =>
                        dispatch(
                          actions.setForAllMemberships(!forAllMemberships),
                        ),
                      );
                    }}
                  />
                )}
              </div>
              {Boolean(productId) && (
                <div>
                  <span className={classes.selectMembership}>
                    Select a membership
                  </span>
                  <Select
                    className={classes.membershipSelect}
                    size="small"
                    options={memberships}
                    value={membership}
                    onChange={(option) => {
                      changeMembershipAction(() =>
                        dispatch(actions.selectMembership(option)),
                      );
                    }}
                  />
                </div>
              )}
              {Boolean(productId) && (
                <SaveButton
                  isLoading={updatePriceList.isLoading}
                  onClick={onSave}
                />
              )}
            </div>
          </div>
          {forAllClinics ? (
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <Input
                  required
                  label="Price Per Unit"
                  placeholder="Price Per Unit"
                  value={getPerUnitPrice()}
                  isError={!!errors?.perUnitError || !!errors_?.perUnitError}
                  onChange={(e) => {
                    dispatch(
                      actions.changePrice({
                        type: CHANAGE_PRICE_TYPE.perUnit,
                        value: e.target.value,
                      }),
                    );
                  }}
                />
              </div>
              {Boolean(productId) && (
                <div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">
                  <Input
                    placeholder="Price for Members"
                    value={getMemberPrice()}
                    onChange={(e) => {
                      dispatch(
                        actions.changePrice({
                          type: CHANAGE_PRICE_TYPE.member,
                          value: e.target.value,
                        }),
                      );
                    }}
                    label={
                      isDefaultMembership(membership)
                        ? "Member Price"
                        : membership?.label
                    }
                  />
                </div>
              )}
            </div>
          ) : (
            clinics.map((clinic) => (
              <ClinicRow label={clinic.name} key={clinic.id}>
                <div className="col-sm-4 col-xs-12">
                  <Input
                    required
                    label="Product Price Per Unit"
                    placeholder="Price Per Unit"
                    value={getPerUnitPrice(clinic.id)}
                    isError={
                      !!errors?.perClinicErrors?.includes?.(clinic.id) ||
                      !!errors_?.perClinicErrors?.includes?.(clinic.id)
                    }
                    onChange={(e) => {
                      dispatch(
                        actions.changePrice({
                          type: CHANAGE_PRICE_TYPE.perUnit,
                          value: e.target.value,
                          clinicId: clinic.id,
                        }),
                      );
                    }}
                  />
                </div>
                {Boolean(productId) && (
                  <div className="col-md-4 col-sm-5 col-xs-12">
                    <Input
                      placeholder="Price for Members"
                      value={getMemberPrice(clinic.id)}
                      onChange={(e) => {
                        dispatch(
                          actions.changePrice({
                            type: CHANAGE_PRICE_TYPE.member,
                            value: e.target.value,
                            clinicId: clinic.id,
                          }),
                        );
                      }}
                      label={
                        !isDefaultMembership(membership) ? (
                          membership.label
                        ) : (
                          <MemberPriceInfoLabel />
                        )
                      }
                    />
                  </div>
                )}
              </ClinicRow>
            ))
          )}
        </div>
        <RouterPrompt
          nativePrompt
          whenFn={() =>
            isPricesChanged({
              prices: getChangedPrices(),
              initialPrices: getOriginalPrices(),
              forAllClinics,
            })
          }
          title="Warning"
          text={UNSAVED_CHANGES_TEXT}
        />
      </div>
    );
  },
);
