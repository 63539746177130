import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import Loader from "../../../../../../../Components/Common/Loader";
import {
  getStripePaymentIntent,
  getClientCardDataStripe,
} from "../../../../../../../Actions/Stripe/StripeActions";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import PaymentFormModal from "../components/CardOnFile/PaymentFormModal";
import PaymentTipsModal from "../components/CardOnFile/PaymentTipsModal";

const FormCardOnFile = ({
  cardList,
  getStripePaymentIntent,
  type,
  isTipsApplying,
}) => {
  const { invoice, refetchInvoice, noTipAmount } = useInvoice();
  const { showTipsModal } = usePaymentFlow();

  const [showLoader, setShowLoader] = useState(false);

  const cardOptions = useMemo(() => {
    return (
      cardList?.length &&
      cardList.map((c) => {
        const last4 = `ending with ${c.card.last4}`;
        return {
          label: c.type === "card" ? `${c.card.brand} ${last4}` : c.card.email,
          value: c.id,
        };
      })
    );
  }, [cardList]);

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: noTipAmount,
      currency: invoice?.currency,
    }),
    selectedCard: yup.object().required("Please select a card"),
  });

  const { form, errors, changeFormValue, hasError, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: () => {
        completePayment();
      },
      initialValues: {
        amount: noTipAmount || "",
        selectedCard: cardOptions[0] || null,
      },
    });

  const completePayment = async (amount = form.amount) => {
    let stripeFormData = {};
    stripeFormData.payment_method_id = form.selectedCard.value;
    stripeFormData.invoice_id = invoice.id;
    stripeFormData.amount = amount;

    setShowLoader(true);
    try {
      await getStripePaymentIntent(stripeFormData);
      refreshInvoiceDetails();
      toast.success("Payment done successfully");
      dispatch(checkoutInvoice.actions.paymentViewClose());
    } catch (error) {
      toast.error(error.message);
      showTipsModal.update(false);
    }
    setShowLoader(false);
  };

  const refreshInvoiceDetails = async () => {
    await refetchInvoice();

    let invoiceDetailInterval = setInterval(() => {
      refetchInvoice().then((response) => {
        if (response?.data?.status !== "pending") {
          clearInterval(invoiceDetailInterval);
        }
      });
    }, 2000);
  };

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          form={form}
          errors={errors}
          changeFormValue={changeFormValue}
          type={type}
          hasError={hasError}
          cardOptions={cardOptions}
          isTipsApplying={isTipsApplying}
          isValid={isValid}
          submit={submit}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          form={form}
          showLoader={showLoader}
          showTipsModal={showTipsModal}
          isTipsApplying={isTipsApplying}
          submit={completePayment}
        />
      )}

      <Loader isFullWidth={true} showLoader={showLoader} />
    </div>
  );
};

FormCardOnFile.propTypes = {
  cardList: PropTypes.array,
  getStripePaymentIntent: PropTypes.func,
};

const mapStateToProps = (state, { type }) => {
  const list =
    type === "card"
      ? state.StripeReducer.cardList.filter((card) => card.type === "card")
      : state.StripeReducer.cardList.filter((card) => card.type === "link");

  return {
    cardList: list,
    isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getClientCardDataStripe, getStripePaymentIntent },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FormCardOnFile));
