import React from "react";
import classes from "./styles.module.scss";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import { SaveDiskIcon } from "../../../../../../../assets/Icons/SaveDiskIcon";

export function SaveButton({ onClick, isLoading }) {
  return (
    <button className={classes.root} disabled={isLoading} onClick={onClick}>
      {isLoading ? (
        <CircularProgress size="small" />
      ) : (
        <SaveDiskIcon width="18px" />
      )}
    </button>
  );
}
