import React from "react";
import { Modal } from "../../../../../../../shared/Modal/Modal";

export function MemberPriceInfoLabel() {
  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <>
      Member Price
      <button
        onClick={() => setShowInfo(true)}
        className="infoIcon absolute -m-t-5 m-l-3 btn-reset"
      >
        {" "}
        <i className="fa fa-info-circle"></i>
      </button>
      {showInfo && (
        <Modal isOpen onClose={() => setShowInfo(false)}>
          Members will pay the Member Price when the discount is configured at
          0%.
        </Modal>
      )}
    </>
  );
}
