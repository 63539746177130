import React from "react";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { CheckIcon } from "../../../../../../assets/Icons/CheckIcon";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";

export function Footer({ save, forceSave }) {
  var { tCommon: t } = useAppTranslation.Common();

  return (
    <div className={styles.root}>
      <Button
        color="success"
        onClick={save.initiate}
        isDisabled={save.isLoading || forceSave?.isLoading}
        rightAdornment={
          save.isLoading ? (
            <CircularProgress color="white" size="small" />
          ) : (
            <CheckIcon width="20px" />
          )
        }
      >
        {t("label.submit")}
      </Button>
      {forceSave && (
        <Button
          variant="outlined"
          onClick={forceSave.initiate}
          isDisabled={forceSave.isLoading || save.isLoading}
          rightAdornment={
            forceSave.isLoading ? <CircularProgress size="small" /> : undefined
          }
        >
          {t("fillPatientQuestionnaire.finishLater")}
        </Button>
      )}
    </div>
  );
}
