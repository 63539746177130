import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useChargeForm } from "../../hooks/useChargeForm";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ViewChargeCash = ({ isTipsApplying }) => {
  const { invoice, amountToPay, noTipAmount } = useInvoice();
  const { chargeCash } = useInvoiceActions();
  const { showTipsModal, isTipsAvailable } = usePaymentFlow();

  const schema = yup.object({
    amount: composeAmountSchema(
      {
        amountToPay: noTipAmount,
        currency: invoice?.currency,
      },
      true,
    ),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeCash.initiate({ amount });
      },
      initialValues: {
        amount: noTipAmount || "",
      },
    });

  useEffect(() => {
    if (amountToPay === 0 && isTipsAvailable) {
      showTipsModal.update(true);
      changeFormValue("amount", 0);
    }
  }, []);

  return !showTipsModal.value ? (
    <PaymentFormModal
      submit={submit}
      showTipsModal={showTipsModal}
      form={form}
      errors={errors}
      hasError={hasError}
      isLoading={chargeCash.isLoading}
      isValid={isValid}
      isTipsApplying={isTipsApplying}
      changeFormValue={changeFormValue}
    />
  ) : (
    <PaymentTipsModal
      form={form}
      showTipsModal={showTipsModal}
      isTipsApplying={isTipsApplying}
    />
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCash);
