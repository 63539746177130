import React, { useState } from "react";
import cx from "clsx";
import { useCheckProviderFutureAppointmentsMutation } from "../../../../../../api/mutations/useCheckProviderFutureAppointmentsMutation";
import { useDeleteInviteMutation } from "../../../../../../api/mutations/useDeleteInviteMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { ConfirmModal } from "../../../../../../boxes/ConfirmModal/ConfirmModal";
import { ReassignModal } from "../ReassignModal/ReassignModal";
import { DeleteIcon } from "../../../../../../assets/Icons/DeleteIcon";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import styles from "./DeleteUser.module.scss";

export function DeleteUser({ userId, wpuId, refetchUsersPendingInvites }) {
  const [isOpen, setIsOpen] = useState({
    confirmModal: false,
    assignModal: false,
  });
  const [appointmentData, setAppointmentData] = useState({
    providers: [],
    upcomingAppointmentCount: null,
  });

  const checkAppointments = useCheckProviderFutureAppointmentsMutation({
    onSuccess: (response) => {
      if (response?.upcomingAppointmentCount) {
        setAppointmentData({
          providers: response?.providers,
          upcomingAppointmentCount: response?.upcomingAppointmentCount,
        });
        return setIsOpen({
          confirmModal: false,
          assignModal: true,
        });
      }
      setIsOpen({
        confirmModal: true,
        assignModal: false,
      });
    },
    onError: () => {
      uiNotification.error("Failed to check appointments");
    },
  });

  const deleteInvite = useDeleteInviteMutation({
    onSuccess: () => {
      uiNotification.success("Invite deleted");
      refetchUsersPendingInvites();
      onClose();
    },
    onError: () => {
      uiNotification.error("Unable to delete invite. Try again later");
    },
  });

  const onClose = () => {
    setIsOpen({
      confirmModal: false,
      assignModal: false,
    });
  };

  const callDeleteModal = () => {
    checkAppointments.mutate({ userId: userId });
  };

  const onDelete = () => {
    deleteInvite.mutate({ wpuId: wpuId });
    onClose();
  };

  return (
    <div className={styles.wrapper}>
      <button
        className={cx("easy-link", styles.btnDelete)}
        disabled={checkAppointments.isLoading}
        onClick={() => callDeleteModal()}
      >
        <div className={styles.btnContent}>
          {checkAppointments.isLoading ? (
            <CircularProgress size="tiny" color="primary" />
          ) : (
            <div className={styles.icon}>
              <DeleteIcon width={13} />
            </div>
          )}
          Delete
        </div>
      </button>
      {checkAppointments.status === "success" && (
        <ReassignModal
          isOpen={isOpen.assignModal}
          onClose={onClose}
          refetchUsersPendingInvites={refetchUsersPendingInvites}
          appointmentsCount={appointmentData.upcomingAppointmentCount}
          providers={appointmentData.providers}
          wpuId={wpuId}
        />
      )}

      <ConfirmModal
        isOpen={isOpen.confirmModal}
        onConfirm={onDelete}
        onClose={onClose}
        onCancel={onClose}
      >
        <h4>Are you sure you want to delete this user?</h4>
      </ConfirmModal>
    </div>
  );
}
