import React from "react";
import PhoneInput from "react-phone-input-2";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { Input } from "../../../../../../shared/Input/Input";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { usePaymentFlow } from "../../../hooks/usePaymentFlow";
import { svg } from "../../../../../../assets/svg";
import { InputError } from "../../../../../../shared/InputError/InputError";
import classes from "../../ViewChargeKlarna/ViewChargeKlarna.module.scss";

const PaymentFormModal = ({
  isLoading,
  isValid,
  isTipsApplying,
  submit,
  form,
  errors,
  hasError,
  changeFormValue,
  showTipsModal,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice, noTipAmount } = useInvoice();
  const { isTipsAvailable } = usePaymentFlow();

  const handleSubmit = () => {
    if (isTipsAvailable) {
      showTipsModal.update(true);
    } else {
      submit();
    }
  };

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={tSales("checkoutInvoice.paymentOptions.text2pay")}
      imageSrc={svg.text2Pay}
      onCharge={handleSubmit}
      isCharging={isLoading}
      isChargeDisabled={!isValid || isTipsApplying}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={noTipAmount}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          onChange={(value) => changeFormValue("amount", value)}
          isDisabled
        />
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <InputLabel>{tSales("checkoutInvoice.formInputLabel.mail")}</InputLabel>
        <Input
          type="email"
          size="medium"
          value={form.email}
          isError={hasError("email")}
          onChange={(e) => changeFormValue("email", e.target.value)}
          placeholder={tCommon("formPlaceholder.email")}
          isDisabled={Boolean(invoice?.patient?.email)}
        />
        {errors.email && <InputError>{errors.email}</InputError>}
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.phoneNumber")}
        </InputLabel>
        <PhoneInput
          size="medium"
          value={form.phoneNumber}
          isError={hasError("phoneNumber")}
          country={"us"}
          countryCodeEditable={false}
          enableSearch={true}
          className={classes.phoneKlarna + " klarna-tel-input"}
          isValid={Boolean(!errors.phoneNumber)}
          onChange={(phone, country, event, formattedPhone) => {
            changeFormValue("country", country);
            changeFormValue("phoneNumber", formattedPhone);
          }}
        />
        {errors.phoneNumber && <InputError>{errors.phoneNumber}</InputError>}
      </ModalCharge.InputWrap>
    </ModalCharge>
  );
};

export default PaymentFormModal;
