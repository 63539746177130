import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import { useForm } from "./hooks/useForm";
import { useSubmit } from "./hooks/useSubmit";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import classes from "./ModalPatientConsentSign.module.scss";
import { Button } from "../../shared/Button/Button";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { Signature } from "../../boxes/Signature/Signature";
import { InputError } from "../../shared/InputError/InputError";
import { Input } from "../../shared/Input/Input";
import { useClientGlobalName } from "../../hooks/useClientGlobalName";

export function ModalPatientConsentSign({
  isOpen,
  onClose,
  onSuccess,
  consent,
  allowPatientNameChange,
}) {
  const { tCommon } = useAppTranslation.Common();
  const submit = useSubmit({ consent, onSuccess });
  const clientGlobalName = useClientGlobalName();

  const form = useForm({
    initialValues: {
      patientSignature: consent?.patientSignatureUrl || "",
      patientName: consent?.patientName || "",
      witnessSignature: consent?.witnessSignatureUrl || "",
      witnessName: consent?.witnessName || "",
    },
    onSubmit: (values) => submit.initiate(values),
    isWitnessSignatureRequired: consent?.isWitnessSignatureRequired,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Modal.Title>
          {clientGlobalName.t({ path: "signPatientConsent.label" })}
        </Modal.Title>
      }
      contentClassName={classes.root}
      shouldCloseOnOverlayClick={false}
      footer={
        <div className={classes.footer}>
          <Button
            onClick={form.handleSubmit}
            isDisabled={submit.isLoading}
            leftAdornment={
              submit.isLoading ? (
                <CircularProgress color="white" size="small" />
              ) : undefined
            }
          >
            {tCommon("signPatientConsent.form.submit")}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
        </div>
      }
    >
      <div className={classes.name}>
        <b>{tCommon("signPatientConsent.consentName")}:</b>
        {consent.name}
      </div>
      <div className={classes.block}>
        <h4>{clientGlobalName.t()}</h4>
        <div>
          <Input
            name="patientName"
            value={form.values.patientName}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={clientGlobalName.t({
              path: "signPatientConsent.form.patientName",
            })}
            isError={form.hasError("patientName")}
            isDisabled={!allowPatientNameChange && !!consent.patientName}
          />
          {form.hasError("patientName") && (
            <InputError>{form.errors.patientName}</InputError>
          )}
        </div>
        <div>
          <Signature
            signatureUrl={consent.patientSignatureUrl}
            onChange={(data) => form.setFieldValue("patientSignature", data)}
            onClear={() => form.setFieldValue("patientSignature", "")}
            onEdit={() => form.setFieldValue("patientSignature", "")}
            onReset={() => form.setFieldValue("patientSignature", "")}
            isError={form.hasError("patientSignature")}
            isDisabled={Boolean(consent.patientSignatureUrl)}
          />
          {form.hasError("patientSignature") && (
            <InputError>{form.errors.patientSignature}</InputError>
          )}
        </div>
      </div>
      {consent.isWitnessSignatureRequired && (
        <div className={classes.block}>
          <h4>{tCommon("label.witness")}</h4>
          <div>
            <Input
              name="witnessName"
              value={form.values.witnessName}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder={tCommon("signPatientConsent.form.witnessName")}
              isError={form.hasError("witnessName")}
              isDisabled={Boolean(
                consent.witnessName && consent.witnessSignatureUrl,
              )}
            />
            {form.hasError("witnessName") && (
              <InputError>{form.errors.witnessName}</InputError>
            )}
          </div>
          <div>
            <Signature
              signatureUrl={consent.witnessSignatureUrl}
              onChange={(data) => form.setFieldValue("witnessSignature", data)}
              onClear={() => form.setFieldValue("witnessSignature", "")}
              onEdit={() => form.setFieldValue("witnessSignature", "")}
              onReset={() => form.setFieldValue("witnessSignature", "")}
              isError={form.hasError("witnessSignature")}
              isDisabled={Boolean(consent.witnessSignatureUrl)}
            />
            {form.hasError("witnessSignature") && (
              <InputError>{form.errors.witnessSignature}</InputError>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

ModalPatientConsentSign.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  allowPatientNameChange: PropTypes.bool,
  consent: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    patientSignatureUrl: PropTypes.string,
    patientName: PropTypes.string,
    witnessSignatureUrl: PropTypes.string,
    witnessName: PropTypes.string,
    isWitnessSignatureRequired: PropTypes.bool.isRequired,
  }).isRequired,
};

ModalPatientConsentSign.defaultProps = {
  allowPatientNameChange: false,
};
