import React from "react";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { img } from "../../../../../../assets/img";
import { tCommon, tSales } from "../../../../../../i18n/useAppTranslation";
import ViewTipsModal from "../../ViewTipsModal/ViewTipsModal";

const PaymentTipsModal = ({ isTipsApplying, form, showTipsModal }) => {
  const { invoice } = useInvoice();
  const { chargeGreenSky } = useInvoiceActions();
  const totalAmountToPay =
    parseFloat(form.amount) + parseFloat(invoice.tipAmount);

  const handleSubmit = () => {
    chargeGreenSky.initiate({
      amount: totalAmountToPay,
      note: form.note,
    });
  };

  const onClose = () => {
    showTipsModal.update(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.greenSky")}
      imageSrc={img.greenSky}
      onCharge={handleSubmit}
      isCharging={chargeGreenSky.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={isTipsApplying}
    >
      <ViewTipsModal amountToPay={totalAmountToPay} />
    </ModalCharge>
  );
};

export default PaymentTipsModal;
