import React from "react";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Input } from "../../../../shared/Input/Input";
import { InputError } from "../../../../shared/InputError/InputError";
import { Button } from "../../../../shared/Button/Button";
import styles from "./styles.module.scss";
import { useForm } from "../../hooks/useForm";
import { useSubmit } from "../../hooks/useSubmit";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { InputPassword } from "../../../../boxes/InputPassword/InputPassword";

/**
 * @param {{
 *    wpuId: string | number;
 *    resendOtp: () => Promise<Object>;
 *    onSubmitSuccess: () => void;
 *    checkAuthBeforeSubmit?: boolean;
 * }} param0
 */
export function Form({
  wpuId,
  resendOtp,
  onSubmitSuccess,
  checkAuthBeforeSubmit,
}) {
  var { tCommon: t } = useAppTranslation.Common();

  var submit = useSubmit({
    wpuId,
    onSuccess: onSubmitSuccess,
    checkAuthBeforeSubmit,
  });

  var { values, handleSubmit, handleBlur, handleChange, getError } = useForm(
    submit.initiate,
  );

  var oldPasswordError = getError("oldPassword");
  var newPasswordError = getError("newPassword");
  var newPasswordConfirmError = getError("newPasswordConfirm");
  var otpError = getError("otp");

  return (
    <div className={styles.root}>
      <div>
        <InputLabel>{t("changePassword.oldPassword")}</InputLabel>
        <InputPassword
          name="oldPassword"
          value={values.oldPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!oldPasswordError}
        />
        {!!oldPasswordError && <InputError>{oldPasswordError}</InputError>}
      </div>
      <div>
        <InputLabel>{t("changePassword.newPassword")}</InputLabel>
        <InputPassword
          name="newPassword"
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!newPasswordError}
        />
        {!!newPasswordError && <InputError>{newPasswordError}</InputError>}
      </div>
      <div>
        <InputLabel>{t("changePassword.confirmPassword")}</InputLabel>
        <InputPassword
          name="newPasswordConfirm"
          value={values.newPasswordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!newPasswordConfirmError}
        />
        {!!newPasswordConfirmError && (
          <InputError>{newPasswordConfirmError}</InputError>
        )}
      </div>
      <div>
        <InputLabel>{t("changePassword.otp")}</InputLabel>
        <Input
          type="number"
          name="otp"
          value={values.otp}
          onChange={handleChange}
          onBlur={handleBlur}
          isError={!!otpError}
        />
        {!!otpError && <InputError>{otpError}</InputError>}
      </div>
      <div className={styles.footer}>
        <Button
          onClick={handleSubmit}
          isDisabled={submit.isLoading}
          leftAdornment={
            submit.isLoading ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {t("changePassword.submit")}
        </Button>
        <Button variant="text" onClick={resendOtp}>
          {t("changePassword.resendOtp")}
        </Button>
      </div>
    </div>
  );
}
