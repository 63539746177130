import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { TemplateSelector } from "./templates/TemplateSelector/TemplateSelector";
import { NoteDisplay } from "./shared/NoteDisplay";
import { DownloadIcon } from "../../assets/Icons/DownloadIcon";
import { ClipboardListIcon } from "../../assets/Icons/ClipboardListIcon";
import {
  formatSOAPNote,
  formatProcedureNote,
  formatSurgicalNote,
  formatFollowUpVisit,
} from "./utils/NoteGeneration";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { MicMuteIcon } from "../../assets/Icons/MicMuteIcon";
import { MicIcon } from "../../assets/Icons/MicIcon";
import { VoiceIcon } from "../../assets/Icons/VoiceIcon";
import { useDictation } from "./hooks/useDictation";
import classes from "./Dictation.module.scss";

export function Dictation({ onChange }) {
  const { tCommon } = useAppTranslation.Common();
  const [transcription, setTranscription] = useState("");
  const [formattedNote, setFormattedNote] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isGeneratingNote, setIsGeneratingNote] = useState(false);

  const { isRecording, onToggle, partialText } = useDictation({
    text: transcription,
    onReadText: (text) => {
      setTranscription(text);
      if (onChange) {
        onChange(text);
      }
    },
  });

  const preparedPreviewText = transcription ? transcription.trim() : "";

  const handleTemplateSelection = async (template) => {
    if (!transcription) {
      alert(tCommon("dictation.message.pleaseRecordFirst"));
      return;
    }
    setSelectedTemplate(template);
    setIsGeneratingNote(true);

    try {
      let formattedText = "";
      switch (template.id) {
        case 1:
          formattedText = await formatSOAPNote(transcription);
          break;
        case 2:
          formattedText = await formatFollowUpVisit(transcription);
          break;
        case 3:
          formattedText = await formatSurgicalNote(transcription);
          break;
        case 4:
          formattedText = await formatProcedureNote(transcription);
          break;
        default:
          formattedText = transcription;
          break;
      }
      setFormattedNote(formattedText);
    } catch (error) {
      alert(tCommon("error.noteGenerationFailed"));
    } finally {
      setIsGeneratingNote(false);
    }
  };

  const copyToClipboard = () => {
    if (formattedNote) {
      navigator.clipboard.writeText(formattedNote).then(() => {
        alert(tCommon("dictation.message.noteCopied"));
      });
    }
  };

  const downloadNote = () => {
    if (formattedNote) {
      const blob = new Blob([formattedNote], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "note.txt";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color={isRecording ? "error" : undefined}
        leftAdornment={isRecording ? <MicMuteIcon /> : <MicIcon />}
        onClick={onToggle}
        disabled={isGeneratingNote}
      >
        {isRecording
          ? tCommon("dictation.label.stopDictation")
          : tCommon("dictation.label.startDictation")}
      </Button>

      <div className={classes.content}>
        {preparedPreviewText || partialText ? (
          <div className={classes.valueWrap}>
            {isRecording && <div className={clsx(classes.dot, classes.mt5)} />}
            {preparedPreviewText}
            {partialText && (
              <div className={classes.partialText}>{partialText}</div>
            )}
          </div>
        ) : (
          <div>
            {isRecording ? (
              <div className={classes.placeholderRecording}>
                <div className={classes.dot} />
                <div>{tCommon("dictation.label.recording")}...</div>
              </div>
            ) : (
              <div className={classes.placeholder}>
                <VoiceIcon width="21px" />
                {tCommon("dictation.label.dictationPlaceholder")}
              </div>
            )}
          </div>
        )}
      </div>

      <TemplateSelector
        onTemplateSelect={handleTemplateSelection}
        disabled={isGeneratingNote || isRecording}
      />

      {formattedNote && (
        <div>
          <h2 className="text-xl font-semibold mt-6">
            {selectedTemplate?.label ||
              tCommon("dictation.label.formattedNote")}
          </h2>
          <NoteDisplay note={formattedNote} />

          <div className={clsx(classes.flexRowCenter, "gap-4", "mt-4")}>
            <button
              className={clsx(
                classes.button,
                classes["button--secondary"],
                classes.flexRowCenter,
              )}
              onClick={copyToClipboard}
            >
              <ClipboardListIcon className={classes.icon} />
              {tCommon("dictation.label.copyToClipboard")}
            </button>

            <button
              className={clsx(
                classes.button,
                classes["button--secondary"],
                classes.flexRowCenter,
              )}
              onClick={downloadNote}
            >
              <DownloadIcon className={classes.icon} />
              {tCommon("dictation.label.downloadNote")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

Dictation.propTypes = {
  onChange: PropTypes.func,
};
