import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { fetchConsents } from "../../../../Actions/Settings/settingsActions.js";
import DragAndDrop from "./DragAndDrop.js";
import Loader from "../../../../Components/Common/Loader.js";
import { uiNotification } from "../../../../services/UINotificationService.js";
import { ConsentSearchInput } from "./components/ConsentSearchInput.js";
import { tCommon } from "../../../../i18n/useAppTranslation.js";

const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
};

class Consents extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      consents_header: languageData.settings["consents_header"],
      consents_added_by: languageData.settings["consents_added_by"],
      title_name_patient_work: languageData.settings["title_name_patient_work"],
      create_consent_button: languageData.settings["create_consent_button"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      clinics_Search: languageData.settings["clinics_Search"],

      consents: [],
      consentsPreviewList: [],
      showLoadingText: false,
      startFresh: true,
      showLoader: false,

      plan_selected: userData.account.account_subscription.plan_code,
      isTouchMd:
        userData && userData.account
          ? userData.account.account_preference.is_touch_md
          : 0,
    };
  }

  componentDidMount() {
    this.getConsents();
  }

  getConsents = () => {
    this.setState({ showLoader: true });
    this.props
      .fetchConsents()
      .then((response) => {
        this.setState({
          consents: response?.data || [],
          consentsPreviewList: response?.data || [],
        });
      })
      .catch(() => {
        uiNotification.error("Unable to fetch consents. Try again later");
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  consentEdit = (id) => {
    this.props.history.push(`/settings/consent/${id}/edit`);
  };

  handleSearch = (searchTerm) => {
    this.setState({
      consentsPreviewList: this.state.consents.filter((c) =>
        String(c.consent_name)
          .toLowerCase()
          .includes(String(searchTerm).toLowerCase()),
      ),
    });
  };

  render() {
    const userPermissions = JSON.parse(
      localStorage.getItem("userData"),
    ).permissions;

    let sideBarPermissions = [...userPermissions];

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title d-flex align-center justify-between flex-wrap">
                  {this.state.title_name_patient_work}
                  <div className="d-flex align-center gap-8">
                    <ConsentSearchInput onSearch={this.handleSearch} />
                    {checkPermission(sideBarPermissions, "manage-consents") && (
                      <Link
                        to="/settings/consent/create"
                        className="new-blue-btn"
                      >
                        {this.state.create_consent_button}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <div className="newTabsOuter">
                  {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking"
                    ? ""
                    : checkPermission(
                        sideBarPermissions,
                        "manage-questionnaires",
                      ) && (
                        <Link
                          to="/settings/questionnaires"
                          className="newTabs disabled-tab"
                        >
                          {tCommon("label.simpleQuestionnaires")}
                        </Link>
                      )}
                  {checkPermission(
                    sideBarPermissions,
                    "manage-questionnaires",
                  ) && (
                    <Link
                      to="/settings/procedure-templates"
                      className="newTabs disabled-tab"
                    >
                      {this.state.isTouchMd == 1 &&
                      this.state.plan_selected == "practice_booking"
                        ? "Questionnaires"
                        : tCommon("label.multiQuestionnaires")}
                    </Link>
                  )}
                  {checkPermission(sideBarPermissions, "manage-consents") && (
                    <Link to="/settings/consents" className="newTabs">
                      Consents
                    </Link>
                  )}
                  {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking" ? (
                    ""
                  ) : (
                    <Link
                      to="/settings/membership-agreements"
                      className="newTabs disabled-tab"
                    >
                      Membership Agreement
                    </Link>
                  )}
                </div>
              </div>
              <div className="juvly-section full-width">
                <div className="table-responsive">
                  <table className="table-updated setting-table no-td-border no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-6 table-updated-th sorting">
                          {this.state.consents_header}
                        </th>
                        <th className="col-xs-3 table-updated-th sorting text-left">
                          {this.state.consents_added_by}
                        </th>
                        <th className="col-xs-3 table-updated-th sorting text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <DragAndDrop
                      sortUpdate={this.updateSortOrder}
                      list={this.state.consentsPreviewList}
                      module={"Consent"}
                      editUrl={this.consentEdit}
                    />
                    <div
                      className={
                        this.state.consentsPreviewList
                          ? "no-record no-display"
                          : "no-record"
                      }
                    ></div>{" "}
                    {this.state.consentsPreviewList.length <= 0 &&
                      this.state.showLoader === false && (
                        <tbody>
                          <tr>
                            <td colSpan="3" className="table-updated-td">
                              <div className="no-record no-float text-center">
                                {" "}
                                {this.state.clinic_No_Record_Found}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                  </table>
                </div>
              </div>
              <div
                className={
                  this.state.showLoadingText
                    ? "loading-please-wait no-margin-top"
                    : "loading-please-wait no-margin-top no-display "
                }
              >
                {this.state.loading_please_wait_text}
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchConsents }, dispatch);
}

export default connect(null, mapDispatchToProps)(Consents);
