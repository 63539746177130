import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import InsightsSideBar from "../InsightsSideBar.js";
import StaffTitle from "./StaffTitle.js";
import StaffReportTitle from "./StaffReportTitle.js";
import StaffReportOptions from "./StaffReportOptions.js";
import Loader from "../../Common/Loader.js";
import { autoScrolling, getCurrencySymbol } from "../../../Utils/services.js";
import {
  resetReducerAction,
  getOptionAndList,
  saveReport,
  getReportVariables,
  getReportData,
  downloadReportData,
} from "../../../Actions/BusinessInsights/businessInsightActions.js";
import SaveReport from "../SaveReport.js";
import WidgetRevenuePerHour from "../../Dashboard/widgets/RevenuePerHour/WidgetRevenuePerHour.js";

const COL_LABELS_BY_RESPONSE_COL = {
  provider_name: "Provider Name",
  patient_first_name: "Patient First Name",
  patient_last_name: "Patient Last Name",
  email_address: "Email",
  date_of_last_visit: "Last Visit Date",
  number_of_visits: "Number of Visits",
};

const SYS_NAMES_WITH_PAGINATION = [
  "staff-treatment-tracking",
  "patient-activity",
  "provider-roll-up",
];

class StaffInsightReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      showCreateOptions: false,
      type: "staff",
      report_type_sysname: "",
      report_type_id: "",
      reportOptionAndList: {},
      reportOptions: props.reportOptions ? props.reportOptions : [],
      reportVariableData: [],
      reportData: {},

      languageData: languageData.business_insights,
      page: 1,
      pagesize: 15,
      startFresh: true,
      next_page_url: "",
      showLoadingText: false,
      tableColumns: [],
      reportListData: [],
      totalRow: null,
      grammar: null,
      graphData: [],

      reportType: this.props.match.params.reportType
        ? this.props.match.params.reportType
        : "create",
      reportID: this.props.match.params.reportID
        ? this.props.match.params.reportID
        : 0,
      isBreadcrumbs:
        this.props.match.params && this.props.match.params.reportID > 0
          ? false
          : true,
      insightID:
        this.props.match.params && this.props.match.params.insightID > 0
          ? this.props.match.params.insightID
          : 0,

      showTableSection: false,
      dataLoadedOnce: false,
      showNameSection: false,
      reportNameClass: "simpleInput",

      downloadData: {},
    };

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );

      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null &&
        SYS_NAMES_WITH_PAGINATION.includes(this.state.report_type_sysname)
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    autoScrolling(true);
    this.setState({ showLoader: true });

    let reportOptionFilter = {};

    if (
      this.state.reportType === "view" &&
      this.state.reportID &&
      this.state.insightID
    ) {
      this.setState({
        dataLoadedOnce: true,
        selected_report_type_id: this.state.insightID,
      });

      reportOptionFilter.page = this.state.page;
      reportOptionFilter.pagesize = this.state.pagesize;
      reportOptionFilter.business_insight_reports_id = this.state.reportID;
      this.props.getReportData(this.state.insightID, reportOptionFilter);
    } else {
      if (this.state.reportType === "edit" && this.state.reportID > 0) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
      }
      this.props.getOptionAndList(this.state.type, reportOptionFilter);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData &&
      nextProps.reportData.next_page_url !== prevState.next_page_url
    ) {
      returnState.reportData = nextProps.reportData;
      returnState.tableColumns = nextProps.reportData.columns
        ? nextProps.reportData.columns
        : prevState.tableColumns;
      if (prevState.next_page_url === null) {
        autoScrolling(false);
        return (returnState.next_page_url = null);
      }

      if (
        prevState.reportListData &&
        prevState.reportListData.length === 0 &&
        prevState.startFresh === true
      ) {
        returnState.insightReportData = nextProps.reportData;

        if (nextProps.reportData.next_page_url !== null) {
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url = nextProps.reportData.next_page_url;
        }
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.reportListData = nextProps.reportData.data
          ? nextProps.reportData.data
          : [];
        returnState.showTableSection = true;
        returnState.dataLoadedOnce = true;
        returnState.totalRow = nextProps.reportData.total
          ? nextProps.reportData.total
          : 0;
        returnState.grammar = nextProps.reportData.grammar
          ? nextProps.reportData.grammar
          : "";
        returnState.report_type_sysname = nextProps.reportData.sys_name
          ? nextProps.reportData.sys_name
          : prevState.report_type_sysname;
      } else if (
        prevState.insightReportData !== nextProps.reportData &&
        prevState.insightReportData.length !== 0
      ) {
        returnState.reportListData = [
          ...prevState.reportListData,
          ...nextProps.reportData.data,
        ];
        returnState.insightReportData = nextProps.reportData;
        returnState.next_page_url = nextProps.reportData.next_page_url;
        returnState.showLoader = false;
        returnState.page = prevState.page + 1;
        returnState.showLoadingText = false;
      }

      autoScrolling(false);
    } else if (
      nextProps.reportOptionAndList !== undefined &&
      nextProps.reportOptionAndList !== prevState.reportOptionAndList
    ) {
      returnState.reportOptionAndList = nextProps.reportOptionAndList;
      returnState.reportOptions = nextProps.reportOptionAndList.reportOptions;
      returnState.allData = nextProps.reportOptionAndList.allData;
      returnState.showLoader = false;
      if (
        prevState.reportType !== "view" &&
        prevState.showCreateOptions === false &&
        prevState.dataLoadedOnce === false
      ) {
        returnState.showCreateOptions = true;
      }
    } else if (
      nextProps.reportVariableData &&
      nextProps.reportVariableData !== prevState.reportVariableData
    ) {
      returnState.reportVariableData = nextProps.reportVariableData;
      returnState.showLoader = false;
    } else if (
      nextProps.downloadData !== undefined &&
      nextProps.downloadData !== prevState.downloadData
    ) {
      returnState.showLoader = false;
      returnState.downloadData = nextProps.downloadData;
      nextProps.resetReducerAction();
      window.open(
        process.env.REACT_APP_API_URL +
          "download-data/" +
          nextProps.downloadData.file,
        "_blank",
      );
    } else if (
      nextProps.redirect !== undefined &&
      nextProps.redirect === true
    ) {
      nextProps.resetReducerAction();
      toast.success(nextProps.message);
      nextProps.history.push(`/business-insight/${prevState.type}`);
    } else if (
      nextProps.showLoader !== undefined &&
      nextProps.showLoader === false
    ) {
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    }

    return returnState;
  }

  componentDidUpdate = (_, prevState) => {
    if (
      this.state.reportSavedData !== null &&
      this.state.reportSavedData !== "" &&
      this.state.reportSavedData !== prevState.reportSavedData &&
      this.state.savedStatus !== null &&
      this.state.savedStatus !== ""
    ) {
      toast.dismiss();
      toast.success(this.state.globalLang[this.state.savedStatus]);

      return (
        <div>
          {this.props.history.push(`/business-insight/${this.state.type}`)}
        </div>
      );
    }
  };

  loadMore = () => {
    if (!autoScrolling()) {
      let reportOptionFilter = {};

      if (
        this.state.reportType === "view" &&
        this.state.reportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;
        this.setState({ showLoadingText: true, startFresh: false });
        this.props.getReportData(this.state.insightID, reportOptionFilter);
      } else {
        reportOptionFilter = this.state.reportFilter;
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;

        if (reportOptionFilter) {
          this.setState({ showLoadingText: true, startFresh: false });
          autoScrolling(true);
          this.props.getReportData(
            reportOptionFilter.report_type_id,
            reportOptionFilter,
          );
        }
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });
  };

  showCreateOptions = (reportType) => {
    if (reportType === "create") {
      return (
        <div>
          {this.props.history.push(
            `/business-insight/${this.state.type}/${reportType}`,
          )}
        </div>
      );
    } else {
      this.setState({ showCreateOptions: true });
    }
  };

  hideCreateOptions = (childSate) => {
    if (this.state.dataLoadedOnce === false) {
      return (
        <div>
          {this.props.history.push(`/business-insight/${this.state.type}`)}
        </div>
      );
    } else {
      this.setState(childSate);
    }
  };

  getData = (childSate) => {
    autoScrolling(true);
    this.setState(childSate);

    let formData = childSate.reportFilter;
    formData.page = 1;
    formData.pagesize = this.state.pagesize;
    this.setState(
      {
        reportListData: [],
        showLoader: true,
        page: 1,
        startFresh: true,
        next_page_url: "",
        selected_report_type_id: childSate.report_type_id,
        selected_report_type_sysname: childSate.report_type_sysname,
        totalRow: null,
        grammar: null,
      },
      () => {
        this.props.getReportData(childSate.report_type_id, formData);
      },
    );
  };

  getReportVariables = (childSate, isVariableData) => {
    this.setState(childSate);
    if (isVariableData) {
      this.props.getReportVariables(childSate.report_type_id);
    }
  };

  downloadData = () => {
    let reportOptionFilter = {};
    if (
      this.state.reportType === "view" &&
      this.state.reportID &&
      this.state.insightID
    ) {
      reportOptionFilter.business_insight_reports_id = this.state.reportID;
      reportOptionFilter.is_download = 1;
      this.setState({ showLoader: true });
      this.props.downloadReportData(this.state.insightID, reportOptionFilter);
    } else {
      reportOptionFilter = this.state.reportFilter;
      reportOptionFilter.is_download = 1;
      this.setState({ showLoader: true });
      this.props.downloadReportData(
        reportOptionFilter.report_type_id,
        reportOptionFilter,
      );
    }
  };

  getReportName = () => {
    this.setState({ showNameSection: true });
  };

  hideNameSection = (childState) => {
    this.setState(childState);
  };

  saveThisReport = () => {
    let reportName = this.state.reportName;
    if (this.state.grammar === undefined || this.state.grammar === null) {
      toast.dismiss();
      toast.error("Grammar is missing");
      return false;
    }

    if (reportName && reportName.trim() !== "") {
      this.setState({
        showLoader: true,
        reportNameClass: "simpleInput",
        reportName: "",
        showNameSection: false,
      });

      let reportFilter = this.state.reportFilter;
      reportFilter.business_insight_id = this.state.reportFilter.report_type_id;
      reportFilter.name = reportName;
      reportFilter.grammar = this.state.grammar;

      if (
        this.state.reportID &&
        this.state.reportID > 0 &&
        this.state.reportType === "edit"
      ) {
        reportFilter.business_insight_report_id = this.state.reportID;
      }
      this.props.saveReport(this.state.type, reportFilter);
    } else {
      this.setState({ reportNameClass: "simpleInput field-error" });
      return;
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  render() {
    return (
      <div id="content">
        <SaveReport
          showNameSection={this.state.showNameSection}
          hideNameSection={this.hideNameSection}
          reportNameClass={this.state.reportNameClass}
          saveThisReport={this.saveThisReport}
          reportName={this.state.reportName}
          handleChildState={this.handleChildState}
          reportMode={this.state.reportType}
          editReportID={this.state.reportID}
          propReportType={this.state.reportType}
        />

        <StaffReportOptions
          showCreateOptions={this.state.showCreateOptions}
          getData={this.getData}
          hideCreateOptions={this.hideCreateOptions}
          getReportVariables={this.getReportVariables}
          reportOptions={this.state.reportOptions}
          reportVariableData={this.state.reportVariableData}
          type={this.state.type}
          reportID={this.state.reportID}
          allData={this.state.allData}
          businessInsightLang={this.state.businessInsightLang}
          globalLang={this.state.globalLang}
          history={this.props.history}
        />

        <div className="container-fluid content setting-wrapper">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            <StaffTitle
              history={this.props.history}
              isCreateButton={this.state.reportType === "view" ? true : false}
              isBreadcrumbs={this.state.isBreadcrumbs}
              showCreateOptions={this.showCreateOptions}
              businessInsightLang={this.state.businessInsightLang}
              globalLang={this.state.globalLang}
            />

            <div
              className={
                this.state.dataLoadedOnce
                  ? "setting-setion m-b-10"
                  : "setting-setion m-b-10 no-display"
              }
            >
              <StaffReportTitle
                downloadData={this.downloadData}
                showCreateOptions={this.showCreateOptions}
                saveReport={this.getReportName}
                parentState={this.state}
              />
              {this.state.report_type_sysname ===
                "provider-revenue-per-hour" && (
                <WidgetRevenuePerHour
                  fromReportData={{
                    from_date: this.state.reportFilter?.from_date,
                    to_date: this.state.reportFilter?.to_date,
                    clinic: this.state.reportFilter?.clinic,
                  }}
                />
              )}
              <div className="table-responsive clients-table no-border">
                <table className="table-updated  table-min-width no-td-border no-hover">
                  <thead className="table-updated-thead">
                    {
                      <tr>
                        {this.state.tableColumns &&
                          this.state.tableColumns.length > 0 &&
                          this.state.tableColumns.map((tobj, tidx) => {
                            return (
                              <th key={tidx} className={`table-updated-th`}>
                                {COL_LABELS_BY_RESPONSE_COL[tobj] ||
                                  this.state.languageData[tobj]}{" "}
                              </th>
                            );
                          })}
                      </tr>
                    }
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.reportListData &&
                      this.state.reportListData.length > 0 &&
                      this.state.reportListData.map((robj, ridx) => {
                        return (
                          <tr key={ridx} className="table-updated-tr">
                            <>
                              {this.state.tableColumns &&
                                this.state.tableColumns.length > 0 &&
                                this.state.tableColumns.map((tobj, tidx) => {
                                  return (
                                    <td
                                      key={ridx + `_` + tidx}
                                      className={`table-updated-td ${
                                        tidx !== 0 ? "break-all" : `tidx${tidx}`
                                      }`}
                                    >
                                      {[
                                        "bi_amount_sold",
                                        "bi_total",
                                        "bi_avg_sale",
                                      ].indexOf(tobj) > -1
                                        ? getCurrencySymbol()
                                        : null}
                                      {robj[tobj]}
                                    </td>
                                  );
                                })}
                            </>
                          </tr>
                        );
                      })}

                    {this.state.reportListData &&
                      this.state.reportListData.length <= 0 &&
                      this.state.showLoader === false &&
                      this.state.startFresh === false && (
                        <tr className="table-updated-tr">
                          <td
                            className="table-updated-td no-record no-float"
                            colSpan={10}
                          >
                            {this.state.businessInsightLang.bi_no_record_found}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>

              <div
                className={
                  this.state.showLoadingText
                    ? "loading-please-wait no-margin-top"
                    : "loading-please-wait no-margin-top no-display"
                }
              >
                {this.state.globalLang.loading_please_wait_text}
              </div>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.BusinessInsightReducer.action === "GET_STAFF_REPORT") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportOptionAndList = {
        savedReports: state.BusinessInsightReducer.staffSavedReports,
        reportOptions: state.BusinessInsightReducer.staffOptions,
        allData: state.BusinessInsightReducer.data.data,
      };
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_VARIABLES") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.reportVariableData = state.BusinessInsightReducer.data.data;
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportData = state.BusinessInsightReducer.data.data;
    }
  }

  if (state.BusinessInsightReducer.action === "UPDATE_STAFF_SAVED_REPORTS") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      toast.dismiss();
      returnState.savedReports = state.BusinessInsightReducer.staffSavedReports;
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.BusinessInsightReducer.data.message];
    }
  }

  if (state.BusinessInsightReducer.action === "DOWNLOAD_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.downloadData = state.BusinessInsightReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction: resetReducerAction,
      getOptionAndList: getOptionAndList,
      saveReport: saveReport,
      getReportVariables: getReportVariables,
      getReportData: getReportData,
      downloadReportData: downloadReportData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(StaffInsightReport));
