import React from "react";
import { tCommon } from "../../../../i18n/useAppTranslation";
import { useClientGlobalName } from "../../../../hooks/useClientGlobalName";

export default function DeclinePaymentEmailSmsSnippet({ title }) {
  const clientGlobalName = useClientGlobalName();

  return (
    <div className="setting-variable snippets m-b-30">
      <p>
        <b>{title}:</b> {`{{PATIENTNAME}}`}
      </p>
      <p>
        <b>{tCommon("label.membershipName")}:</b> {`{{MEMBERSHIPNAME}}`}{" "}
      </p>
      <p>
        <b>{tCommon("label.membershipFee")}:</b> {`{{MEMBERSHIPFEE}}`}{" "}
      </p>
      <p>
        <b>{tCommon("label.clinicName")}:</b> {`{{CLINICNAME}}`}{" "}
      </p>
      <p>
        <b>{clientGlobalName.t({ path: "label.patientPortalLink" })}:</b>{" "}
        {`{{CLIENTPATIENTURL}}`}{" "}
      </p>
    </div>
  );
}
