import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import { useGiftCardActions } from "../../../hooks/useGiftCardActions";
import { useChargeForm } from "../../../hooks/useChargeForm";
import { checkoutInvoice } from "../../../../../../store/checkoutInvoice";
import { composeAmountSchema } from "../../../SalesCheckoutInvoice.schemas";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { usePaymentFlow } from "../../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ApplyGiftAmount = ({ giftCard, isTipsApplying }) => {
  const { applyGiftCard } = useGiftCardActions();
  const { invoice, noTipAmount } = useInvoice();
  const { showTipsModal } = usePaymentFlow();

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: Math.min(noTipAmount, giftCard.availableAmount),
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        applyGiftCard.initiate({
          redeemAmount: amount,
          giftCardId: giftCard.id,
          redemptionCode: giftCard.redemptionCode,
          giftCardAvailableAmount: giftCard.availableAmount,
        });
      },
      initialValues: {
        amount: Math.min(noTipAmount, giftCard.availableAmount),
      },
    });

  const maxAmountToPay = Math.min(
    giftCard.availableAmount,
    parseFloat(form.amount) + parseFloat(invoice.tipAmount),
  );

  const isLoading = applyGiftCard.isLoading || isTipsApplying;

  return (
    <div>
      {!showTipsModal.value ? (
        <PaymentFormModal
          isLoading={isLoading}
          form={form}
          errors={errors}
          hasError={hasError}
          isValid={isValid}
          changeFormValue={changeFormValue}
          giftCard={giftCard}
          submit={submit}
          showTipsModal={showTipsModal}
        />
      ) : (
        <PaymentTipsModal
          isLoading={isLoading}
          maxAmountToPay={maxAmountToPay}
          showTipsModal={showTipsModal}
          giftCard={giftCard}
        />
      )}
    </div>
  );
};

ApplyGiftAmount.propTypes = {
  giftCard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    availableAmount: PropTypes.number.isRequired,
    redemptionCode: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ApplyGiftAmount);
