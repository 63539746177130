import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./TemplateSelector.module.scss";

import { FileMedicalIcon } from "../../../../assets/Icons/FileMedicalIcon";
import { ProceduresIcon } from "../../../../assets/Icons/ProceduresIcon";
import { UserMDIcon } from "../../../../assets/Icons/UserMDIcon";
import { FollowUpVisitIcon } from "../../../../assets/Icons/FollowUpVisitIcon";
const templates = [
  {
    id: 1,
    label: "General SOAP Note",
    icon: <FileMedicalIcon />,
    content: `S: Subjective Information: Describe the patient's main complaint and symptoms in their own words.
O: Objective Information: Record objective, measurable data, such as vital signs and test results.
A: Assessment: Provide a diagnosis or summary of the patient's condition.
P: Plan: Outline the treatment plan including tests, procedures, and follow-up care.`,
  },
  {
    id: 2,
    label: "Follow-Up Visit",
    icon: <FollowUpVisitIcon />,
    content: `S: Patient reports improvement in symptoms since last visit.
O: Vital signs stable and within normal limits.
A: Condition: Monitoring progress and effectiveness of current treatment.
P: Continue current medications, schedule follow-up in two weeks.`,
  },
  {
    id: 3,
    label: "Surgical Note",
    icon: <UserMDIcon />,
    content: `S: Subjective information from the patient regarding the surgical procedure.
O: Objective details about the surgery, including techniques and tools used.
A: Assessment post-operation to determine immediate success and next steps.
P: Plan for post-operative care and follow-up requirements.`,
  },
  {
    id: 4,
    label: "Procedure Note",
    icon: <ProceduresIcon />,
    content: `S: Subjective information about the procedure's necessity and patient history.
O: Objective account of the procedure steps and expected outcomes.
A: Assessment regarding the results of the procedure.
P: Plan including recovery, medications, and ongoing care considerations.`,
  },
];

export function TemplateSelector({ onTemplateSelect }) {
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const handleTemplateClick = (template) => {
    setSelectedTemplateId(template.id);
    onTemplateSelect(template);
  };

  return (
    <div className={styles.templateSelector}>
      <h3 className={styles.title}>Select a Template</h3>
      <div className={styles.templateGrid}>
        {templates.map((template) => (
          <button
            key={template.id}
            onClick={() => handleTemplateClick(template)}
            className={clsx(
              styles.templateButton,
              selectedTemplateId === template.id &&
                styles.templateButtonSelected,
            )}
            aria-pressed={selectedTemplateId === template.id}
          >
            <div
              className={clsx(
                styles.icon,
                selectedTemplateId === template.id && styles.iconSelected,
              )}
            >
              {React.cloneElement(template.icon, {
                className: styles.icon,
              })}
            </div>
            <div className={styles.textLeft}>
              <div className={styles.templateLabel}>{template.label}</div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

TemplateSelector.propTypes = {
  onTemplateSelect: PropTypes.func.isRequired,
};
